<div class="flex w-full mt-16 justify-center">
	<div class="max-w-[600px] w-full px-8">
		<div (click)="back()" class="flex my-2 cursor-pointer">
			<img class="mr-2" src="assets/svg/arrow-left.svg" />
			<p class="text-sm font-medium text-gray-800">Back</p>
		</div>
		<p class="my-6 text-3xl font-medium text-gray-900">Linked addresses</p>

		<div class="mb-8 bg-stone rounded py-4 px-6 flex items-center">
			<img class="h-14 mr-4" src="assets/svg/no-locations.svg" />
			<div>
				<p class="mb-0.5 text-base font-semibold text-gray-700">
					No linked properties found
				</p>
				<p class="text-sm font-normal text-gray-500">
					We will create a job one-off for this address. Address
					linking happens via your property managing agent.
				</p>
			</div>
		</div>

		<div [formGroup]="addressForm" class="flex flex-col mb-12">
			<div>
				<p class="mb-0.5 text-xl font-medium text-gray-900">
					Tell us where
				</p>
				<p class="text-sm font-normal text-gray-500">
					Enter the property address.
				</p>
			</div>

			<!-- <div class="relative mt-8">
				<div class="block">
					<fixify-text-input
						[control]="addressForm.controls['formattedAddress']"
						inputType="text"
						label="Property address"
						[showRequiredStar]="true"
						[showErrorMessage]="false"
						placeholder="Enter address"
					></fixify-text-input>
				</div>
				<div
					[class.hidden]="
						!addressForm.get('formattedAddress')?.value ||
						searchResults().length === 0
					"
					class="absolute z-50 bg-white divide-y divide-gray-100 rounded-lg shadow w-full"
				>
					<ul
						class="text-sm text-gray-700"
						aria-labelledby="dropdownInput"
					>
						@for (suggestion of searchResults(); track $index) {
							<li
								(click)="selectedAddress(suggestion)"
								class="cursor-pointer"
							>
								<p class="block px-4 py-2 hover:bg-gray-100">
									{{ suggestion.placePrediction.text.text }}
								</p>
							</li>
						}
					</ul>
				</div>
				
			</div> -->

			<fixify-search-places
				class="mt-8"
				[control]="addressForm.get('formattedAddress')!"
				(address)="selectedAddress($event)"
				(selectedLocation)="selectedLocation($event)"
				[(ignoreNextSearchChange)]="ignoreNextSearchChange"
				[(searchResults)]="searchResults"
				(searchingForAddresses)="searching($event)"
				label="Property address"
				placeholder="Enter address"
				[showRequiredStar]="true"
			></fixify-search-places>
			@if (
				searchingAddress() && addressForm.get('formattedAddress')?.value
			) {
				<div class="flex justify-center">
					<app-loader class="mt-8" [loadingState]="true"></app-loader>
				</div>
			}

			<div
				[class.hidden]="!clickedLocation"
				class="w-full overflow-hidden rounded-lg h-[232px] mt-6"
			>
				<p class="text-sm font-normal text-gray-500 mb-3">
					Move the pin to the exact location
				</p>
				<div class="w-full overflow-hidden rounded-lg h-[232px]">
					<google-map
						[center]="center()"
						height="100%"
						width="100%"
						[options]="options"
					>
						@if (clickedLocation !== null) {
							<map-advanced-marker
								(mapDragend)="onMapDragEnd($event)"
								[position]="clickedLocation"
								[options]="advancedMarkerOptions"
							></map-advanced-marker>
						}
					</google-map>
				</div>
			</div>
			<fixify-dropdown
				class="mt-8"
				[control]="addressForm.get('estateComplexName')!"
				[currentlySelectedItem]="selectedEstateComplex()"
				[items]="estatesWithOther()"
				[nameFieldOfItem]="'name'"
				[valueFieldOfItem]="'id'"
				defaultText="None"
				label="Complex/Estate (optional)"
			></fixify-dropdown>
			<fixify-text-input
				class="mt-8"
				[class.hidden]="!showOtherEstateComplex()"
				[control]="addressForm.controls['otherEstateComplex']"
				inputType="text"
				label="Complex/Estate name"
				[showRequiredStar]="true"
				errorText="Enter your Complex/Estate name"
				placeholder="Enter the name of the complex/estate"
			></fixify-text-input>
			<fixify-text-input
				class="mt-8"
				[class.hidden]="this.selectedEstateComplex() === null"
				[control]="addressForm.controls['unit']"
				inputType="text"
				label="Unit number"
				[showRequiredStar]="true"
				errorText="Enter your Complex/Estate Unit number"
				placeholder="e.g. 109"
			></fixify-text-input>
		</div>

		<div class="flex gap-3 mb-8">
			<button (click)="cancel()" class="btn-outline w-full">
				Cancel request
			</button>
			<button (click)="submit()" class="btn-primary w-full">
				Continue
			</button>
		</div>
	</div>
</div>

