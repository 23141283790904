import { NgClass } from '@angular/common';
import { Component, input, output } from '@angular/core';

@Component({
	selector: 'fixify-modal',
	standalone: true,
	imports: [NgClass],
	templateUrl: './modal-card.component.html',
	styleUrl: './modal-card.component.css'
})
export class ModalCardComponent {
	onBackdropClick = output<void>();
	size = input<'sm' | 'md' | 'lg' | 'xl' | '2xl' | '4xl'>('md');

	onBackdropClicked() {
		this.onBackdropClick.emit();
	}
}
