import { Component, input, output } from '@angular/core';
import { DividerComponent } from '../divider/divider.component';
import { StatusPillComponent } from '../status-pill/status-pill.component';

@Component({
	selector: 'fixify-status-banner',
	standalone: true,
	imports: [DividerComponent, StatusPillComponent],
	templateUrl: './status-banner.component.html',
	styleUrl: './status-banner.component.css'
})
export class StatusBannerComponent {
	title = input.required<string>();
	subtitle = input<string>();
	statusColor = input<string>('green');
	status = input<string>();
	iconSrc = input<string>();
	primaryAction = input<string>();
	secondaryAction = input<string>();
	primaryClicked = output<void>();
	secondaryClicked = output<void>();

	onPrimaryClick() {
		this.primaryClicked.emit();
	}

	onSecondaryClick() {
		this.secondaryClicked.emit();
	}
}
