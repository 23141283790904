import { Component, signal } from '@angular/core';
import { ModalComponent } from '../../../../../shared/ui/modals/modal.service';
import { PropertyAvatarComponent } from '../../../../../shared/ui/property-avatar/property-avatar.component';
import { IconComponent } from '../../../../../shared/ui/icon/icon.component';
import { PropertyInfoCellComponent } from '../../property-info-cell/property-info-cell.component';
import { AvatarComponent } from '../../../../../shared/ui/avatar/avatar.component';
import { NgClass } from '@angular/common';
import { SpInfoCardComponent } from '../../../../../shared/ui/sp-info-card/sp-info-card.component';
import { ModalCardComponent } from '../../../../../shared/ui/modal-card/modal-card.component';

@Component({
	selector: 'app-property-modal',
	standalone: true,
	imports: [
		PropertyAvatarComponent,
		IconComponent,
		PropertyInfoCellComponent,
		AvatarComponent,
		NgClass,
		SpInfoCardComponent,
		ModalCardComponent
	],
	templateUrl: './property-modal.component.html',
	styleUrl: './property-modal.component.css'
})
export class PropertyModalComponent extends ModalComponent<null, void> {
	// For testing the Frontend for different states
	isLinkedToEstate = signal<boolean>(true);
	hasFavouriteSps = signal<boolean>(true);

	onClose(): void {
		this.close();
	}
}
