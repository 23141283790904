import { CommonModule } from '@angular/common';
import { Component, computed, input, model, OnInit } from '@angular/core';
import {
	FormControl,
	FormGroup,
	FormsModule,
	ReactiveFormsModule,
	Validators
} from '@angular/forms';
import { LocalStorageService } from '../../../../shared/data-access/local-storage.service';
import { LoaderComponent } from '../../../../shared/ui/loader/loader.component';
import { AlertComponent } from '../../../../shared/ui/alert/alert.component';
import { TextInputComponent } from '../../../../shared/ui/text-input/text-input.component';
import { ApiService } from '../../../../shared/data-access/api-service.service';
import {
	Auth,
	ConfirmationResult,
	PhoneAuthProvider,
	RecaptchaVerifier,
	signInWithCredential,
	signInWithPhoneNumber
} from '@angular/fire/auth';
import { Router } from '@angular/router';
import { ToastService } from '../../../../shared/data-access/toast.service';

const DEFAULT_USER_TYPE = 'service-providers';
@Component({
	selector: 'app-otp',
	standalone: true,
	imports: [
		ReactiveFormsModule,
		FormsModule,
		CommonModule,
		LoaderComponent,
		AlertComponent,
		TextInputComponent
	],
	templateUrl: './otp.component.html',
	styleUrl: './otp.component.css'
})
export class OtpComponent implements OnInit {
	loginMode = input<'phone' | 'mail'>('phone');
	secondsRemaining = input<number>(0);
	goBack = model<boolean>(false);
	resendOTP = model<boolean>(false);
	emailOrPhoneNumber = input<string>('');
	userType = input<'service-providers' | 'agency'>(DEFAULT_USER_TYPE);
	redactedPhoneNumber = computed(() => {
		return '*'.repeat(8) + this.emailOrPhoneNumber().slice(-2);
	});
	loading = false;
	emailResent = false;
	codeForm: FormGroup;

	recaptchaVerifier: RecaptchaVerifier | undefined;
	recaptchaConfirmationResult: ConfirmationResult | undefined;
	verificationId = '';
	showRecaptcha = true;

	constructor(
		private localStorage: LocalStorageService,
		private apiService: ApiService,
		private auth: Auth,
		private router: Router,
		private toastService: ToastService
	) {
		this.codeForm = new FormGroup({
			otp: new FormControl('', [
				Validators.required,
				Validators.minLength(6)
			])
		});
	}

	ngOnInit() {
		this.renderReCaptcha();
	}

	back() {
		this.goBack.set(true);
	}

	continue() {
		this.localStorage.removeItem('otpTimeRemaining');

		// TODO: login code here depending on the login mode
	}

	renderReCaptcha() {
		setTimeout(() => {
			this.showRecaptcha = true;
			this.recaptchaVerifier = new RecaptchaVerifier(
				this.auth,
				'recaptcha-container',
				{
					size: 'normal',
					callback: () => {
						this.onCaptchaVerified();
					},
					'expired-callback': () => {
						this.renderReCaptcha();
					},
					'error-callback': () => {
						this.renderReCaptcha();
					}
				}
			);

			this.recaptchaVerifier.render();
		}, 0);
	}

	onCaptchaVerified() {
		// Enable the send OTP button or automatically start the signInWithPhoneNumber process
		console.log('Captcha verified');
		this.recaptchaVerifier?.verify().then(() => {
			this.sendOTP();
			this.showRecaptcha = false;
		});
	}

	sendOTP() {
		if (!this.recaptchaVerifier) {
			console.error('reCAPTCHA not initialized');
			return;
		}

		signInWithPhoneNumber(
			this.auth,
			this.emailOrPhoneNumber(),
			this.recaptchaVerifier
		)
			.then(confirmationResult => {
				this.recaptchaConfirmationResult = confirmationResult;
				this.verificationId = confirmationResult.verificationId;
			})
			.catch(error => {
				console.error('Error sending OTP', error);
				this.renderReCaptcha();
			});
	}

	verifyOTP() {
		if (!this.recaptchaConfirmationResult) {
			console.error('OTP not sent');
			return;
		}

		const credential = PhoneAuthProvider.credential(
			this.verificationId,
			this.codeForm.get('otp')?.value
		);

		signInWithCredential(this.auth, credential)
			.then(userCredential => {
				if (userCredential.user) {
					// User is signed in
					this.continue();

					if (this.userType() == 'agency') {
						this.router.navigate(['/agency/jobs']);
					} else {
						this.router.navigate(['/service-provider/jobs']);
					}
				}
			})
			.catch(error => {
				console.error('Error verifying OTP', error);
			});
	}

	async resendEmail() {
		this.emailResent = false;
		this.loading = true;

		this.signInWithEmailLink()
			.pipe()
			.subscribe({
				next: data => {
					this.toastService.add('Email sent successfully');
					console.log('Data received:', data);
					this.emailResent = true;
					this.resendOTP.set(true);
				},
				error: error => {
					this.loading = false;
					this.toastService.add(
						'Failed to send mail, please try again later',
						5000,
						'error'
					);
					console.error('Error:', error);
				},
				complete: () => {
					this.loading = false;
				}
			});
	}

	signInWithEmailLink() {
		return this.apiService.postData(
			`${this.userType()}/signin-with-email-link`,
			{
				email: this.emailOrPhoneNumber()
			}
		);
	}

	resendOtp() {
		this.renderReCaptcha();
	}
}
