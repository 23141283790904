import { Component, input, output, signal } from '@angular/core';
import {
	FormControl,
	FormGroup,
	ReactiveFormsModule,
	Validators
} from '@angular/forms';
import { TextInputComponent } from '../../../shared/ui/text-input/text-input.component';
import { NgClass } from '@angular/common';
import { Subject, takeUntil } from 'rxjs';
import {
	GoogleMap,
	MapAdvancedMarker,
	MapGeocoder
} from '@angular/google-maps';
import { DEFAULT_LAT_LNG, Suggestion } from '../../../shared/models/maps';
import { LoaderComponent } from '../../../shared/ui/loader/loader.component';
import { DropdownComponent } from '../../../shared/ui/dropdown/dropdown.component';
import { SearchPlacesComponent } from '../../../shared/ui/search-places/search-places.component';
import { Address, Estate } from '../../service/creator-portal.service';
import { mapAddress } from '../../../shared/utils/maps';
import { createAPropertyRequest } from '../../../shared/data-access/residential/residential.types';

@Component({
	selector: 'fixify-no-linked-addresses',
	standalone: true,
	imports: [
		TextInputComponent,
		ReactiveFormsModule,
		NgClass,
		MapAdvancedMarker,
		GoogleMap,
		LoaderComponent,
		DropdownComponent,
		SearchPlacesComponent
	],
	templateUrl: './no-linked-addresses.component.html',
	styleUrl: './no-linked-addresses.component.css'
})
export class NoLinkedAddressesComponent {
	goBack = output<boolean>();
	createProperty = output<createAPropertyRequest>();
	estates = input.required<Array<Estate>>();
	searchingAddress = signal<boolean>(false);
	ignoreNextSearchChange = signal<boolean>(false);
	addressForm: FormGroup;
	destroy$ = new Subject<void>();
	selectedEstateComplex = signal<Estate | null>(null);
	center = signal<google.maps.LatLngLiteral>(DEFAULT_LAT_LNG);
	options: google.maps.MapOptions = {
		streetViewControl: false,
		zoom: 16,
		mapTypeControl: false,
		fullscreenControl: false,
		mapId: '4ae9361332aa6c90',
		restriction: {
			latLngBounds: {
				north: -20.1258,
				south: -36.8192,
				west: 14.5126,
				east: 35.8913
			},
			strictBounds: false
		}
	};
	address: Address | null = null;

	advancedMarkerOptions: google.maps.marker.AdvancedMarkerElementOptions = {
		gmpDraggable: true,
		content: this.createContent()
	};

	clickedLocation: {
		lat: number;
		lng: number;
	} | null = null;

	searchResults = signal<Array<Suggestion>>([]);

	constructor(private geocoder: MapGeocoder) {
		this.addressForm = new FormGroup({
			formattedAddress: new FormControl('', Validators.required),
			estateComplexName: new FormControl(''),
			otherEstateComplex: new FormControl(
				{ value: '', disabled: true },
				Validators.required
			),
			unit: new FormControl(
				{
					value: '',
					disabled: true
				},
				Validators.required
			)
		});
	}

	ngOnInit() {
		this.addressForm.controls['estateComplexName'].valueChanges
			.pipe(takeUntil(this.destroy$))
			.subscribe((estateComplex: string) => {
				const estate = this.estatesWithOther().find(
					estate => estate.id === estateComplex
				);
				this.selectedEstateComplex.set(estate || null);
				if (estateComplex === 'other') {
					this.addressForm.controls['otherEstateComplex'].enable();
				} else {
					this.addressForm.controls['otherEstateComplex'].reset();
					this.addressForm.controls['otherEstateComplex'].disable();
				}

				if (estateComplex) {
					this.addressForm.controls['unit'].enable();
				} else {
					this.addressForm.controls['unit'].reset();
					this.addressForm.controls['unit'].disable();
				}
			});
	}

	showOtherEstateComplex() {
		return this.addressForm.controls['estateComplexName'].value === 'other';
	}

	createContent() {
		const content = document.createElement('div');
		content.innerHTML = `<img src="assets/svg/map-pin-primary.svg"/>`;
		return content;
	}

	estatesWithOther(): Array<Estate> {
		return [
			...this.estates(),
			{
				id: 'other',
				name: 'Other',
				address: {
					city: '',
					coordinates: [0, 0],
					formatted: '',
					geohash: '',
					postalCode: '',
					province: '',
					street: {
						name: '',
						number: ''
					},
					suburb: '',
					unitNumber: ''
				}
			}
		];
	}

	onMapDragEnd(event: google.maps.MapMouseEvent) {
		if (!event.latLng) {
			return;
		}
		const previousClick = this.clickedLocation
			? { ...this.clickedLocation }
			: null;
		this.clickedLocation = event.latLng.toJSON();

		this.addressForm.controls['formattedAddress'].disable();

		this.geocoder.geocode({ location: event.latLng.toJSON() }).subscribe({
			next: ({ results }) => {
				if (results.length === 0) {
					return;
				}
				this.address = mapAddress(results[0]);
				const address = results[0].formatted_address;
				this.ignoreNextSearchChange.set(true);
				this.addressForm.controls['formattedAddress'].setValue(address);
			},
			error: error => {
				console.error('Geocoding error:', error);
				this.clickedLocation = previousClick;
				// Handle the error here
			},
			complete: () => {
				this.addressForm.controls['formattedAddress'].enable();
			}
		});
	}

	back() {
		this.goBack.emit(true);
	}

	searching(isSearching: boolean) {
		this.searchingAddress.set(isSearching);
	}

	async selectedAddress(address: Address | null) {
		this.address = address;
	}

	cancel() {
		this.goBack.emit(true);
	}

	submit() {
		if (!this.address) {
			return;
		}
		this.address.unitNumber =
			this.addressForm.controls['unit'].value ?? null;

		if (this.addressForm.invalid) {
			return;
		}

		const estate = this.selectedEstateComplex();
		const otherEstateName =
			this.addressForm.controls['otherEstateComplex'].value;
		if (estate && estate.id === 'other') {
			estate.name = otherEstateName;
		}
		const createProperty: createAPropertyRequest = {
			address: this.address,
			estateId: estate !== null ? estate?.id : null,
			estateName: estate !== null ? estate?.name : null
		};
		this.createProperty.emit(createProperty);
	}

	async selectedLocation(location: google.maps.LatLngLiteral | null) {
		if (!location) {
			this.clickedLocation = null;
			return;
		}
		this.clickedLocation = location;
		// required to wait for zoom to finish, otherwise the map will not center correctly due to it's set restrictions
		await new Promise(resolve => setTimeout(resolve, 1));
		this.center.set(location);
	}
}
