@if (label().length > 0) {
	<label class="input-label" [for]="id()"
		>{{ label() }}
		@if (showRequiredStar()) {
			<span class="text-red-700">*</span>
		}
	</label>
}
<div [class.relative]="inputType() === 'search'">
	@if (inputType() === 'textarea') {
		<textarea
			[id]="id()"
			[disabled]="disabled()"
			[formControl]="ngControl"
			[ngClass]="{
				'input-error': control.invalid && control.dirty
			}"
			class="h-40 form-input focus:outline-none focus:ring-1 focus:ring-primary-100 focus:border-transparent"
			[placeholder]="placeholder()"
		></textarea>
	} @else {
		@if (inputType() === 'search') {
			<div
				class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none"
			>
				<svg
					class="w-4 h-4 text-gray-500 dark:text-gray-400"
					aria-hidden="true"
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 20 20"
				>
					<path
						stroke="currentColor"
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="2"
						d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
					/>
				</svg>
			</div>
		}
		<input
			[id]="id()"
			[disabled]="disabled()"
			[readOnly]="disabled()"
			[formControl]="ngControl"
			[ngClass]="{
				'cursor-not-allowed focus:ring-0 text-gray-400': disabled(),
				'input-error': control.invalid && control.dirty,
				'block w-full ps-10 bg-white': inputType() === 'search'
			}"
			[type]="inputType()"
			class="form-input max-h-10 focus:outline-none focus:ring-1 focus:ring-primary-100 focus:border-transparent"
			[placeholder]="placeholder()"
		/>
	}
</div>
@if (control.invalid && control.dirty && showErrorMessage()) {
	<p class="input-error-text">{{ errorText() }}</p>
}
