<!-- pagination.component.html -->
<div class="flex items-center justify-between bg-white px-4 py-3 sm:px-6">
	<div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
		<div>
			<p class="text-sm text-gray-500">
				Showing
				<span class="font-medium text-gray-900">{{
					showingFrom + '-' + showingTo
				}}</span>
				of
				<span class="font-medium text-gray-900">{{ maxItems() }}</span>
			</p>
		</div>
		<div>
			<nav class="isolate inline-flex -space-x-px rounded-md flex gap-2">
				<button
					[disabled]="currentPage() === 1"
					(click)="onPageChange(1)"
					class="btn btn-secondary w-8 h-8 p-0"
				>
					<div class="p-1.5 rotate-180">
						<fixify-icon
							class="w-5 h-5 text-gray-500"
							name="align-right-01"
						></fixify-icon>
					</div>
				</button>
				<button
					[disabled]="currentPage() === 1"
					(click)="onPageChange(currentPage() - 1)"
					class="btn btn-secondary w-8 h-8 p-0"
				>
					<div class="p-1.5 rotate-180">
						<fixify-icon
							class="w-5 h-5 text-gray-500"
							name="chevron-right"
						></fixify-icon>
					</div>
				</button>

				@for (pageNum of [].constructor(totalPages()); track $index) {
					<button
						(click)="onPageChange($index + 1)"
						class="btn btn-secondary w-8 h-8 p-0 text-gray-500"
						[ngClass]="{
							'bg-primary-100 text-primary-900':
								$index + 1 === currentPage()
						}"
					>
						{{ $index + 1 }}
					</button>
				}
				<button
					[disabled]="currentPage() === totalPages()"
					(click)="onPageChange(currentPage() + 1)"
					class="btn btn-secondary w-8 h-8 p-0"
				>
					<div class="p-1.5">
						<fixify-icon
							class="w-5 h-5 text-gray-500"
							name="chevron-right"
						></fixify-icon>
					</div>
				</button>
				<button
					[disabled]="currentPage() === totalPages()"
					(click)="onPageChange(totalPages())"
					class="btn btn-secondary w-8 h-8 p-0"
				>
					<div class="p-1.5">
						<fixify-icon
							class="w-5 h-5 text-gray-500"
							name="align-right-01"
						></fixify-icon>
					</div>
				</button>
			</nav>
		</div>
	</div>
</div>

