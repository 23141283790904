import { Component, signal } from '@angular/core';
import { IconComponent } from '../../../shared/ui/icon/icon.component';
import { ActivatedRoute, Router } from '@angular/router';
import {
	FormArray,
	FormControl,
	FormGroup,
	ReactiveFormsModule,
	Validators
} from '@angular/forms';
import { DividerComponent } from '../../../shared/ui/divider/divider.component';
import { TextInputComponent } from '../../../shared/ui/text-input/text-input.component';
import { NgClass } from '@angular/common';
import { SelectableGridItemComponent } from '../../../shared/ui/selectable-grid-item/selectable-grid-item.component';
import { SearchPlacesComponent } from '../../../shared/ui/search-places/search-places.component';
import { Suggestion } from '../../../shared/models/maps';
import { LoaderComponent } from '../../../shared/ui/loader/loader.component';
import { UploadComponent } from '../../../shared/ui/upload/upload.component';
import { PhoneInputComponent } from '../../../shared/ui/phone-input/phone-input.component';
import { ModalService } from '../../../shared/ui/modals/modal.service';
import { SpFavouritingComponent } from '../ui/modals/sp-favouriting/sp-favouriting.component';
import { Observable, Subject, takeUntil } from 'rxjs';
import {
	Provinces,
	provinces,
	staticMapImageUrl
} from '../../../shared/utils/maps';
import { latLngCoordinateString } from '../../../shared/validators';
import { Address } from '../../../creator-portal/service/creator-portal.service';
import { DropdownComponent } from '../../../shared/ui/dropdown/dropdown.component';
import { AlertComponent } from '../../../shared/ui/alert/alert.component';
import { PropertyImageGuidelineComponent } from '../ui/modals/property-image-guideline/property-image-guideline.component';
import { GenericSearchInputComponent } from '../../../shared/ui/generic-search-input/generic-search-input.component';
import { Agent } from '../../../shared/data-access/agency/agency.types';

type AddressType = 'House' | 'Apartment' | null;
type LandlordTrustee = 'Landlord' | 'Trustee' | null;

@Component({
	selector: 'fixify-add-edit-property',
	standalone: true,
	imports: [
		IconComponent,
		ReactiveFormsModule,
		DividerComponent,
		TextInputComponent,
		NgClass,
		SelectableGridItemComponent,
		SearchPlacesComponent,
		LoaderComponent,
		UploadComponent,
		PhoneInputComponent,
		DropdownComponent,
		AlertComponent,
		GenericSearchInputComponent
	],
	templateUrl: './add-edit-property.component.html',
	styleUrl: './add-edit-property.component.css'
})
export class AddEditPropertyComponent {
	propertyId = signal<string | null>(null);
	propertyForm: FormGroup;
	searchResults = signal<Array<Suggestion>>([]);
	agentSearchResults = signal<Array<Agent>>([]);
	ignoreNextSearchChange = signal(false);
	selectedLocation = signal<google.maps.LatLngLiteral | null>(null);
	selectedAgent = signal<Agent | null>(null);
	searchingForAddresses = signal(false);
	linkedEstate = signal<{ name: string } | null>(null);
	addressSelected = signal(false);
	$destroy = new Subject<void>();
	mapUrl: string | null = null;
	provinces = [...provinces];

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private modalService: ModalService
	) {
		const { propertyId } = this.route.snapshot.params;

		this.propertyId.set(propertyId ?? null);

		this.propertyForm = new FormGroup({
			address: new FormGroup({
				streetAddress: new FormControl('', [Validators.required]),
				unitNumber: new FormControl(''),
				suburb: new FormControl('', [Validators.required]),
				city: new FormControl('', [Validators.required]),
				province: new FormControl<Provinces | ''>('', [
					Validators.required
				]),
				postalCode: new FormControl('', [Validators.required]),
				buildingName: new FormControl(''),
				coordinates: new FormControl('', [
					Validators.required,
					latLngCoordinateString
				])
			}),
			additionalInformation: new FormControl(''),
			landlordTrustees: new FormArray([
				new FormGroup({
					landlordTrustee: new FormControl<LandlordTrustee>(null, [
						Validators.required
					]),
					name: new FormControl('', [Validators.required]),
					email: new FormControl('', [
						Validators.required,
						Validators.email
					]),
					phone: new FormControl('', [Validators.required])
				})
			]),
			vacant: new FormControl(false),
			occupants: new FormArray([
				new FormGroup({
					name: new FormControl('', [Validators.required]),
					email: new FormControl('', [
						Validators.required,
						Validators.email
					]),
					phone: new FormControl('', [Validators.required])
				})
			]),
			managingAgent: new FormControl('', [Validators.required])
		});
	}

	ngOnInit() {
		this.propertyForm
			.get('vacant')
			?.valueChanges.pipe(takeUntil(this.$destroy))
			.subscribe(vacant => {
				if (vacant) {
					this.removeAllOccupants();
				} else {
					this.addOccupantForm();
				}
			});

		this.propertyForm
			.get('address.coordinates')
			?.statusChanges.pipe(takeUntil(this.$destroy))
			.subscribe(status => {
				if (status === 'VALID') {
					const split = this.propertyForm
						.get('address.coordinates')
						?.value.split(',');

					this.mapUrl = staticMapImageUrl(
						{
							lat: parseFloat(split[0]),
							lng: parseFloat(split[1])
						},
						16,
						400,
						300
					);
				}
			});
	}

	addLandlordTrusteeForm() {
		(this.propertyForm.get('landlordTrustees') as FormArray).push(
			new FormGroup({
				landlordTrustee: new FormControl<LandlordTrustee>(null, [
					Validators.required
				]),
				name: new FormControl('', [Validators.required]),
				email: new FormControl('', [
					Validators.required,
					Validators.email
				]),
				phone: new FormControl('', [Validators.required])
			})
		);

		this.propertyForm.updateValueAndValidity();
	}

	addOccupantForm() {
		(this.propertyForm.get('occupants') as FormArray).push(
			new FormGroup({
				name: new FormControl('', [Validators.required]),
				email: new FormControl('', [
					Validators.required,
					Validators.email
				]),
				phone: new FormControl('', [Validators.required])
			})
		);

		this.propertyForm.updateValueAndValidity();
	}

	allFormGroups(controlName: string): FormGroup[] {
		return (this.propertyForm.controls[controlName] as FormArray)
			.controls as FormGroup[];
	}

	goBack() {
		this.router.navigate(['../'], { relativeTo: this.route });
	}

	openImageGuidelineModal() {
		this.modalService.showModal(PropertyImageGuidelineComponent);
	}

	openSpFavouritingModal() {
		this.modalService.showModal(SpFavouritingComponent);
	}

	removeAllOccupants() {
		(this.propertyForm.get('occupants') as FormArray).clear();
		this.propertyForm.updateValueAndValidity();
	}

	removeLandlordTrusteeForm(index: number) {
		(this.propertyForm.get('landlordTrustees') as FormArray).removeAt(
			index
		);
		this.propertyForm.updateValueAndValidity();
	}

	removeOccupantForm(index: number) {
		(this.propertyForm.get('occupants') as FormArray).removeAt(index);
		this.propertyForm.updateValueAndValidity();
	}

	searchAddresses(isSearching: boolean) {
		this.searchingForAddresses.set(isSearching);
	}

	onAddress(suggestion: Address | null) {
		if (!suggestion) {
			this.addressSelected.set(false);
			this.propertyForm.get('address')?.patchValue({
				unitNumber: '',
				suburb: '',
				city: '',
				province: '',
				postalCode: '',
				coordinates: ''
			});

			this.mapUrl = null;
			return;
		}

		this.ignoreNextSearchChange.set(true);

		this.propertyForm.get('address')?.patchValue({
			unitNumber: suggestion.unitNumber,
			suburb: suggestion.suburb,
			city: suggestion.city,
			province: suggestion.province,
			postalCode: suggestion.postalCode,
			coordinates: suggestion.coordinates
				?.map(c => c.toString())
				.join(',')
		});

		if (suggestion.coordinates) {
			this.selectedLocation.set({
				lat: suggestion.coordinates[0],
				lng: suggestion.coordinates[1]
			});
		}

		this.addressSelected.set(true);
	}

	setAddressType(type: AddressType) {
		this.propertyForm.get('addressType')?.setValue(type);
	}

	setLandlordTrustee(formGroup: FormGroup, option: LandlordTrustee) {
		formGroup.get('landlordTrustee')?.setValue(option);
	}

	vacant() {
		return this.propertyForm.get('vacant')?.value;
	}

	ngOnDestroy() {
		this.$destroy.next();
		this.$destroy.complete();
	}

	genericAgentTest(input: string): Observable<Array<Agent>> {
		return new Observable<Array<Agent>>(observer => {
			setTimeout(() => {
				observer.next([
					{
						name: 'John',
						lastName: 'Doe',
						email: 'john@doe.com',
						mobileNumber: '1234567890',
						agencyId: 'agency123',
						firstName: 'John',
						id: 'agent007',
						profilePicture: '',
						role: 'admin',
						userId: 'user123'
					}
				]);
				observer.complete();
			}, 200);
		});
	}

	genericAgentTransformer(item: Agent) {
		return [item.name, item.lastName].join(' ');
	}
}
