<fixify-modal (onBackdropClick)="onClose()" size="md">
	<div class="flex items-start justify-between rounded-t">
		<p class="text-xl font-semibold text-gray-900">Account not found</p>
	</div>
	<div class="pt-1 pb-6">
		<p class="text-base leading-relaxed text-gray-500 text-justify">
			We couldn't find an account with this phone number. If this is your
			first time here, continue with your email to create an account.
		</p>
	</div>
	<div
		class="flex items-center justify-center space-x-2 border-gray-200 rounded-b text-sm"
	>
		<button
			(click)="close()"
			type="button"
			class="btn-outline border-gray-200 bg-gray-200 text-sm"
		>
			Try different number
		</button>

		<button (click)="continueWithEmail()" type="button" class="btn-primary">
			Continue with email
		</button>
	</div>
</fixify-modal>

