import { Component } from '@angular/core';

@Component({
	selector: 'fixify-badge',
	standalone: true,
	imports: [],
	templateUrl: './badge.component.html',
	styleUrl: './badge.component.css'
})
export class BadgeComponent {}
