<div class="flex">
	<app-sidenav></app-sidenav>
	<main class="flex flex-col grow">
		<app-navbar></app-navbar>
		<div
			class="flex flex-col grow px-8 py-10 overflow-y-scroll overflow-auto"
		>
			<div class="grow">
				<router-outlet />
			</div>
		</div>
	</main>
</div>
