import { Injectable } from '@angular/core';
import { ApiService } from '../api-service.service';
import { BehaviorSubject, Observable, take } from 'rxjs';
import { AccountCompletion, Agency, Agent } from './agency.types';

@Injectable({
	providedIn: 'root'
})
export class AgencyService {
	constructor(private apiService: ApiService) {
		this.fetchProfile();
		this.fetchAccountOverview();
	}

	private agent$ = new BehaviorSubject<Agent | null>(null);
	private agency$ = new BehaviorSubject<Agency | null>(null);
	private agentId$ = new BehaviorSubject<string | null>(null);

	private userName$ = new BehaviorSubject<string | null>(null);

	private isSubmitting$ = new BehaviorSubject<boolean>(false);
	private isSubmittingMedia$ = new BehaviorSubject<boolean>(false);

	private accountCompletion$ = new BehaviorSubject<AccountCompletion>({
		profile: false,
		agencyDetails: false
	});

	accountCompletion() {
		return this.accountCompletion$.asObservable();
	}

	fetchProfile() {
		this.apiService.getData('agency/profile').subscribe({
			next: async data => {
				const { agent, agencyDetails } = data.body as {
					agent: Agent;
					agencyDetails: Agency;
				};
				this.setAgent({
					...agent,
					name: `${agent.firstName ?? 'Name'} ${agent.lastName ?? 'Surname'}`
				});
				this.setAgency(agencyDetails);
				this.userName$.next(
					`${agent.firstName ?? ''} ${agent.lastName ?? ''}`
				);
				this.agentId$.next(agent.id ?? null);
			},
			error: error => {
				console.error(error);
			}
		});
	}

	updateProfile(data: Partial<Agent>) {
		this.isSubmitting$.next(true);
		this.apiService.putData('agency/profile', data).subscribe({
			next: data => {
				const personalData = data.body as Agent;
				this.agent()
					.pipe(take(1))
					.subscribe({
						next: agent => {
							const updatedAgent = {
								...agent,
								...personalData
							} as Agent;
							this.setAgent(updatedAgent);
							this.fetchAccountOverview();
						}
					});
			},
			error: error => {
				console.error(error);
			},
			complete: () => {
				this.isSubmitting$.next(false);
			}
		});
	}

	updateProfilePicture(profilePicture: string | null) {
		this.isSubmittingMedia$.next(true);
		this.apiService
			.putData('agency/profile', {
				profilePicture
			})
			.subscribe({
				next: () => {
					this.fetchAccountOverview();
				},
				error: error => {
					console.error(error);
				},
				complete: () => {
					this.isSubmittingMedia$.next(false);
				}
			});
	}

	updateAgencyLogo(logoUrl: string | null) {
		this.isSubmittingMedia$.next(true);
		this.apiService
			.putData('agency/company-details', {
				logoUrl
			})
			.subscribe({
				next: () => {
					this.fetchAccountOverview();
				},
				error: error => {
					console.error(error);
				},
				complete: () => {
					this.isSubmittingMedia$.next(false);
				}
			});
	}

	updateAgency(data: Partial<Agency>) {
		this.isSubmitting$.next(true);
		this.apiService.putData('agency/company-details', data).subscribe({
			next: data => {
				const agencyData = data.body as Agency;
				this.agency()
					.pipe(take(1))
					.subscribe({
						next: agency => {
							const updatedAgency = {
								...agency,
								...agencyData
							} as Agency;
							this.setAgency(updatedAgency);
							this.fetchAccountOverview();
						}
					});
			},
			error: error => {
				console.error(error);
			},
			complete: () => {
				this.isSubmitting$.next(false);
			}
		});
	}

	fetchAccountOverview() {
		this.apiService.getData('agency/account').subscribe({
			next: data => {
				this.accountCompletion$.next(data.body as AccountCompletion);
			},
			error: error => {
				console.error(error);
			}
		});
	}

	agent(): Observable<Agent | null> {
		return this.agent$.asObservable();
	}

	agency(): Observable<Agency | null> {
		return this.agency$.asObservable();
	}

	getShortenedName() {
		return this.userName$.asObservable();
	}

	setAgent(agent: Agent) {
		this.agent$.next(agent);
	}

	setAgency(agency: Agency) {
		this.agency$.next(agency);
	}

	isSubmitting() {
		return this.isSubmitting$.asObservable();
	}

	isSubmittingMedia() {
		return this.isSubmittingMedia$.asObservable();
	}
}
