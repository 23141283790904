@if (isModalOpen()) {
	<div
		class="fixed z-50 inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
	>
		<div
			id="add-location-modal"
			(click)="hideModal()"
			class="fixed top-0 left-0 right-0 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full flex items-center justify-center"
		>
			<div
				(click)="handleModalClicked($event)"
				class="relative w-full max-w-2xl max-h-full"
			>
				<div class="relative bg-white rounded-lg shadow p-8">
					<img
						class="mb-5"
						src="assets/svg/location-pin-primary.svg"
					/>
					<div class="flex items-start justify-between rounded-t">
						<p class="text-xl font-semibold text-gray-900">
							Add location
						</p>
					</div>
					<div class="pt-1 pb-6">
						<p class="text-base leading-relaxed text-gray-500">
							Search to add an address and adjust the pin on the
							map. Each circle represents a 30km radius.
						</p>
					</div>
					<form [formGroup]="searchForm" class="mb-6">
						<fixify-search-places
							[control]="searchForm.get('search')!"
							(selectedLocation)="selectedLocation($event)"
							[(ignoreNextSearchChange)]="ignoreNextSearchChange"
							[(searchResults)]="searchResults"
						></fixify-search-places>
					</form>
					<div class="w-full mb-6 overflow-hidden rounded h-[300px]">
						<google-map
							[center]="center()"
							[zoom]="zoom()"
							height="100%"
							width="100%"
							[options]="options"
							(mapClick)="mapClick($event)"
						>
							@for (
								location of filteredLocations();
								track $index
							) {
								<map-circle
									[center]="location.latlng"
									[radius]="radius()"
									[options]="circleOptions"
									[id]="location.id"
								/>
								<map-advanced-marker
									[position]="location.latlng"
									[content]="generateContent(location.id)"
								></map-advanced-marker>
							}
							@if (clickedLocation !== null) {
								<map-circle
									[center]="clickedLocation"
									[radius]="radius()"
									[options]="circleOptions"
								/>
								<map-advanced-marker
									[position]="clickedLocation"
									[content]="createContent()"
									[options]="{}"
								></map-advanced-marker>
							}
						</google-map>
					</div>
					<div
						class="flex items-center justify-end space-x-2 border-gray-200 rounded-b"
					>
						<button
							(click)="hideModal()"
							type="button"
							class="btn-outline border-gray-200"
						>
							Cancel
						</button>

						<button
							[ngClass]="{
								disabled: !clickedLocation
							}"
							(click)="addLocation()"
							[disabled]="!clickedLocation || searchForm.invalid"
							type="button"
							class="btn-primary"
						>
							{{ editLocation() ? 'Update' : 'Add' }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
}

