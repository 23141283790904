<div class="flex w-full mt-4 justify-center">
	<div class="max-w-[600px] w-full px-8 pb-8">
		<p class="text-3xl font-medium mb-6">New maintenance job</p>
		<div class="rounded bg-stone py-4 px-6">
			<div class="flex items-center justify-between">
				<div class="flex-grow mr-6">
					<p class="text-sm font-normal text-gray-500 mb-0.5">
						Property address
					</p>
					<p class="text-base font-normal text-gray-900">
						{{ linkedProperty().address.formatted }}
					</p>
				</div>
				@if (canInterchangeProperty()) {
					<div
						(click)="changeAddress()"
						class="px-3 py-2 cursor-pointer"
					>
						<p class="text-primary-500">Edit</p>
					</div>
				}
			</div>
			@if (linkedProperty().estateName) {
				<div class="mt-4">
					<p class="text-sm font-normal text-gray-500">
						Complex/Estate
					</p>
					<p class="text-base font-normal text-gray-900">
						{{ linkedProperty().estateName ?? 'N/A' }}
					</p>
				</div>
			}
		</div>
		<form [formGroup]="detailsForm">
			<div class="my-8">
				<fixify-divider class="!bg-gray-100"></fixify-divider>
			</div>
			@if (linkedProperty().estateId) {
				<div id="complex-estate">
					<p class="text-xl font-medium text-gray-900 mb-0.5">
						Where's the issue?
					</p>
					<p class="text-sm font-normal text-gray-700 mb-8">
						Specify whether this maintenance is needed on private or
						common property.
					</p>

					@if (missingPropertyType()) {
						<div class="mb-4">
							<fixify-validation-message
								text="Select an option to continue"
							></fixify-validation-message>
						</div>
					}

					<div class="gap-4 flex w-full">
						<fixify-request-card
							class="flex-1"
							titleSizeOverride="!text-xl"
							(changeCardSelect)="
								changePropertyType(PropertyType.PRIVATE)
							"
							imgUrl="assets/svg/private-property.svg"
							[selected]="propertyType() === 'private'"
							value="private"
							text="Private property"
							subText="A space that is owned and used exclusively."
							[borderError]="missingPropertyType()"
						></fixify-request-card>
						<fixify-request-card
							class="flex-1"
							titleSizeOverride="!text-xl"
							(changeCardSelect)="
								changePropertyType(PropertyType.COMMON)
							"
							imgUrl="assets/svg/common-property.svg"
							[selected]="propertyType() === 'common'"
							value="common"
							text="Common property"
							subText="A shared area that everyone in a building or complex can use."
							[borderError]="missingPropertyType()"
						></fixify-request-card>
					</div>
					<div
						[class.hidden]="propertyType() !== 'common'"
						class="mt-6"
					>
						<p class="font-sm font-medium font-gray-900 mb-2">
							Add specific location/landmark
						</p>
						<fixify-text-input
							[control]="detailsForm.controls['landmark']"
							inputType="text"
							placeholder="Add any landmarks you think might be useful"
						></fixify-text-input>
					</div>
					<div class="my-8">
						<fixify-divider class="!bg-gray-100"></fixify-divider>
					</div>
				</div>
			}

			<p class="text-xl font-medium text-gray-900 mb-0.5">
				What maintenance do you need?
			</p>
			<p class="text-sm font-normal text-gray-700">
				Select one category.
			</p>
			<div class="pt-8">
				@if (missingCategory()) {
					<fixify-validation-message
						text="Select 1 category"
					></fixify-validation-message>
				}

				<p class="text-sm font-medium text-gray-700 mb-4">
					Our top categories
				</p>
				<div class="grid grid-cols-3 gap-2">
					@for (
						topCategory of linkedProperty().availableCategories;
						track $index
					) {
						<div
							[ngClass]="{
								'border-primary-500':
									topCategory === selectedCategory(),
								'!border-red-700': missingCategory()
							}"
							(click)="selectCategory(topCategory)"
							class="flex items-center p-4 rounded-lg border-2 border-gray-100 cursor-pointer"
						>
							<img
								class="mr-3 max-h-5 max-w-5"
								src="assets/svg/calendar-heart-dark.svg"
							/>
							<p class="text-sm font-medium text-gray-900">
								{{ topCategory.name }}
							</p>
						</div>
					}
				</div>
			</div>
			<div class="my-8">
				<fixify-divider class="!bg-gray-100"></fixify-divider>
			</div>
			<p class="text-xl font-medium text-gray-900 mb-0.5">
				Let's get into the details
			</p>
			<p class="text-sm font-normal text-gray-700">
				Provide us with more information about this job.
			</p>

			<div class="mt-8 mb-6">
				@if (missingDetails()) {
					<fixify-validation-message
						text="Provide a short description"
					></fixify-validation-message>
				}
				<p class="font-sm font-medium font-gray-900 mb-2">
					What are the details?
				</p>
				<fixify-text-input
					[control]="detailsForm.controls['details']"
					inputType="textarea"
					placeholder="Enter the job details"
					[showErrorMessage]="false"
				></fixify-text-input>
			</div>
			<div [class.hidden]="propertyType() !== 'private'" class="mb-6">
				<p class="font-sm font-medium font-gray-900 mb-2">
					Extra details
				</p>
				<fixify-text-input
					[control]="detailsForm.controls['extra']"
					inputType="text"
					placeholder="Add additional instuctions for entry or access"
				></fixify-text-input>
			</div>
		</form>
		@if (missingImages()) {
			<fixify-validation-message
				text="Add at least 1 image"
			></fixify-validation-message>
		}
		<div>
			<p class="text-xl font-medium text-gray-900 mb-2">Add images</p>
			<div class="grid grid-cols-6 gap-2">
				@for (image of images(); track $index) {
					<div class="relative w-20 h-20">
						<img
							class="w-full h-full object-cover rounded-lg"
							src="{{ image.url }}"
						/>
						<div
							(click)="deleteMedia(image)"
							class="absolute top-0 right-0 transform translate-x-1/4 -translate-y-1/4 border-2 border-white rounded-full cursor-pointer"
						>
							<img src="assets/svg/remove-image-cross.svg" />
						</div>
					</div>
				}
				<div
					(click)="selectFilesFromDevice()"
					[ngClass]="{ 'border-red-700 border-2': missingImages() }"
					class="min-w-20 min-h-20 flex justify-center items-center bg-gray-100 w-full h-full rounded-lg cursor-pointer"
				>
					<img src="assets/svg/plus-primary.svg" />
				</div>
			</div>
		</div>
		<div class="my-8">
			<fixify-divider class="!bg-gray-100"></fixify-divider>
		</div>
		<p class="text-xl font-medium text-gray-900 mb-0.5">
			Add your contact details
		</p>
		<p class="text-sm font-normal text-gray-700">
			We will send you email updates on the progress of this job.
		</p>
		<div class="mt-8">
			@if (missingResponsibility()) {
				<fixify-validation-message
					text="Select an option to continue"
				></fixify-validation-message>
			}
			<p class="mb-4 text-sm font-medium text-gray-900">
				Who are you logging this as?
			</p>
			<div class="grid grid-cols-3 gap-2">
				@for (resp of responsibilities; track $index) {
					<div
						[ngClass]="{
							'border-primary-500':
								personalResponsibility() === resp,
							'!border-red-700': missingResponsibility()
						}"
						(click)="selectResponsibility(resp)"
						class="flex p-4 rounded-lg border-2 border-gray-100 cursor-pointer"
					>
						<img
							class="mr-3 max-h-5 max-w-5"
							src="assets/svg/{{ resp.icon }}.svg"
						/>
						<p class="text-sm font-medium text-gray-900">
							{{
								resp.name.includes('other')
									? 'Other'
									: 'I am a ' + resp.name
							}}
						</p>
					</div>
				}
			</div>
		</div>
		<div class="my-8">
			@if (missingOccupant()) {
				<fixify-validation-message
					text="Add your contact information"
				></fixify-validation-message>
			}
			<p class="mb-4 text-sm font-medium text-gray-900">
				Occupant information
			</p>
			<form class="flex flex-col gap-6" [formGroup]="occupantForm">
				<div [class.hidden]="!notATenant()" class="flex items-center">
					<input
						id="checkbox-vacant"
						formControlName="isVacant"
						type="checkbox"
						class="checkbox-primary"
					/>
					<label
						for="checkbox-vacant"
						class="ms-2 text-sm font-normal text-gray-900"
						>This is a vacant propery</label
					>
				</div>
				<!-- <fixify-text-input
					[class.hidden]="personalResponsibility()?.name !== 'other'"
					[control]="occupantForm.controls['reference']"
					errorText="Please enter a reference"
					inputType="text"
					placeholder="Enter a reference (i.e 'Visitor' or 'Security')"
					[showErrorMessage]="false"
				></fixify-text-input> -->
				<fixify-text-input
					[class.hidden]="hideContactInformationRequirements()"
					[control]="occupantForm.controls['name']"
					errorText="Please enter your name"
					inputType="text"
					placeholder="Full name"
					[showErrorMessage]="false"
				></fixify-text-input>
				<fixify-text-input
					[class.hidden]="hideContactInformationRequirements()"
					[control]="occupantForm.controls['email']"
					errorText="Please enter a valid email address"
					inputType="email"
					placeholder="Email address"
					[showErrorMessage]="false"
				></fixify-text-input>
				<div
					[class.hidden]="hideContactInformationRequirements()"
					class="flex gap-4"
				>
					<div class="w-2/5">
						<select
							formControlName="country"
							id="country"
							class="form-input"
						>
							@for (country of countries; track $index) {
								<option
									[selected]="
										country.name === selectedCountry.name
									"
									[value]="country.code"
								>
									{{ country.name }}
								</option>
							}
						</select>
					</div>
					<div class="w-3/5">
						<fixify-text-input
							[control]="occupantForm.get('phone')!"
							id="phone"
							inputType="tel"
							placeholder="{{ selectedCountry.dial_code }}"
							[showErrorMessage]="false"
						></fixify-text-input>
					</div>
				</div>
			</form>
		</div>
		<div [class.hidden]="!notATenant()" class="my-8">
			@if (missingPrimaryContact()) {
				<fixify-validation-message
					text="Add primary contact information"
				></fixify-validation-message>
			}
			<p class="text-sm font-medium text-gray-900">
				Primary contact information
			</p>
			<p class="mb-4 text-sm font-normal text-gray-400">
				This contact will be used if an occupant is unavailable or
				unreachable
			</p>
			<form class="flex flex-col gap-6" [formGroup]="primaryContactForm">
				<fixify-text-input
					[class.hidden]="personalResponsibility()?.name !== 'other'"
					[control]="primaryContactForm.controls['reference']"
					errorText="Please enter a reference"
					inputType="text"
					placeholder="Enter a reference (i.e 'Visitor' or 'Security')"
					[showErrorMessage]="false"
				></fixify-text-input>
				<fixify-text-input
					[control]="primaryContactForm.controls['name']"
					errorText="Please enter your name"
					inputType="text"
					placeholder="Full name"
					[showErrorMessage]="false"
				></fixify-text-input>
				<fixify-text-input
					[control]="primaryContactForm.controls['email']"
					errorText="Please enter a valid email address"
					inputType="email"
					placeholder="Email address"
					[showErrorMessage]="false"
				></fixify-text-input>
				<div class="flex gap-4">
					<div class="w-2/5">
						<select
							formControlName="country"
							id="country"
							class="form-input"
						>
							@for (country of countries; track $index) {
								<option
									[selected]="
										country.name === selectedCountry.name
									"
									[value]="country.code"
								>
									{{ country.name }}
								</option>
							}
						</select>
					</div>
					<div class="w-3/5">
						<fixify-text-input
							[control]="primaryContactForm.get('phone')!"
							id="phone"
							inputType="tel"
							placeholder="{{ selectedCountry.dial_code }}"
							[showErrorMessage]="false"
						></fixify-text-input>
					</div>
				</div>
			</form>
		</div>
		@if (!canSubmit()) {
			<fixify-validation-message
				text="Fill in all required fields"
			></fixify-validation-message>
		}
		<div class="mt-4 flex gap-3">
			<button class="btn-outline w-full">Cancel request</button>
			<button (click)="submitJob()" class="btn-primary w-full">
				Submit job
			</button>
		</div>
	</div>
</div>

