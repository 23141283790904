<div class="max-w px-12 md:px-22 xl:px-44">
	<app-breadcrumb
		[items]="this.breadcrumbs() | async"
		public
	></app-breadcrumb>

	<div class="flex justify-start items-center pb-2">
		<div class="grow flex items-center">
			<h1 class="font-medium text-2xl leading-tight pr-2 py-2.5">
				Categories
			</h1>
			@if (!(categoryStatusses$ | async)?.hasCategories) {
				<span
					class="inline-block bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded"
					>Incomplete</span
				>
			} @else if (
				(categoryStatusses$ | async)?.allVerifying ||
				(categoryStatusses$ | async)?.someVerifyingWithoutActive
			) {
				<span
					class="inline-block bg-primary-100 text-primary-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded"
					>Verifying</span
				>
			} @else if ((categoryStatusses$ | async)?.fullyVerified) {
				<span
					class="inline-block bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded"
					>Verified</span
				>
			} @else if ((categoryStatusses$ | async)?.allDeactivated) {
				<span
					class="inline-block bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded"
					>Deactivated</span
				>
			} @else {
				<span
					class="inline-block bg-gray-100 text-gray-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded"
					>Partially Verified</span
				>
			}
		</div>
		@if ((categoryStatusses$ | async)?.hasCategories || addCategory()) {
			<div>
				<button
					(click)="addOrSaveChanges()"
					[ngClass]="{ disabled: disableSave() }"
					[disabled]="disableSave()"
					class="grow-0 btn btn-primary"
				>
					{{ buttonText() }}
				</button>
			</div>
		}
	</div>

	<div class="h-8"></div>

	@if (!addCategory() && !(categoryStatusses$ | async)?.hasCategories) {
		<app-alert class="mb-8" [type]="'grey'" [showAlert]="true">
			You will only receive jobs for the categories you’re verified for.
		</app-alert>
	}

	@if ((categoryStatusses$ | async)?.anyVerifying && !addCategory()) {
		<app-alert class="mb-8" [type]="'grey'" [showAlert]="true">
			Kindly wait as we verify your documents (this could take up to 5
			business days). During this period, you won’t be able to edit this
			category and tasks.
		</app-alert>
	}

	<div
		class="grid gap-x-6 lg:gap-x-12 xl:gap-x-20 gap-y-6 grid-cols-1 md:grid-cols-2 gap-6 pt-6"
	>
		@if (!addCategory() && !(categoryStatusses$ | async)?.hasCategories) {
			<div class="flex flex-col justify-center items-center">
				<img class="mb-4" src="assets/svg/empty-mailbox.svg" />
				<p class="text-base font-semibold mb-1">No categories added</p>
				<p class="text-sm font-normal mb-4">
					Add categories to receive work
				</p>
				<button (click)="toggleAddCategory()" class="btn-primary small">
					Add category
				</button>
			</div>
		}

		@if (addCategory()) {
			<fixify-new-categories
				[unavailableCategoryIds]="
					(unavailableCategoryIds$ | async) ?? []
				"
				[save]="save"
				[categories]="(categories$ | async) ?? []"
				(formValidChange)="formValidChange($event)"
				(noCategoriesChange)="noCategoriesToAdd()"
			></fixify-new-categories>
		}

		<div [ngClass]="{ 'opacity-50 pointer-events-none': addCategory() }">
			@if ((spCategories$ | async)?.length != 0) {
				<fixify-current-categories
					[unavailableCategoryIds]="
						(unavailableCategoryIds$ | async) ?? []
					"
					[categories]="(categories$ | async) ?? []"
					[spCategories]="(spCategories$ | async) ?? []"
					(editingIdChange)="editingIdChange($event)"
				></fixify-current-categories>
			}
		</div>

		<div class="row-start-1 col-start-1 md:col-start-2 row-span-4">
			<div
				class="p-6 bg-white rounded-lg border border-gray-200 grid gap-y-1 cursor-pointer"
			>
				<div class="flex-shrink-0 mb-2">
					<img src="assets/svg/category-edit.svg" />
				</div>

				<p class="text-gray-500">
					Add the categories you offer, specific tasks and supporting
					documents. We will review and verify all uploads.
				</p>
			</div>
		</div>
	</div>
</div>

