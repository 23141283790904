<div class="relative w-full h-full">
	<div
		class="flex-none min-h-8 min-w-12 h-full w-full rounded-md bg-gray-100 flex flex-col items-center justify-center"
	>
		<div
			class="flex items-center justify-center w-full h-full absolute top-0 left-0 leading-none text-xs font-medium text-gray-900"
		>
			<fixify-icon
				class="text-gray-400 w-4 h-4"
				name="image-03"
			></fixify-icon>
		</div>
	</div>

	@if (url()) {
		<img
			class="absolute rounded top-0 object-cover w-full h-full"
			[src]="url()"
		/>
	}
</div>

