import { Component, input } from '@angular/core';
import { StatusPillComponent } from '../status-pill/status-pill.component';
import { TitleCasePipe } from '@angular/common';

export interface QuoteItem {
	name: string;
	url: string;
	createdAt: string;
	status: 'approved' | 'rejected';
	reason?: string;
}

@Component({
	selector: 'fixify-quote-list-item',
	standalone: true,
	imports: [StatusPillComponent, TitleCasePipe],
	templateUrl: './quote-list-item.component.html',
	styleUrl: './quote-list-item.component.css'
})
export class QuoteListItemComponent {
	quoteItem = input.required<QuoteItem>();

	download() {
		window.open(this.quoteItem().url, '_blank');
	}
}
