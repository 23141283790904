<div class="w-full h-full flex justify-center items-center">
	<div class="w-[258px] py-10 px-16 border-2 border-gray-100 rounded">
		<div class="flex flex-col justify-center items-center">
			<img
				class="mb-4 animate-spin"
				src="assets/svg/spinner-light-primary.svg"
			/>
			<p class="text-sm font-medium text-center">
				Searching for linked properties...
			</p>
		</div>
	</div>
</div>

