import { Component, input } from '@angular/core';
import { PropertyAvatarComponent } from '../property-avatar/property-avatar.component';

@Component({
	selector: 'fixify-sp-info-card',
	standalone: true,
	imports: [PropertyAvatarComponent],
	templateUrl: './sp-info-card.component.html',
	styleUrl: './sp-info-card.component.css'
})
export class SpInfoCardComponent {
	companyName = input.required<string>();
	imageUrl = input<string>('');
}
