<div class="max-w px-12 md:px-22 xl:px-44">
	<app-breadcrumb
		[items]="this.breadcrumbService.breadcrumbs$ | async"
		public
	></app-breadcrumb>

	<div class="flex justify-start items-center">
		<div class="grow flex items-center">
			<h1 class="font-medium text-2xl leading-tight pr-2 py-2.5">
				Agency details
			</h1>
			@if (!accountCompletion()) {
				<span
					class="inline-block bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded"
					>Incomplete</span
				>
			}
		</div>
		<div>
			<button
				type="submit"
				(click)="formElement.ngSubmit.emit()"
				[disabled]="!formValid || !formTouched || isSubmitting"
				[ngClass]="{
					'opacity-50 cursor-not-allowed':
						!formValid || !formTouched || isSubmitting
				}"
				class="grow-0 btn btn-primary"
			>
				{{ isSubmitting ? 'Saving...' : 'Save' }}
			</button>
		</div>
	</div>

	<div class="h-8"></div>

	<form
		#formElement="ngForm"
		[formGroup]="agencyDetailsForm"
		(ngSubmit)="onSubmit()"
	>
		<div
			class="grid gap-x-6 lg:gap-x-12 gap-y-6 grid-cols-1 md:grid-cols-2 pt-6"
		>
			<div>
				<fixify-text-input
					[control]="agencyDetailsForm.get('name')!"
					label="Agency name"
					id="name"
					placeholder="Enter the agency name"
				></fixify-text-input>
			</div>
			<div class="md:row-span-8">
				<fixify-upload
					[largerLogo]="true"
					label="Agency logo"
					[url]="agencyLogoUrl()"
					[(uploading)]="uploadState.uploading"
					[(percentage)]="uploadState.percentage"
					(uploadFile)="uploadMedia($event)"
					(removeFile)="deleteMedia()"
				></fixify-upload>
			</div>
			<div>
				<fixify-text-input
					[control]="agencyDetailsForm.get('contactNumber')!"
					label="Contact number"
					id="contactNumber"
					placeholder="Enter the agency contact number"
				></fixify-text-input>
			</div>
		</div>
	</form>
</div>

