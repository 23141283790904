import { Component, input } from '@angular/core';
import { ToastService, ToastType } from '../../data-access/toast.service';
import { IconComponent } from '../icon/icon.component';
import { NgClass } from '@angular/common';

@Component({
	selector: 'app-toast',
	standalone: true,
	templateUrl: './toast.component.html',
	styleUrl: './toast.component.css',
	imports: [IconComponent, NgClass]
})
export class ToastComponent {
	uniqueId = input.required<string>();
	message = input<string>('');
	type = input<ToastType>('info');

	constructor(private toastService: ToastService) {}

	getIconName() {
		switch (this.type()) {
			case 'info':
				return 'annotation-info';
			case 'success':
				return 'check';
			case 'error':
				return 'alert-triangle';
			default:
				return 'info';
		}
	}

	onDismiss() {
		this.toastService.dismiss(this.uniqueId());
	}
}
