import { Component, input, model, output, signal } from '@angular/core';
import {
	AbstractControl,
	FormControl,
	ReactiveFormsModule
} from '@angular/forms';
import {
	Observable,
	Subject,
	debounceTime,
	distinctUntilChanged,
	exhaustMap,
	takeUntil
} from 'rxjs';
import { NgClass } from '@angular/common';

@Component({
	selector: 'fixify-generic-search-input',
	standalone: true,
	imports: [NgClass, ReactiveFormsModule],
	templateUrl: './generic-search-input.component.html',
	styleUrl: './generic-search-input.component.css'
})
export class GenericSearchInputComponent<T> {
	private searchSubject = new Subject<{
		input: string;
	}>();
	control = input.required<AbstractControl>();
	ngControl!: FormControl;
	searchResults = model<Array<T>>([]);
	showRequiredStar = input<boolean>(false);
	label = input<string>('');
	disabled = input<boolean>(false);
	placeholder = input<string>('Search...');
	ignoreNextSearchChange = signal<boolean>(false);
	selectedResult = output<T | null>();
	searching = output<boolean>();
	destroy$ = new Subject<void>();
	genericSearchFunction =
		input.required<(input: string) => Observable<Array<T>>>();
	transformer = input<(item: T) => string>(item => {
		if (typeof item === 'string') {
			return item;
		} else {
			return JSON.stringify(item);
		}
	});

	ngOnInit() {
		this.ngControl = this.control() as FormControl;

		this.initSearchStream()
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: results => {
					this.searchResults.set(results ?? []);
					this.searching.emit(false);
				},
				error: error => {
					console.error(error);
				}
			});

		this.ngControl.valueChanges
			.pipe(
				debounceTime(500),
				takeUntil(this.destroy$),
				distinctUntilChanged()
			)
			.subscribe(val => {
				if (this.ignoreNextSearchChange() || !val) {
					this.ignoreNextSearchChange.set(false);
					return;
				}
				this.searching.emit(true);
				this.selectedResult.emit(null);

				this.search(val);
			});
	}

	initSearchStream(): Observable<Array<T>> {
		return this.searchSubject
			.asObservable()
			.pipe(
				exhaustMap(({ input }) => this.genericSearchFunction()(input))
			);
	}

	search(input: string): void {
		this.searchSubject.next({ input });
	}

	selected(suggestion: T) {
		this.ignoreNextSearchChange.set(true);
		this.searchResults.set([]);
		this.ngControl.setValue(this.transformer()(suggestion));
		this.selectedResult.emit(suggestion);
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
