import { Component, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { DividerComponent } from '../../shared/ui/divider/divider.component';
import {
	AgencyTileComponent,
	LinkedAgency
} from './ui/agency-tile/agency-tile.component';
import { AddLocationModalComponent } from '../../shared/ui/modals/add-location-modal/add-location-modal.component';
import { NoLocationsComponent } from './ui/no-locations/no-locations.component';
import { GoogleMap, MapAdvancedMarker, MapCircle } from '@angular/google-maps';
import { DeleteModalComponent } from '../../shared/ui/modals/delete/delete.component';
import { ViewLinkedAgencyModalComponent } from '../../shared/ui/modals/view-linked-agency-modal/view-linked-agency-modal.component';
import { Location } from '../../shared/data-access/service-provider/service-provider.types';
import { ServiceProviderService } from '../../shared/data-access/service-provider/service-provider.service';
import { Subject, takeUntil } from 'rxjs';

const DEFAULT_LAT_LNG = { lat: -28.5595, lng: 22.9375 };

@Component({
	selector: 'app-locations',
	standalone: true,
	imports: [
		DividerComponent,
		AgencyTileComponent,
		AddLocationModalComponent,
		NoLocationsComponent,
		GoogleMap,
		MapCircle,
		MapAdvancedMarker,
		DeleteModalComponent,
		ViewLinkedAgencyModalComponent
	],
	templateUrl: './locations.component.html',
	styleUrl: './locations.component.css'
})
export class LocationsComponent implements OnInit, OnDestroy {
	private destroy$ = new Subject<void>();
	radius = signal<number>(30000);
	circleOptions: google.maps.CircleOptions = {
		fillColor: '#FFA757',
		fillOpacity: 0.4,
		strokeWeight: 0,
		clickable: false
	};
	center = signal(DEFAULT_LAT_LNG);
	zoom = signal(5);
	options: google.maps.MapOptions = {
		streetViewControl: false,
		zoomControl: false,
		mapTypeControl: false,
		fullscreenControl: false,
		mapId: '4ae9361332aa6c90',
		minZoom: 5,
		restriction: {
			latLngBounds: {
				north: -20.1258,
				south: -36.8192,
				west: 14.5126,
				east: 35.8913
			},
			strictBounds: false
		}
	};
	isDeleteModalOpen = signal<boolean>(false);
	isAddLocationModalOpen = signal<boolean>(false);
	isViewLinkedAgencyModalOpen = signal<boolean>(false);
	isDelinkAgencyModalOpen = signal<boolean>(false);
	serviceProviderService = inject(ServiceProviderService);
	locations: Array<Location> = [];

	selectedLocation = signal<Location | null>(null);
	selectedAgency = signal<LinkedAgency | null>(null);

	linkedAgencies: Array<LinkedAgency> = [
		// {
		// 	id: '123',
		// 	name: 'Oasis Properties',
		// 	url: 'https://www.agency1.com',
		// 	formattedAddress:
		// 		'901 Scottsridge Estate, Street, Big Bay, Western Cape, 1009',
		// 	managingAgent: 'John Doe',
		// 	email: 'john@doe.com',
		// 	contactNumber: '021 123 4567'
		// },
		// {
		// 	id: '2345',
		// 	name: 'PrimeNest Realty',
		// 	url: 'https://www.agency2.com',
		// 	formattedAddress: '383 Manitoba Drive, Faerie Glen, Pretoria, 0081',
		// 	managingAgent: 'Jane Doe',
		// 	email: 'john@doe.com',
		// 	contactNumber: '021 123 4567'
		// },
		// {
		// 	id: '3456',
		// 	name: 'NextDoor Realty Group',
		// 	url: 'https://www.agency3.com',
		// 	formattedAddress: 'Durban',
		// 	managingAgent: 'John Doe',
		// 	email: 'john@doe.com',
		// 	contactNumber: '021 123 4567'
		// },
		// {
		// 	id: '4567',
		// 	name: 'Summit property Solutions Brackenfell',
		// 	url: 'https://www.agency3.com',
		// 	formattedAddress: 'Pretoria',
		// 	managingAgent: 'Jane Doe',
		// 	email: 'john@doe.com',
		// 	contactNumber: '021 123 4567'
		// }
	];

	ngOnInit(): void {
		this.serviceProviderService
			.locations()
			.pipe(takeUntil(this.destroy$))
			.subscribe(locations => {
				this.locations = locations;
			});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
	}

	addLocation(location: Location) {
		this.serviceProviderService.addLocation(
			location.coordinates,
			location.name
		);
		// this.locations.push(location);
		this.goToLocation(location.coordinates);
	}

	deleteSelectedLocation() {
		if (this.selectedLocation() === null) {
			return;
		}
		this.serviceProviderService.removeLocation(
			this.selectedLocation()?.id ?? ''
		);
		this.selectedLocation.set(null);
	}

	editLocation(location: Location) {
		this.serviceProviderService.editLocation(
			location.id,
			location.coordinates,
			location.name
		);
		this.goToLocation(location.coordinates);
	}

	generateContent(id: string) {
		const content = document.createElement('div');
		content.id = id;
		content.innerHTML = `<img src="assets/svg/map-pin.svg" class="translate-y-3" />`;
		return content;
	}

	async goToLocation(location: [number, number]) {
		// change to force zoom, otherwise the zoom will not reset if manual zoom was used by the user
		if (this.zoom() === 10) {
			this.zoom.set(10.2);
		} else {
			this.zoom.set(10);
		}

		// wait for zoom to finish, otherwise the map will not center correctly due to it's set restrictions
		await new Promise(resolve => setTimeout(resolve, 1));
		this.center.set({
			lat: location[0],
			lng: location[1]
		});
	}

	openAddLocationModal() {
		this.selectedLocation.set(null);
		this.isAddLocationModalOpen.set(true);
	}

	openModalAsEdit(location: Location, $event: MouseEvent) {
		$event.stopPropagation();
		this.selectedLocation.set(location);
		this.isAddLocationModalOpen.set(true);
	}

	openDeleteModal(location: Location, $event: MouseEvent) {
		$event.stopPropagation();
		this.isDeleteModalOpen.set(true);
		this.selectedLocation.set(location);
	}

	openViewLinkedAgencyModal(agency: LinkedAgency) {
		this.selectedAgency.set(agency);
		this.isViewLinkedAgencyModalOpen.set(true);
	}

	openDelinkAgencyModal() {
		if (!this.selectedAgency()) {
			return;
		}
		this.isDelinkAgencyModalOpen.set(true);
	}

	delinkAgency() {
		if (!this.selectedAgency()) {
			return;
		}

		const index = this.linkedAgencies.findIndex(
			agency => agency.id === this.selectedAgency()?.id
		);

		if (index === -1) {
			return;
		}

		this.linkedAgencies.splice(index, 1);
	}
}
