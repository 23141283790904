<div
	class="flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow"
	role="alert"
>
	<div
		[ngClass]="{
			'bg-red-100': type() === 'error',
			'bg-green-100': type() === 'success',
			'bg-primary-100': type() === 'info'
		}"
		class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-primary-500 rounded-lg"
	>
		<fixify-icon
			[ngClass]="{
				'text-red-700': type() === 'error',
				'text-green-700': type() === 'success',
				'text-primary-700': type() === 'info'
			}"
			class="w-4 h-4"
			[name]="getIconName()"
		></fixify-icon>
	</div>
	<div class="ms-3 text-sm font-normal">{{ message() }}</div>
	<button
		(click)="onDismiss()"
		type="button"
		class="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-1 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8"
	>
		<span class="sr-only">Close</span>
		<fixify-icon class="w-3 h-3 text-gray-500" name="x-close"></fixify-icon>
	</button>
</div>
