import { Component, input, signal } from '@angular/core';

@Component({
	selector: 'fixify-status-pill',
	standalone: true,
	imports: [],
	templateUrl: './status-pill.component.html',
	styleUrl: './status-pill.component.css'
})
export class StatusPillComponent {
	color = input<string>('');
	classText = signal<string>('');

	ngOnInit() {
		switch (this.color()) {
			case 'green':
				this.classText.set('bg-green-100 text-green-800');
				break;
			case 'primary':
				this.classText.set('bg-primary-100 text-primary-800');
				break;
			case 'bright-orange':
				this.classText.set('bg-orange-500 text-white');
				break;
			case 'red':
				this.classText.set('bg-red-100 text-red-800');
				break;
			case 'yellow':
				this.classText.set('bg-yellow-100 text-yellow-800');
				break;
			case 'purple':
				this.classText.set('bg-purple-100 text-purple-800');
				break;
			default:
				this.classText.set('bg-gray-100 text-gray-800');
				break;
		}
	}
}
