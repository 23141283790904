import {
	Injectable,
	ComponentRef,
	ViewContainerRef,
	Type,
	OnDestroy
} from '@angular/core';
import { Observable, Subject, firstValueFrom } from 'rxjs';

@Injectable()
export abstract class ModalComponent<T, R> implements OnDestroy {
	private closeSubject = new Subject<R | undefined>();
	closeObservable: Observable<R | undefined> =
		this.closeSubject.asObservable();

	protected data?: T;

	setData(data: T): void {
		this.data = data;
		this.onDataSet();
	}

	close(result?: R): void {
		this.closeSubject.next(result);
	}

	ngOnDestroy(): void {
		this.closeSubject.complete();
	}

	// This method can be overridden in child classes if needed
	protected onDataSet(): void {
		return;
	}
}

@Injectable({
	providedIn: 'root'
})
export class ModalService {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private modalComponentRef: ComponentRef<ModalComponent<any, any>> | null =
		null;
	private viewContainerRef: ViewContainerRef | null = null;

	setViewContainerRef(vcr: ViewContainerRef) {
		this.viewContainerRef = vcr;
	}

	async showModal<T, R>(
		component: Type<ModalComponent<T, R>>,
		data?: T
	): Promise<R | undefined> {
		if (!this.viewContainerRef) {
			throw new Error(
				'ViewContainerRef not set. Make sure to use the modalHost directive in your template.'
			);
		}

		this.modalComponentRef =
			this.viewContainerRef.createComponent(component);

		if (data && this.modalComponentRef.instance.setData) {
			this.modalComponentRef.instance.setData(data);
		}

		this.modalComponentRef.location.nativeElement.classList.add(
			'no-doc-scroll'
		);

		const result = await firstValueFrom(
			this.modalComponentRef.instance.closeObservable
		);
		this.hideModal();
		console.log(result);
		return result;
	}

	private hideModal() {
		if (this.modalComponentRef) {
			this.modalComponentRef.destroy();
			this.modalComponentRef = null;
		}
	}
}
