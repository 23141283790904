import { NgClass } from '@angular/common';
import { Component, model, output } from '@angular/core';
import {
	Auth,
	ConfirmationResult,
	linkWithPhoneNumber,
	RecaptchaVerifier
} from '@angular/fire/auth';
import { FormsModule } from '@angular/forms';

@Component({
	selector: 'fixify-verify-mobile-number-modal',
	standalone: true,
	imports: [NgClass, FormsModule],
	templateUrl: './verify-mobile-number.component.html',
	styleUrl: './verify-mobile-number.component.css'
})
export class VerifyMobileNumberComponent {
	isModalOpen = model<boolean>(true);

	deleteClicked() {
		this.hideModal();
	}

	hideModal() {
		this.isModalOpen.set(false);
	}

	phoneNumber = model.required<string>();
	verified = output<boolean>();
	verificationId = '';
	verificationCode = '';
	errorMessage = '';
	recaptchaVerifier: RecaptchaVerifier | undefined;
	recaptchaConfirmationResult: ConfirmationResult | undefined;

	constructor(private auth: Auth) {}

	ngOnInit() {
		this.renderReCaptcha();
	}

	renderReCaptcha() {
		setTimeout(() => {
			this.recaptchaVerifier = new RecaptchaVerifier(
				this.auth,
				'recaptcha-container',
				{
					size: 'normal',
					callback: () => {
						this.onCaptchaVerified();
					},
					'expired-callback': () => {
						this.renderReCaptcha();
					},
					'error-callback': () => {
						this.renderReCaptcha();
					}
				}
			);

			this.recaptchaVerifier.render();
		}, 0);
	}

	onCaptchaVerified() {
		// Enable the send OTP button or automatically start the signInWithPhoneNumber process
	}

	disableVerify() {
		return this.verificationCode.length !== 6 || !this.recaptchaVerifier;
	}

	sendOTP() {
		if (!this.recaptchaVerifier) {
			console.error('reCAPTCHA not initialized');
			return;
		}

		if (!this.auth.currentUser) throw new Error('User not logged in');

		linkWithPhoneNumber(
			this.auth.currentUser,
			this.phoneNumber(),
			this.recaptchaVerifier
		)
			.then(confirmationResult => {
				this.recaptchaConfirmationResult = confirmationResult;
				this.verificationId = confirmationResult.verificationId;
			})
			.catch(error => {
				console.error('Error sending OTP', error);
				const match = error.message.match(/\((.*?)\)/);
				if (match && match[1]) {
					this.errorMessage = match[1];
				}
				this.renderReCaptcha();
			});
	}

	verifyOTP() {
		if (!this.recaptchaConfirmationResult) {
			console.error('OTP not sent');
			return;
		}

		this.recaptchaConfirmationResult
			.confirm(this.verificationCode)
			.then(userCredential => {
				if (userCredential.user) {
					this.verified.emit(true);
					this.hideModal();
				}
			})
			.catch(error => {
				const match = error.message.match(/\((.*?)\)/);
				if (match && match[1]) {
					this.errorMessage = match[1];
				}
				console.error('Error verifying OTP', error);
			});
	}
}
