<div class="w-full">
	<p class="leading-tight text-3xl font-medium pb-1">Welcome to Fixify</p>
	<p class="leading-tight text-base font-normal text-gray-600 mb-8">
		@if (loginMode() === 'phone') {
			We’ll text you to confirm your number. Standard message and data
			rates apply.
		} @else {
			Enter your email address to continue.
		}
		<span
			(click)="openPrivacyPolicy()"
			class="underline text-base font-normal cursor-pointer"
			>Terms and Conditions.</span
		>
	</p>

	<form [formGroup]="loginForm" class="max-w">
		<div
			[ngClass]="{
				hidden: loginMode() === 'mail'
			}"
		>
			<div class="mb-4">
				<select
					formControlName="country"
					id="country"
					class="form-input"
				>
					@for (country of countries; track $index) {
						<option
							[selected]="country.name === selectedCountry.name"
							[value]="country.code"
						>
							{{ country.name }}
						</option>
					}
				</select>
			</div>
			<div class="mb-8">
				<fixify-text-input
					[control]="loginForm.get('phone')!"
					label="Phone number"
					id="phone"
					inputType="tel"
					placeholder="{{ selectedCountry.dial_code }}"
					errorText="Invalid phone number"
					[showRequiredStar]="true"
				></fixify-text-input>
			</div>
		</div>
		<div
			[ngClass]="{
				hidden: loginMode() === 'phone'
			}"
			class="mb-8"
		>
			<fixify-text-input
				[control]="loginForm.get('email')!"
				label="Email"
				id="email"
				inputType="email"
				placeholder="Enter your email address"
				errorText="Invalid email address"
				[showRequiredStar]="true"
			></fixify-text-input>
		</div>

		<div class="grid grid-rows-2 gap-2">
			<button
				[disabled]="!loginForm.valid || cooldownApplicable() || loading"
				[ngClass]="{
					disabled:
						!loginForm.valid || cooldownApplicable() || loading
				}"
				(click)="login()"
				class="btn-primary text-center w-full"
			>
				<app-loader
					[loadingState]="loading"
					class="flex justify-center"
				>
					Continue
					{{
						cooldownApplicable()
							? '(' + secondsRemaining() + 's)'
							: ''
					}}
				</app-loader>
			</button>
			<button
				[disabled]="loading"
				(click)="swapLoginModes()"
				class="btn-outline"
			>
				<img
					[src]="
						loginMode() === 'phone'
							? 'assets/svg/mail.svg'
							: 'assets/svg/phone.svg'
					"
					alt="img"
					class="h-6 w-6 mr-2"
				/>
				Continue with
				{{ loginMode() === 'phone' ? 'Email' : 'Phone' }}
			</button>
		</div>
	</form>
</div>
