import { Component, inject } from '@angular/core';
import { SidenavComponent } from '../../shared/ui/sidenav/sidenav.component';
import { NavbarComponent } from '../../shared/ui/navbar/navbar.component';
import { RouterOutlet } from '@angular/router';
import { ToastComponent } from '../../shared/ui/toast/toast.component';
import { ToastService } from '../../shared/data-access/toast.service';
import { CommonModule } from '@angular/common';
// import { ModalHostDirective } from '../../shared/directives/modal.host.directive';

@Component({
	selector: 'app-private',
	standalone: true,
	imports: [
		SidenavComponent,
		NavbarComponent,
		RouterOutlet,
		ToastComponent,
		CommonModule
	],
	templateUrl: './private.component.html',
	styleUrl: './private.component.css'
})
export class PrivateComponent {
	toastr = inject(ToastService);

	toasts = this.toastr.toasts();
}
