import { Component, input, model, output, signal } from '@angular/core';
import { TextInputComponent } from '../../text-input/text-input.component';
import {
	FormControl,
	FormGroup,
	ReactiveFormsModule,
	Validators
} from '@angular/forms';
import {
	GoogleMap,
	MapAdvancedMarker,
	MapCircle,
	MapGeocoder
} from '@angular/google-maps';
import { NgClass } from '@angular/common';
import { DEFAULT_LAT_LNG, Suggestion } from '../../../models/maps';
import { Location } from '../../../data-access/service-provider/service-provider.types';
import { SearchPlacesComponent } from '../../search-places/search-places.component';

@Component({
	selector: 'fixify-add-location-modal',
	standalone: true,
	imports: [
		TextInputComponent,
		ReactiveFormsModule,
		GoogleMap,
		MapCircle,
		MapAdvancedMarker,
		NgClass,
		SearchPlacesComponent
	],
	templateUrl: './add-location-modal.component.html',
	styleUrl: './add-location-modal.component.css'
})
export class AddLocationModalComponent {
	title = input<string>('Add location?');
	body = input<string>('Are you sure you want to delete this item?');
	isModalOpen = model<boolean>(false);
	onAdd = output<Location>();
	onEdit = output<Location>();
	editLocation = input<Location | null>(null);
	otherLocations = input<Location[]>([]);
	searchForm: FormGroup;
	ignoreNextSearchChange = signal<boolean>(false);
	clickedLocation: {
		lat: number;
		lng: number;
	} | null = null;
	radius = signal<number>(30000);
	center = signal<google.maps.LatLngLiteral>(DEFAULT_LAT_LNG);
	zoom = signal<number>(5);

	circleOptions: google.maps.CircleOptions = {
		fillColor: '#FFA757',
		fillOpacity: 0.4,
		strokeWeight: 0,
		clickable: false
	};

	options: google.maps.MapOptions = {
		streetViewControl: false,
		zoomControl: false,
		mapTypeControl: false,
		fullscreenControl: false,
		mapId: '4ae9361332aa6c90',
		restriction: {
			latLngBounds: {
				north: -20.1258,
				south: -36.8192,
				west: 14.5126,
				east: 35.8913
			},
			strictBounds: false
		}
	};

	searchResults = signal<Array<Suggestion>>([]);

	constructor(private geocoder: MapGeocoder) {
		this.searchForm = new FormGroup({
			search: new FormControl<string>('', Validators.required)
		});
	}

	ngOnInit() {
		if (this.editLocation()) {
			this.ignoreNextSearchChange.set(true);
			this.searchForm.controls['search'].setValue(
				this.editLocation()?.name
			);
			this.clickedLocation = this.editLocation()?.latlng ?? null;
			this.center.set(this.editLocation()?.latlng ?? DEFAULT_LAT_LNG);
			this.zoom.set(10);
		}
	}

	addLocation() {
		if (!this.clickedLocation) {
			return;
		}

		if (this.editLocation()) {
			this.onEdit.emit({
				name: this.searchForm.controls['search'].value,
				coordinates: [
					this.clickedLocation.lat,
					this.clickedLocation.lng
				],
				latlng: this.clickedLocation,
				id: this.editLocation()?.id ?? ''
			});
		} else {
			this.onAdd.emit({
				name: this.searchForm.controls['search'].value,
				coordinates: [
					this.clickedLocation.lat,
					this.clickedLocation.lng
				],
				latlng: this.clickedLocation,
				id: Math.random().toString()
			});
		}

		this.hideModal();
	}

	createContent() {
		const content = document.createElement('div');
		content.innerHTML = `<img src="assets/svg/map-pin.svg" class="translate-y-3" />`;
		return content;
	}

	generateContent(id: string) {
		const content = document.createElement('div');
		content.id = id;
		content.innerHTML = `<img src="assets/svg/map-pin.svg" class="translate-y-3" />`;
		return content;
	}

	emptySearchResults() {
		this.searchResults.set([]);
	}

	handleModalClicked(event: MouseEvent) {
		this.emptySearchResults();
		event.stopPropagation();
	}

	async selectedLocation(location: google.maps.LatLngLiteral | null) {
		if (!location) {
			this.clickedLocation = null;
			return;
		}
		this.clickedLocation = location;
		this.zoom.set(15);
		// required to wait for zoom to finish, otherwise the map will not center correctly due to it's set restrictions
		await new Promise(resolve => setTimeout(resolve, 1));
		this.center.set(location);
	}

	filteredLocations() {
		return this.otherLocations().filter(
			location =>
				!this.editLocation() || this.editLocation()?.id !== location.id
		);
	}

	hideModal() {
		this.isModalOpen.set(false);
	}

	mapClick(event: google.maps.MapMouseEvent) {
		if (!event.latLng) {
			return;
		}
		const previousClick = this.clickedLocation
			? { ...this.clickedLocation }
			: null;
		this.clickedLocation = event.latLng.toJSON();

		this.searchForm.controls['search'].disable();

		this.geocoder.geocode({ location: event.latLng.toJSON() }).subscribe({
			next: ({ results }) => {
				if (results.length === 0) {
					return;
				}
				const address = results[0].formatted_address;
				this.ignoreNextSearchChange.set(true);
				this.searchForm.controls['search'].setValue(address);
			},
			error: error => {
				console.error('Geocoding error:', error);
				this.clickedLocation = previousClick;
				// Handle the error here
			},
			complete: () => {
				this.searchForm.controls['search'].enable();
			}
		});
	}
}
