import { NgClass } from '@angular/common';
import { Component, input, model, output, signal } from '@angular/core';

@Component({
	selector: 'fixify-upload',
	standalone: true,
	imports: [NgClass],
	templateUrl: './upload.component.html',
	styleUrl: './upload.component.css'
})
export class UploadComponent {
	label = input<string>();
	url = input<string | null>(null);

	fileToUploadSrc = signal<string | null>(null);
	percentage = model<number>(0);
	uploadFile = output<File>();
	removeFile = output<void>();
	uploading = model<boolean>(false);
	removing = model<boolean>(false);
	largerLogo = input<boolean>(false);
	dragOver = signal<boolean>(false);

	edit() {
		this.triggerFileSelect();
	}

	onDragOver(event: DragEvent) {
		event.preventDefault();
		event.stopPropagation();
		this.dragOver.set(true);
	}

	onDragLeave(event: DragEvent) {
		event.preventDefault();
		event.stopPropagation();
		this.dragOver.set(false);
	}

	onDrop(event: DragEvent) {
		event.preventDefault();
		event.stopPropagation();
		this.dragOver.set(false);

		const files = event.dataTransfer?.files ?? [];
		if (files.length > 0) {
			const file = files[0];
			if (file.type.startsWith('image/')) {
				this.handleFile(file);
			} else {
				// Handle invalid file type
				console.error('Invalid file type. Please upload an image.');
			}
		}
	}

	onFileSelected(event: Event) {
		const input = event.target as HTMLInputElement;
		const file = input.files?.[0];
		this.percentage.set(0);

		if (file) {
			this.handleFile(file);
		}
	}

	private handleFile(file: File) {
		this.uploadFile.emit(file);

		this.uploading.set(true);
		const reader = new FileReader();
		reader.onload = () => {
			this.fileToUploadSrc.set(reader.result as string);

			// The below code is to simulate the file upload process
			// 	const timer = setInterval(() => {
			// 		if (this.percentage() < 100) {
			// 			this.percentage.set(this.percentage() + 10);
			// 		} else {
			// 			clearInterval(timer);
			// 			this.uploading.set(false);
			// 		}
			// 	}, 100);
			// end simulation
		};

		reader.readAsDataURL(file);
	}

	remove() {
		this.removeFile.emit();
		this.fileToUploadSrc.set(null);
	}

	source() {
		return this.fileToUploadSrc() ?? this.url();
	}

	triggerFileSelect() {
		const input = document.getElementById('file-input');
		if (input) {
			input.click();
		}
	}
}
