import { inject } from '@angular/core';
import {
	HttpRequest,
	HttpEvent,
	HttpInterceptorFn,
	HttpHandlerFn
} from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { switchMap, first } from 'rxjs/operators';
import { AuthService } from './auth.service';

export const authInterceptor: HttpInterceptorFn = (
	req: HttpRequest<unknown>,
	next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
	const authService = inject(AuthService);

	return authService.getAuthState().pipe(
		first(),
		switchMap(user => {
			if (user) {
				return from(user.getIdToken());
			}
			return Promise.resolve(null);
		}),
		switchMap(token => {
			if (token) {
				const clonedRequest = req.clone({
					setHeaders: { authorization: `${token}` }
				});
				return next(clonedRequest);
			}
			return next(req);
		})
	);
};
