@if (label().length > 0) {
	<label class="input-label" [for]="id()"
		>{{ label() }}
		@if (showRequiredStar()) {
			<span class="text-red-700">*</span>
		}
	</label>
}
<select [formControl]="ngControl" class="form-input">
	<option value="" [disabled]="!defaultSelectable()" selected>
		{{ defaultText() }}
	</option>
	@for (item of items(); track $index) {
		<option
			[selected]="item === currentlySelectedItem()"
			[value]="getItemValue(item)"
			[disabled]="disabled()"
		>
			{{ getItemName(item) }}
		</option>
	}
</select>

@if (control.invalid && control.dirty && showErrorMessage()) {
	<p class="input-error-text">{{ errorText() }}</p>
}

