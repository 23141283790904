import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

export type ToastType = 'success' | 'error' | 'info';

interface IToast {
	message: string;
	id: string;
	dismissed: Subject<boolean>;
	type: ToastType;
}

@Injectable({
	providedIn: 'root'
})
export class ToastService {
	private _toasts = new BehaviorSubject<IToast[]>([]);

	toasts() {
		return this._toasts.asObservable();
	}

	remove(toastId: string) {
		const currentToasts = this._toasts.getValue();
		const toastIndex = currentToasts.findIndex(
			toast => toast.id === toastId
		);

		if (toastIndex !== -1) {
			currentToasts.splice(toastIndex, 1);
			this._toasts.next(currentToasts);
		}
	}

	add(
		message: string,
		duration = 3000,
		type: ToastType = 'info'
	): Promise<void> {
		const newToast: IToast = {
			message,
			id: this.generateUniqueId(),
			dismissed: new Subject(),
			type
		};
		const currentToasts = this._toasts.getValue();
		this._toasts.next([...currentToasts, newToast]);

		return new Promise(resolve => {
			setTimeout(() => {
				this.remove(newToast.id);
				resolve();
			}, duration);

			newToast.dismissed.subscribe(() => {
				this.remove(newToast.id);
				resolve();
			});
		});
	}

	dismiss(id: string) {
		const currentToasts = this._toasts.getValue();
		const index = currentToasts.findIndex(toast => toast.id === id);
		if (index > -1) {
			currentToasts[index].dismissed.next(true);
		}
	}

	private generateUniqueId(): string {
		return (
			Math.random().toString(36).substring(2, 15) +
			Math.random().toString(36).substring(2, 15)
		);
	}
}
