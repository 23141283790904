import { Component, input, model, output } from '@angular/core';
import {
	AbstractControl,
	FormControl,
	ReactiveFormsModule
} from '@angular/forms';
import { Suggestion, SuggestionsResponse } from '../../models/maps';
import { MapGeocoder } from '@angular/google-maps';
import { NgClass } from '@angular/common';
import {
	Observable,
	Subject,
	debounceTime,
	distinctUntilChanged,
	exhaustMap,
	takeUntil
} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Address } from '../../../creator-portal/service/creator-portal.service';
import { mapAddress } from '../../utils/maps';
import { environment } from '../../../../environments/environment';

@Component({
	selector: 'fixify-search-places',
	standalone: true,
	imports: [ReactiveFormsModule, NgClass],
	templateUrl: './search-places.component.html',
	styleUrl: './search-places.component.css'
})
export class SearchPlacesComponent {
	private searchSubject = new Subject<{
		input: string;
	}>();
	control = input.required<AbstractControl>();
	ngControl!: FormControl;
	searchResults = model<Array<Suggestion>>([]);
	ignoreNextSearchChange = model<boolean>(false);
	showRequiredStar = input<boolean>(false);
	label = input<string>('');
	disabled = input<boolean>(false);
	placeholder = input<string>('Search...');
	selectedLocation = output<google.maps.LatLngLiteral | null>();
	address = output<Address | null>();
	searchingForAddresses = output<boolean>();
	destroy$ = new Subject<void>();

	constructor(
		private geocoder: MapGeocoder,
		private http: HttpClient
	) {}

	ngOnInit() {
		this.ngControl = this.control() as FormControl;

		this.initSearchStream()
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: result => {
					this.searchResults.set(result.suggestions ?? []);
					this.searchingForAddresses.emit(false);
				},
				error: error => {
					console.error(error);
				}
			});

		this.ngControl.valueChanges
			.pipe(
				debounceTime(500),
				takeUntil(this.destroy$),
				distinctUntilChanged()
			)
			.subscribe(val => {
				if (this.ignoreNextSearchChange() || !val) {
					this.ignoreNextSearchChange.set(false);
					return;
				}
				this.searchingForAddresses.emit(true);
				this.selectedLocation.emit(null);
				this.address.emit(null);

				this.search(val);
			});
	}

	search(input: string): void {
		this.searchSubject.next({ input });
	}

	initSearchStream(): Observable<SuggestionsResponse> {
		return this.searchSubject
			.asObservable()
			.pipe(exhaustMap(({ input }) => this.searchPlaces(input)));
	}

	selectedAddress(suggestion: Suggestion) {
		this.ignoreNextSearchChange.set(true);
		this.searchResults.set([]);
		this.ngControl.setValue(suggestion.placePrediction.text.text);

		this.geocoder
			.geocode({ placeId: suggestion.placePrediction.placeId })
			.subscribe({
				next: async ({ results }) => {
					if (results.length === 0) {
						return;
					}
					const { location } = results[0].geometry;
					this.selectedLocation.emit(location.toJSON());
					const address = mapAddress(results[0]);
					this.address.emit(address);
				},
				error: error => {
					console.error('Geocoding error:', error);
					// Handle the error here
				}
			});
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}

	private searchPlaces(input: string): Observable<SuggestionsResponse> {
		const body = {
			input,
			includedRegionCodes: ['za']
		};
		const headers = {
			'Content-Type': 'application/json',
			'X-Goog-Api-Key': environment.mapApiKey ?? ''
		};
		return this.http.post<SuggestionsResponse>(
			'https://places.googleapis.com/v1/places:autocomplete',
			body,
			{ headers }
		);
	}
}
