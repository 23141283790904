import { CommonModule } from '@angular/common';
import { Component, signal } from '@angular/core';
import { MenuItem, menuItems } from './utils/menu-items';
import {
	Router,
	RouterLink,
	RouterLinkActive,
	Event,
	NavigationStart,
	NavigationEnd,
	NavigationError
} from '@angular/router';
import { NavService } from '../../data-access/nav.service';

@Component({
	selector: 'app-sidenav',
	standalone: true,
	imports: [CommonModule, RouterLink, RouterLinkActive],
	templateUrl: './sidenav.component.html',
	styleUrl: './sidenav.component.css'
})
export class SidenavComponent {
	menuItems: MenuItem[] = [];
	userType = signal<'service-providers' | 'agency'>('service-providers');

	constructor(
		private router: Router,
		private navService: NavService
	) {}

	ngOnInit() {
		const urlPart = this.router.url.split('/')[1];
		if (urlPart === 'agency') {
			this.userType.set('agency');
		}

		this.menuItems = menuItems[this.userType()];

		this.handleNavigation(this.router.url);

		// Subscribe to future navigation events
		this.router.events.subscribe((event: Event) => {
			if (event instanceof NavigationStart) {
				this.handleNavigation(event.url);
			}

			if (event instanceof NavigationEnd) {
				// Hide loading indicator
			}

			if (event instanceof NavigationError) {
				console.log(event.error);
			}
		});
	}

	topItems() {
		return this.menuItems.filter(item => !item.bottom);
	}

	bottomItems() {
		return this.menuItems.filter(item => item.bottom);
	}

	// TODO [Frontend]: Only applicable for user toggled expansion for user (future update)
	expanded() {
		return this.navService.expanded;
	}

	private handleNavigation(url: string) {
		this.menuItems.forEach(item => {
			item.active = false;
			if (url.startsWith(item.route)) {
				item.active = true;
			}
		});
	}
}
