import { Directive, ViewContainerRef } from '@angular/core';
import { ModalService } from '../ui/modals/modal.service';

@Directive({
	selector: '[modalHost]',
	standalone: true
})
export class ModalHostDirective {
	constructor(
		viewContainerRef: ViewContainerRef,
		modalService: ModalService
	) {
		modalService.setViewContainerRef(viewContainerRef);
	}
}
