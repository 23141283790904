import { Component } from '@angular/core';

@Component({
	selector: 'app-complex-estates',
	standalone: true,
	imports: [],
	templateUrl: './complex-estates.component.html',
	styleUrl: './complex-estates.component.css'
})
export class ComplexEstatesComponent {}
