import { Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AgencyService } from '../../shared/data-access/agency/agency.service';
import { map } from 'rxjs';
import { AsyncPipe } from '@angular/common';

@Component({
	selector: 'app-agency-account',
	standalone: true,
	imports: [RouterLink, AsyncPipe],
	templateUrl: './agency-account.component.html',
	styleUrl: './agency-account.component.css'
})
export class AgencyAccountComponent {
	agentService = inject(AgencyService);
	agentEmail = this.agentService
		.agent()
		.pipe(map(agent => agent?.email ?? '--'));
	accountCompletion = this.agentService.accountCompletion().pipe(
		map(acc => {
			return {
				agencyDetails: acc.agencyDetails ?? false,
				personalInfo: acc.profile ?? false
			};
		})
	);
}
