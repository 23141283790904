import { Component } from '@angular/core';

@Component({
	selector: 'fixify-dashboard-card',
	standalone: true,
	imports: [],
	templateUrl: './dashboard-card.component.html',
	styleUrl: './dashboard-card.component.css'
})
export class DashboardCardComponent {}
