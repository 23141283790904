<div class="my-4">
	<div class="flex py-3 px-4">
		<div class="mr-2">
			<img src="assets/svg/file-gray.svg" alt="" />
		</div>
		<div class="w-full">
			<div class="flex gap-4">
				<div class="flex">
					<div class="flex flex-col gap-1">
						<p class="text-sm font-normal text-gray-900">
							{{ quoteItem().name }}
						</p>
						<p class="text-xs font-normal text-gray-500">
							{{ quoteItem().createdAt }}
						</p>
					</div>
				</div>
				<button
					(click)="download()"
					class="h-min btn btn-outline small border-gray-200"
				>
					Download
				</button>
				<div class="ml-auto self-center">
					<fixify-status-pill
						[color]="
							quoteItem().status === 'approved' ? 'green' : 'red'
						"
						>{{
							quoteItem().status | titlecase
						}}</fixify-status-pill
					>
				</div>
			</div>
			@if (quoteItem().status === 'rejected') {
				<div class="mt-4">
					<p class="text-sm font-normal text-gray-500 mb-1">
						Rejection reason
					</p>
					<p class="text-sm font-normal text-gray-900">
						{{ quoteItem().reason ?? 'N/A' }}
					</p>
				</div>
			}
		</div>
	</div>
</div>

