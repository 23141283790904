<div class="flex mt-16 w-full justify-center">
	<div class="w-[502px]">
		<div class="flex flex-col items-center mx-8 text-center mb-16">
			<img class="m-4" src="assets/svg/ghost.svg" />
			<p class="leading-tight text-5xl font-medium text-gray-900 mb-2">
				Under construction
			</p>
			<p class="text-lg font-normal text-gray-700">
				Jobs are coming soon, watch this space!
			</p>
		</div>
	</div>
</div>
