import { Component, signal } from '@angular/core';
import { StatusPillComponent } from '../../../shared/ui/status-pill/status-pill.component';
import { NgClass } from '@angular/common';
import { DividerComponent } from '../../../shared/ui/divider/divider.component';
import { StatusBannerComponent } from '../../../shared/ui/status-banner/status-banner.component';
import { PropertyInfoCardComponent } from '../../../shared/ui/property-info-card/property-info-card.component';
import { JobDetailComponent } from '../../../shared/ui/job-detail/job-detail.component';
import { BadgeComponent } from '../../../shared/ui/badge/badge.component';
import {
	SimpleNavBarComponent,
	SimpleNavInput
} from '../../../shared/ui/simple-nav-bar/simple-nav-bar.component';
import {
	QuoteItem,
	QuoteListItemComponent
} from '../../../shared/ui/quote-list-item/quote-list-item.component';

@Component({
	selector: 'fixify-creator-job-details',
	standalone: true,
	imports: [
		StatusPillComponent,
		NgClass,
		DividerComponent,
		StatusBannerComponent,
		PropertyInfoCardComponent,
		JobDetailComponent,
		BadgeComponent,
		SimpleNavBarComponent,
		QuoteListItemComponent
	],
	templateUrl: './job-details.component.html',
	styleUrl: './job-details.component.css'
})
export class JobDetailsComponent {
	canGoPrevious = signal<boolean>(false);
	canGoNext = signal<boolean>(true);
	imgUrls = signal<string[]>([
		'https://images.pexels.com/photos/45201/kitty-cat-kitten-pet-45201.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500'
	]);
	simpleNavItems = signal<Array<SimpleNavInput>>([
		{ name: 'Quotes', selected: true, badgeValue: 2 },
		{ name: 'Photos', selected: false },
		{ name: 'Invoices', selected: false }
	]);

	quoteItems = signal<Array<QuoteItem>>([
		{
			name: 'JBN12345_Quote_2',
			url: '',
			createdAt: '22 May 2024 · 10:56',
			status: 'approved'
		},
		{
			name: 'JBN12345_Quote_1',
			url: '',
			createdAt: '21 May 2024 · 15:01',
			status: 'rejected',
			reason: 'Too expensive. Create another for comparison'
		}
	]);

	navItemChanged(item: SimpleNavInput) {
		this.simpleNavItems.update(items => {
			items.forEach(i => (i.selected = i.name === item.name));
			return items;
		});
	}

	primaryStatusClicked() {
		console.log('Primary status clicked');
	}

	secondaryStatusClicked() {
		console.log('Secondary status clicked');
	}
}
