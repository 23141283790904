import { Component, input } from '@angular/core';

@Component({
	selector: 'fixify-job-detail',
	standalone: true,
	imports: [],
	templateUrl: './job-detail.component.html',
	styleUrl: './job-detail.component.css'
})
export class JobDetailComponent {
	iconSrc = input.required<string>();
	title = input.required<string>();
}
