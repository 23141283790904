import { Component, input, model, output } from '@angular/core';
import { AvatarComponent } from '../../../../../shared/ui/avatar/avatar.component';
import { IconComponent } from '../../../../../shared/ui/icon/icon.component';
import { PropertyInfoCardComponent } from '../../../../../shared/ui/property-info-card/property-info-card.component';
import { RequestCardComponent } from '../../../../../creator-portal/ui/request-card/request-card.component';
import { ITestAgentInterface } from '../../tables/agents-table/agents-table.component';
import { DatePipe } from '@angular/common';

@Component({
	selector: 'fixify-view-agent-modal',
	standalone: true,
	imports: [
		AvatarComponent,
		IconComponent,
		PropertyInfoCardComponent,
		RequestCardComponent,
		DatePipe
	],
	templateUrl: './view-agent-modal.component.html',
	styleUrl: './view-agent-modal.component.css'
})
export class ViewAgentModalComponent {
	deleteButtonText = input<string>('Delete');
	isModalOpen = model<boolean>(false);
	agent = input<ITestAgentInterface | null>(null);
	editAgent = output<void>();
	deleteAgent = output<void>();

	deleteClicked() {
		this.deleteAgent.emit();
		this.hideModal();
	}

	editClicked() {
		this.editAgent.emit();
		this.hideModal();
	}

	hideModal() {
		this.isModalOpen.set(false);
	}
}
