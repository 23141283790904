<!-- table.component.html -->
<div class="w-full h-full overflow-y-auto overflow-x-auto rounded-lg">
	<table class="w-full text-sm text-left rtl:text-right text-gray-900">
		<thead
			class="text-xs text-gray-400 uppercase bg-gray-50 sticky top-0 z-10"
		>
			<tr>
				@for (column of columns; track $index) {
					<th
						scope="col"
						class="px-4 py-5 uppercase text-xs font-semibold"
					>
						{{ column.header() }}
					</th>
				}
			</tr>
		</thead>
		<tbody>
			@for (row of paginatedData; track $index) {
				<tr
					(click)="onRowClicked(row)"
					[ngClass]="{ '!bg-gray-50': isDisabled(row) }"
					class="bg-white border-b hover:bg-gray-100"
				>
					@for (column of columns; track $index) {
						<td class="px-4 py-5 text-gray-900">
							<ng-container
								[ngTemplateOutlet]="column.template"
								[ngTemplateOutletContext]="{
									$implicit: row[column.field()],
									row: row
								}"
							>
							</ng-container>
						</td>
					}
				</tr>
			}
		</tbody>
	</table>
</div>

