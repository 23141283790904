<fixify-modal size="md">
	<div class="flex justify-center mb-6">
		<img src="assets/svg/social.svg" />
	</div>

	<div class="flex items-start justify-between rounded-t">
		<p class="text-xl font-semibold text-gray-900">Terms and Conditions</p>
	</div>
	<div class="pt-1 pb-6">
		<p class="text-base leading-relaxed text-gray-500">
			Please note that in order to move forward on the platform, the terms
			and conditions need to be accepted.
		</p>
	</div>
	<div class="flex items-center justify-center space-x-2 rounded-b pt-1 pb-6">
		<a class="cursor-pointer" href="{{ termsLink }}" target="_blank">
			<span class="text-base text-blue-500"> Terms & Conditions </span>
		</a>
	</div>
	<div
		class="flex items-center justify-end space-x-2 border-gray-200 rounded-b"
	>
		<button
			(click)="signOutClicked()"
			type="button"
			class="btn-outline border-gray-200"
		>
			Sign Out
		</button>

		<button (click)="acceptClicked()" type="button" class="btn-primary">
			Accept Terms & Conditions
		</button>
	</div>
</fixify-modal>

