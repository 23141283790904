import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, computed, input, model } from '@angular/core';

export type AlertType = 'info' | 'success' | 'warning' | 'danger' | 'grey';
@Component({
	selector: 'app-alert',
	standalone: true,
	imports: [NgClass, NgTemplateOutlet],
	templateUrl: './alert.component.html',
	styleUrl: './alert.component.css'
})
export class AlertComponent {
	type = input<AlertType>('info');
	class = input<string>('');
	showAlert = model<boolean>(false);
	typeMappingToClasses = computed<string>(() => {
		switch (this.type()) {
			case 'info':
				return 'text-blue-800 bg-blue-100';
			case 'success':
				return 'text-green-800 bg-green-100';
			case 'warning':
				return 'text-yellow-800 bg-yellow-100';
			case 'danger':
				return 'text-red-800 bg-red-100';
			case 'grey':
				return 'text-gray-800 bg-gray-100';
			default:
				return 'text-blue-800 bg-blue-100';
		}
	});

	iconSource = computed<string>(() => {
		switch (this.type()) {
			case 'grey':
				return 'assets/svg/info-circle.svg';
			default:
				return 'assets/svg/alert-check-' + this.type() + '.svg';
		}
	});
}
