import { Component, inject } from '@angular/core';
import { DashboardCardComponent } from '../../shared/ui/dashboard-card/dashboard-card.component';
import { IconComponent } from '../../shared/ui/icon/icon.component';
import { TextInputComponent } from '../../shared/ui/text-input/text-input.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TableComponent } from '../../shared/ui/table/table.component';
import { TableColumnDirective } from '../../shared/ui/table/directives/table-column.directive';
import { DatePipe } from '@angular/common';
import { PaginationComponent } from '../../shared/ui/table/pagination/pagination.component';
import { AgentsTableComponent } from './ui/tables/agents-table/agents-table.component';
import { ToastService } from '../../shared/data-access/toast.service';
import { ModalService } from '../../shared/ui/modals/modal.service';
import { InviteAgentsModalComponent } from './ui/modals/invite-agents-modal/invite-agents-modal.component';

@Component({
	selector: 'app-agents',
	standalone: true,
	imports: [
		DashboardCardComponent,
		IconComponent,
		TextInputComponent,
		ReactiveFormsModule,
		TableComponent,
		TableColumnDirective,
		DatePipe,
		PaginationComponent,
		AgentsTableComponent
	],
	templateUrl: './agents.component.html',
	styleUrl: './agents.component.css'
})
export class AgentsComponent {
	toastService = inject(ToastService);
	modalService = inject(ModalService);

	openInviteAgentsModal() {
		this.modalService.showModal<null, void>(InviteAgentsModalComponent);
	}
}
