<!-- MODALS -->
<!-- Remove and re-add from DOM to re-render Google Map to prevent state from being kept -->
@if (isAddLocationModalOpen()) {
	<fixify-add-location-modal
		[editLocation]="selectedLocation()"
		(onAdd)="addLocation($event)"
		(onEdit)="editLocation($event)"
		[otherLocations]="locations"
		[(isModalOpen)]="isAddLocationModalOpen"
	></fixify-add-location-modal>
}

<fixify-view-linked-agency-modal
	[(isModalOpen)]="isViewLinkedAgencyModalOpen"
	[linkedAgency]="selectedAgency()!"
	(onDelink)="openDelinkAgencyModal()"
></fixify-view-linked-agency-modal>

<fixify-delete-modal
	(onDelete)="deleteSelectedLocation()"
	[(isModalOpen)]="isDeleteModalOpen"
	title="Delete location?"
	[body]="selectedLocation()?.name ?? ''"
></fixify-delete-modal>

<fixify-delete-modal
	(onDelete)="delinkAgency()"
	[(isModalOpen)]="isDelinkAgencyModalOpen"
	[title]="'Delink from ' + selectedAgency()?.name + '?'"
	body="This action is irreversible. Are you sure you want to delink this agency?"
	deleteButtonText="Delink"
></fixify-delete-modal>

<!-- END MODALS -->

<div class="container mx-auto">
	<div class="flex justify-between mb-4">
		<p class="text-2xl text-gray-900 font-semibold">My locations</p>
		@if (locations.length > 0) {
			<div class="flex gap-2">
				<button (click)="openAddLocationModal()" class="btn-primary">
					Add location
				</button>
			</div>
		}
	</div>

	@if (locations.length === 0) {
		<fixify-no-locations
			(addLocation)="openAddLocationModal()"
		></fixify-no-locations>
		<div class="mt-6">
			<fixify-divider></fixify-divider>
		</div>
	} @else {
		<div class="pb-4"></div>
		<div
			class="flex max-h-[372px] overflow-hidden rounded border-2 border-gray-100"
		>
			<div class="w-7/12">
				<google-map
					[center]="center()"
					[zoom]="zoom()"
					[options]="options"
					width="100%"
					height="372px"
				>
					@for (location of locations; track $index) {
						<map-circle
							[center]="location.latlng"
							[radius]="radius()"
							[options]="circleOptions"
							[id]="location.id"
						/>
						<map-advanced-marker
							[position]="location.latlng"
							[content]="generateContent(location.id)"
						></map-advanced-marker>
					}
				</google-map>
			</div>
			<div class="w-5/12 overflow-scroll">
				@for (location of locations; track $index) {
					<div
						(click)="goToLocation(location.coordinates)"
						class="py-5 px-6 flex items-center cursor-pointer"
					>
						<img
							class="mr-3"
							src="assets/svg/marker-pin-gray.svg"
						/>
						<p
							class="mr-3 w-full text-sm font-normal text-gray-900"
						>
							{{ location.name }}
						</p>
						<div class="flex gap-2">
							<img
								(click)="openDeleteModal(location, $event)"
								class="h-6 w-6 cursor-pointer"
								src="assets/svg/delete.svg"
							/>
							<img
								(click)="openModalAsEdit(location, $event)"
								class="h-6 w-6 cursor-pointer"
								src="assets/svg/edit.svg"
							/>
						</div>
					</div>
					<fixify-divider class="!bg-gray-100"></fixify-divider>
				}
			</div>
		</div>
	}

	<p class="text-2xl text-gray-900 font-semibold mb-8 mt-6">
		Linked agencies
	</p>
	@if (linkedAgencies.length === 0) {
		<div class="flex flex-col w-full items-center mt-8 pb-4">
			<img src="assets/svg/no-agencies.svg" />
			<p class="text-base font-semibold text-gray-700 mb-1">
				No linked agencies
			</p>
			<p class="text-sm font-normal text-gray-500 mb-1">
				Not directly linked to any agencies.
			</p>
		</div>
	} @else {
		<div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
			@for (agency of linkedAgencies; track $index) {
				<fixify-agency-tile
					(click)="openViewLinkedAgencyModal(agency)"
					[linkedAgency]="agency"
				></fixify-agency-tile>
			}
		</div>
	}
</div>
