@if (label().length > 0) {
	<label class="input-label" for="search"
		>{{ label() }}
		@if (showRequiredStar()) {
			<span class="text-red-700">*</span>
		}
	</label>
}
<div class="relative">
	<div
		class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none"
	>
		<svg
			class="w-4 h-4 text-gray-500 dark:text-gray-400"
			aria-hidden="true"
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 20 20"
		>
			<path
				stroke="currentColor"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="2"
				d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
			/>
		</svg>
	</div>
	<input
		autocomplete="off"
		[formControl]="ngControl"
		[disabled]="disabled()"
		[ngClass]="{
			'input-error': control().invalid && control().dirty
		}"
		type="text"
		id="search"
		class="block w-full ps-10 form-input focus:outline-none focus:ring-1 focus:ring-primary-100 focus:border-transparent"
		[placeholder]="placeholder()"
	/>
	<div
		[ngClass]="{
			hidden: !ngControl.value || searchResults().length === 0
		}"
		class="absolute z-50 bg-white divide-y divide-gray-100 rounded-lg shadow w-full"
	>
		<ul class="text-sm text-gray-700" aria-labelledby="dropdownInput">
			@for (suggestion of searchResults(); track $index) {
				<li
					(click)="selectedAddress(suggestion)"
					class="cursor-pointer"
				>
					<p class="block px-4 py-2 hover:bg-gray-100">
						{{ suggestion.placePrediction.text.text }}
					</p>
				</li>
			}
		</ul>
	</div>
</div>

