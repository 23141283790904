import { Directive, TemplateRef, input } from '@angular/core';

@Directive({
	selector: '[fixifyTableColumn]',
	standalone: true
})
export class TableColumnDirective<T> {
	header = input.required<string>();
	field = input.required<keyof T>();
	constructor(public template: TemplateRef<unknown>) {}
}
