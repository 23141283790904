import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, tap } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Injectable({
	providedIn: 'root'
})
export class ServiceProviderLoggedInGuard implements CanActivate {
	constructor(
		private router: Router,
		private authService: AuthService
	) {}

	canActivate(): Observable<boolean> {
		return this.authService.checkClaim('serviceProvider').pipe(
			tap(isServiceProvider => {
				if (!isServiceProvider) {
					this.router.navigate(['/login/service-provider']);
				}
			})
		);
	}
}
