import { Component, signal } from '@angular/core';
import { LocalStorageService } from '../../shared/data-access/local-storage.service';
import { LoginComponent } from './ui/login/login.component';
import { OtpComponent } from './ui/otp/otp.component';
import { NgClass } from '@angular/common';
import { Router } from '@angular/router';

const MIN_RETRY_INTERVAL = 30;

@Component({
	selector: 'app-sign-in',
	standalone: true,
	imports: [LoginComponent, OtpComponent, NgClass],
	templateUrl: './sign-in.component.html',
	styleUrl: './sign-in.component.css'
})
export class SignInComponent {
	protected loginMode: 'phone' | 'mail' = 'phone';
	protected emailOrPhoneNumber = '';
	protected otpScreen = false;
	protected userType = signal<'service-providers' | 'agency'>(
		'service-providers'
	);
	secondsRemaining = signal<number>(0);

	constructor(
		private localStorage: LocalStorageService,
		private router: Router
	) {}

	ngOnInit(): void {
		const otpCooldown = this.localStorage.getItem<number | null>(
			'otpTimeRemaining'
		);

		if (!otpCooldown) {
			return;
		}

		this.startCooldown(otpCooldown);

		const urlPart = this.router.url.split('/')[2];
		if (urlPart === 'agency') {
			this.userType.set('agency');
		}
	}

	backToLogin() {
		this.otpScreen = false;
	}

	startCooldown(seconds: number = MIN_RETRY_INTERVAL) {
		this.localStorage.setItem('otpTimeRemaining', seconds);
		this.secondsRemaining.set(seconds);
		const interval = setInterval(() => {
			if (this.secondsRemaining() === 0) {
				clearInterval(interval);
				return;
			}

			this.secondsRemaining.update(val => {
				this.localStorage.setItem('otpTimeRemaining', val - 1);
				return val - 1;
			});
		}, 1000);
	}
}
