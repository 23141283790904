import { Component, input } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
	selector: 'fixify-property-avatar',
	standalone: true,
	imports: [IconComponent],
	templateUrl: './property-avatar.component.html',
	styleUrl: './property-avatar.component.css'
})
export class PropertyAvatarComponent {
	url = input('');
}
