import { Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ServiceProviderService } from '../../shared/data-access/service-provider/service-provider.service';
import { map } from 'rxjs';
import { AsyncPipe } from '@angular/common';

@Component({
	selector: 'app-account',
	standalone: true,
	imports: [RouterLink, AsyncPipe],
	templateUrl: './account.component.html',
	styleUrl: './account.component.css'
})
export class AccountComponent {
	spService = inject(ServiceProviderService);
	spEmail = this.spService
		.serviceProvider()
		.pipe(map(sp => sp?.email ?? '--'));
	accountCompletion = this.spService.accountCompletion().pipe(
		map(acc => {
			return {
				companyDetails: acc.companyDetails ?? false,
				categories: acc.categories ?? false,
				personalInfo: acc.profile ?? false
			};
		})
	);
}
