import { Component } from '@angular/core';
import { TextInputComponent } from '../../shared/ui/text-input/text-input.component';
import {
	FormControl,
	FormGroup,
	ReactiveFormsModule,
	Validators
} from '@angular/forms';
import { ServiceProviderService } from '../../shared/data-access/service-provider/service-provider.service';

@Component({
	selector: 'app-support',
	standalone: true,
	imports: [TextInputComponent, ReactiveFormsModule],
	templateUrl: './support.component.html',
	styleUrl: './support.component.css'
})
export class SupportComponent {
	supportForm: FormGroup;

	constructor(private ServiceProviderService: ServiceProviderService) {
		this.supportForm = new FormGroup({
			message: new FormControl('', Validators.required)
		});
	}

	onSubmit() {
		if (this.supportForm.valid) {
			this.ServiceProviderService.requestSupport(
				this.supportForm.value.message
			).subscribe({
				next: () => {
					this.supportForm.reset();
				},
				error: () => {
					console.log('Error');
				}
			});
		}
	}
}
