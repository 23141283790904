<div
	class="flex rounded border-2 border-gray-100 px-4 py-5 h-full cursor-pointer"
>
	<img
		class="w-20 h-12 bg-primary-50 mr-4 rounded"
		src="assets/svg/no-locations.svg"
	/>
	<div class="flex flex-col justify-start">
		<p class="text-base font-medium text-gray-900">
			{{ linkedAgency().name }}
		</p>
		<p class="text-sm font-normal text-gray-500">View info</p>
	</div>
</div>

