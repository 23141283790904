<div class="max-w px-12 md:px-22 xl:px-44">
	<div class="justify-start">
		<h1 class="w-full font-medium text-2xl leading-tight pb-2">
			Help and support
		</h1>
		<p class="text-base font-normal text-gray-700">
			Welcome to Fixify! We’re happy to have you here. If you ever run
			into any issues, please don’t hesitate to get in touch with us.
		</p>
		<div class="h-4"></div>
		<div
			class="grid gap-x-6 lg:gap-x-12 xl:gap-x-20 gap-y-6 grid-cols-1 md:grid-cols-2 gap-6 mt-6"
		>
			<form [formGroup]="supportForm">
				<fixify-text-input
					[control]="supportForm.get('message')!"
					errorText="Please enter a message"
					inputType="textarea"
					placeholder="Enter your message"
					label="Message"
					[showRequiredStar]="true"
				></fixify-text-input>

				<button
					[disabled]="supportForm.invalid"
					class="btn-primary mt-6"
					(click)="onSubmit()"
				>
					Submit
				</button>
			</form>
			<div class="row-start-1 col-start-1 md:col-start-2">
				<div
					class="p-6 bg-white rounded-lg border border-gray-200 grid gap-y-1 cursor-pointer"
				>
					<div class="flex-shrink-0 mb-2">
						<img src="assets/svg/support-chat.svg" />
					</div>

					<p class="text-gray-500">
						Need assistance or have feedback? Submit this form, and
						we'll respond as soon as possible.
					</p>
				</div>
			</div>
		</div>
	</div>
</div>
