export const DEFAULT_LAT_LNG = { lat: -26.2041, lng: 28.0473 };

export interface Match {
	endOffset: number;
}

export interface TextWithMatches {
	text: string;
	matches?: Match[];
}

export interface StructuredFormat {
	mainText: TextWithMatches;
	secondaryText: TextWithMatches;
}

export interface PlacePrediction {
	place: string;
	placeId: string;
	text: TextWithMatches;
	structuredFormat: StructuredFormat;
	types: string[];
}

export interface Suggestion {
	placePrediction: PlacePrediction;
}

export interface SuggestionsResponse {
	suggestions: Suggestion[];
}
