<ng-template #alertTemplate>
	<div [class]="class()">
		<div
			class="items-center justify-start w-full inline-flex p-4 text-sm rounded-lg {{
				typeMappingToClasses()
			}}"
			role="alert"
		>
			<!-- TODO: Create dynamic images too -->
			<span class="mr-3">
				<img [src]="iconSource()" />
			</span>
			<ng-content></ng-content>
		</div>
	</div>
</ng-template>

@if (showAlert()) {
	<ng-container [ngTemplateOutlet]="alertTemplate"></ng-container>
}
