import { Component, input } from '@angular/core';

@Component({
	selector: 'fixify-validation-message',
	standalone: true,
	imports: [],
	templateUrl: './validation-message.component.html',
	styleUrl: './validation-message.component.css'
})
export class ValidationMessageComponent {
	text = input<string>('Error');
}
