<div class="w-full">
	<app-alert class="pb-4" [showAlert]="error != ''" type="danger">
		{{ error }}
	</app-alert>
	<p class="leading-tight text-3xl font-medium pb-1">Welcome to Fixify</p>
	<p class="leading-tight text-base font-normal text-gray-600 mb-8">
		Enter your email address to continue.
	</p>

	<form [formGroup]="emailForm" class="max-w">
		<div class="mb-8">
			<fixify-text-input
				[control]="emailForm.get('email')!"
				label="Email"
				id="email"
				inputType="email"
				placeholder="Enter your email address"
				errorText="Invalid email address"
				[showRequiredStar]="true"
			></fixify-text-input>
		</div>
	</form>

	<button
		(click)="continue()"
		[ngClass]="{
			disabled: !emailForm.valid || loading
		}"
		[disabled]="!emailForm.valid"
		class="btn-primary w-full"
	>
		<app-loader class="flex justify-center" [loadingState]="loading">
			Continue
		</app-loader>
	</button>
</div>
