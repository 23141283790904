import { Routes } from '@angular/router';
import { PrivateComponent } from './layout/private/private.component';
import { JobsComponent } from './service-provider/jobs/jobs.component';
import { AccountComponent } from './service-provider/account/account.component';
import { SettingsComponent } from './service-provider/settings/settings.component';
import { SupportComponent } from './service-provider/support/support.component';
import { LocationsComponent } from './service-provider/locations/locations.component';
import { SignInComponent } from './authentication/sign-in/sign-in.component';
import { AuthenticationComponent } from './authentication/authentication.component';
import { VerifyEmailComponent } from './authentication/verify-email/verify-email.component';
import { CompanyInfoComponent } from './service-provider/account/company-info/company-info.component';
import { PersonalInfoComponent } from './service-provider/account/personal-info/personal-info.component';
import { CategoriesComponent } from './service-provider/account/categories/categories.component';
import { ServiceProviderLoggedInGuard } from './shared/guards/sp-logged-in.guard';
import { AuthResolver } from './shared/auth/auth.resolver';
import { CreatorPortalLayoutComponent } from './layout/creator-portal/creator-portal.component';
import { CreatorPortalComponent } from './creator-portal/creator-portal.component';
import { AgencyJobsComponent } from './agency/agency-jobs/agency-jobs.component';
import { PropertiesComponent } from './agency/properties/properties.component';
import { ComplexEstatesComponent } from './agency/complex-estates/complex-estates.component';
import { ServiceProvidersComponent } from './agency/service-providers/service-providers.component';
import { AgentsComponent } from './agency/agents/agents.component';
import { AgencyAccountComponent } from './agency/agency-account/agency-account.component';
import { AgencyDetailsComponent } from './agency/agency-account/agency-details/agency-details.component';
import { AgencyPersonalInfoComponent } from './agency/agency-account/personal-info/personal-info.component';
import { EditAgentComponent } from './agency/agents/edit-agent/edit-agent.component';
import { AddEditPropertyComponent } from './agency/properties/add-edit-property/add-edit-property.component';
// import { AgentLoggedInGuard } from './shared/guards/agent-logged-in.guard';

export const routes: Routes = [
	{
		path: 'login',
		component: AuthenticationComponent,
		children: [
			{
				path: 'service-provider',
				children: [
					{
						path: '',
						component: SignInComponent
					},
					{
						path: 'verify-email',
						component: VerifyEmailComponent
					}
				]
			},
			{
				path: 'agency',
				children: [
					{
						path: '',
						component: SignInComponent
					},
					{
						path: 'verify-email',
						component: VerifyEmailComponent
					}
				]
			}
		]
	},
	{
		path: 'service-provider',
		component: PrivateComponent,
		resolve: { auth: AuthResolver },
		canActivate: [ServiceProviderLoggedInGuard],
		children: [
			{
				path: 'jobs',
				component: JobsComponent
			},
			{
				path: 'account',
				children: [
					{
						path: 'company-details',
						component: CompanyInfoComponent
					},
					{
						path: 'personal-info',
						component: PersonalInfoComponent
					},
					{
						path: 'categories',
						component: CategoriesComponent
					},
					{
						path: '',
						component: AccountComponent
					}
				]
			},
			{
				path: 'settings',
				component: SettingsComponent
			},
			{
				path: 'account',
				component: AccountComponent
			},
			{
				path: 'support',
				component: SupportComponent
			},
			{
				path: 'my-locations',
				component: LocationsComponent
			},
			{
				path: '**',
				redirectTo: 'jobs'
			}
		]
	},
	{
		path: 'creator-portal',
		component: CreatorPortalLayoutComponent,
		children: [
			{ path: '', component: CreatorPortalComponent },
			{ path: ':agencyId', component: CreatorPortalComponent },
			{ path: ':agencyId/:propertyId', component: CreatorPortalComponent }
		]
	},
	{
		path: 'agency',
		component: PrivateComponent,
		// TODO: Uncomment when we are ready to add auth for agency
		// resolve: { auth: AuthResolver },
		// canActivate: [AgentLoggedInGuard],
		children: [
			{
				path: 'jobs',
				component: AgencyJobsComponent
			},
			{
				path: 'properties',
				children: [
					{
						path: '',
						component: PropertiesComponent
					},
					{
						path: 'add',
						component: AddEditPropertyComponent
					},
					{
						path: 'edit/:propertyId',
						component: AddEditPropertyComponent
					}
				]
			},
			{
				path: 'complexes-estates',
				component: ComplexEstatesComponent
			},
			{
				path: 'service-providers',
				component: ServiceProvidersComponent
			},
			{
				path: 'agents',
				children: [
					{
						path: '',
						component: AgentsComponent
					},
					{
						path: 'edit/:agentId',
						component: EditAgentComponent
					}
				]
			},
			{
				path: 'account',
				children: [
					{
						path: 'agency-details',
						component: AgencyDetailsComponent
					},
					{
						path: 'personal-info',
						component: AgencyPersonalInfoComponent
					},
					{
						path: '',
						component: AgencyAccountComponent
					}
				]
			},
			{
				path: 'support',
				component: SupportComponent
			},
			{
				path: '**',
				redirectTo: 'jobs'
			}
		]
	},
	{ path: '**', redirectTo: '/login/service-provider', pathMatch: 'full' }
];
