<div class="flex py-3 px-4">
	<div class="flex-none mr-2">
		<img class="h-5 w-5" [src]="iconSrc()" alt="" />
	</div>
	<div class="flex-col">
		<p class="text-sm font-normal text-gray-500 mb-1">{{ title() }}</p>
		<ng-content></ng-content>
	</div>
</div>

