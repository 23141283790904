import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { BreadcrumbItem } from './breadcrumb.interface';

@Component({
	selector: 'app-breadcrumb',
	standalone: true,
	imports: [RouterLink],
	templateUrl: './breadcrumb.component.html',
	styleUrl: './breadcrumb.component.css'
})
export class BreadcrumbComponent {
	@Input() items: BreadcrumbItem[] | null = [];
}
