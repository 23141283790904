import { CommonModule } from '@angular/common';
import {
	Component,
	OnDestroy,
	OnInit,
	computed,
	input,
	model
} from '@angular/core';
import {
	FormControl,
	FormGroup,
	FormsModule,
	ReactiveFormsModule,
	Validators
} from '@angular/forms';
import CountryList, { Country } from 'country-list-with-dial-code-and-flag';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { Subject, takeUntil } from 'rxjs';
import { internationalisePhoneNumber } from '../../../../shared/utils/phone';
import { LoaderComponent } from '../../../../shared/ui/loader/loader.component';
import { LocalStorageService } from '../../../../shared/data-access/local-storage.service';
import { AlertComponent } from '../../../../shared/ui/alert/alert.component';
import { TextInputComponent } from '../../../../shared/ui/text-input/text-input.component';
import { ApiService } from '../../../../shared/data-access/api-service.service';
import { Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { ToastService } from '../../../../shared/data-access/toast.service';
import { PhoneNotFoundComponent } from '../../../../shared/ui/modals/phone-not-found/phone-not-found.component';
import { ModalService } from '../../../../shared/ui/modals/modal.service';
const PHONE_LIB = PhoneNumberUtil.getInstance();
const DEFAULT_LOGIN_MODE = 'phone';
const DEFAULT_COUNTRY = 'ZA';
const DEFAULT_USER_TYPE = 'service-providers';

@Component({
	selector: 'app-login',
	standalone: true,
	imports: [
		ReactiveFormsModule,
		CommonModule,
		FormsModule,
		LoaderComponent,
		AlertComponent,
		TextInputComponent,
		PhoneNotFoundComponent
	],
	templateUrl: './login.component.html',
	styleUrl: './login.component.css'
})
export class LoginComponent implements OnInit, OnDestroy {
	loginForm: FormGroup;
	loginMode = model<'phone' | 'mail'>(DEFAULT_LOGIN_MODE);
	userType = model<'service-providers' | 'agency'>(DEFAULT_USER_TYPE);
	emailOrPhoneNumber = model<string>('');
	continueToOtp = model<boolean>(false);
	secondsRemaining = input<number>(0);
	countries: Array<Country> = [];
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	selectedCountry = CountryList.findOneByCountryCode(DEFAULT_COUNTRY)!;
	loading = false;
	showPhoneNumberNotFound = false;

	cooldownApplicable = computed<boolean>(() => {
		return this.secondsRemaining() > 0 && this.loginMode() === 'phone';
	});
	private destroy$ = new Subject<void>();

	constructor(
		private localStorage: LocalStorageService,
		private apiService: ApiService,
		private router: Router,
		private toastService: ToastService,
		private modalService: ModalService
	) {
		this.loginForm = new FormGroup({
			country: new FormControl(this.selectedCountry.code, [
				Validators.required
			]),
			phone: new FormControl(
				{ value: '', disabled: this.loginMode() === 'mail' },
				[Validators.required]
			),
			email: new FormControl(
				{ value: '', disabled: this.loginMode() === 'phone' },
				[Validators.required, Validators.email]
			)
		});
	}

	ngOnInit() {
		const urlPart = this.router.url.split('/')[2];
		if (urlPart === 'agency') {
			this.userType.set('agency');
		}

		this.countries = CountryList.getAll();

		this.loginForm.controls['country'].valueChanges
			.pipe(takeUntil(this.destroy$))
			.subscribe((countryCode: string) => {
				this.selectedCountry =
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					CountryList.findOneByCountryCode(countryCode)!;
			});

		this.loginForm.controls['phone'].valueChanges
			.pipe(takeUntil(this.destroy$))
			.subscribe((phone: string) => {
				let valid = true;
				try {
					valid = PHONE_LIB.isValidNumberForRegion(
						PHONE_LIB.parse(phone, this.selectedCountry.code),
						this.selectedCountry.code
					);
				} catch (error) {
					valid = false;
				}

				if (!valid) {
					this.loginForm.controls['phone'].setErrors({
						invalid: true
					});
				} else {
					this.loginForm.controls['phone'].setErrors(null);
				}
			});
	}

	swapLoginModes() {
		this.loginMode.set(this.loginMode() === 'phone' ? 'mail' : 'phone');

		if (this.loginMode() === 'phone') {
			this.loginForm.controls['email'].disable();
			this.loginForm.controls['phone'].enable();
		} else {
			this.loginForm.controls['phone'].disable();
			this.loginForm.controls['email'].enable();
		}
	}

	async login() {
		if (this.loading) return;
		this.loading = true;

		if (this.loginMode() === 'mail') {
			this.emailOrPhoneNumber.set(this.loginForm.get('email')?.value);

			// To be used late for auto populate via the email link
			this.localStorage.setItem('otpMail', this.emailOrPhoneNumber());
			this.signInWithEmailLink()
				.pipe()
				.subscribe({
					next: data => {
						console.log('Data received:', data);
						this.continueToOtp.set(true);
						this.toastService.add('Email sent successfully');
					},
					error: error => {
						this.loading = false;
						this.toastService.add(
							'Failed to send mail, please try again later',
							5000,
							'error'
						);
						console.error('Error:', error);
					},
					complete: () => {
						this.loading = false;
					}
				});
		} else {
			const internationalPhoneNumber = internationalisePhoneNumber(
				this.loginForm.get('phone')?.value,
				this.selectedCountry.code
			);

			if (!internationalPhoneNumber) {
				// TODO: Show error message to user with a nice dialog
				alert('Invalid phone number');
				return;
			}

			this.emailOrPhoneNumber.set(internationalPhoneNumber);
			this.apiService
				.postData(`${this.userType()}/find-mobile-number`, {
					mobileNumber: internationalPhoneNumber
				})
				.subscribe({
					next: async data => {
						if (data.body.linked) {
							this.continueToOtp.set(true);
						} else {
							if (
								await this.modalService.showModal<
									null,
									boolean
								>(PhoneNotFoundComponent)
							) {
								this.swapLoginModes();
							}
						}
					},
					error: async error => {
						console.error('Error:', error);
						if (
							await this.modalService.showModal<null, boolean>(
								PhoneNotFoundComponent
							)
						) {
							this.swapLoginModes();
						}
					},
					complete: () => {
						this.loading = false;
					}
				});
		}
	}

	signInWithEmailLink() {
		return this.apiService.postData(
			`${this.userType()}/signin-with-email-link`,
			{
				email: this.emailOrPhoneNumber()
			}
		);
	}

	openPrivacyPolicy() {
		window.open(environment.termsAndConditions, '_blank');
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
