import { Component, model, output } from '@angular/core';
import { PhoneNumberUtil } from 'google-libphonenumber';
import CountryList, { Country } from 'country-list-with-dial-code-and-flag';
import {
	FormControl,
	FormGroup,
	ReactiveFormsModule,
	Validators
} from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { NgClass } from '@angular/common';
import { TextInputComponent } from '../../text-input/text-input.component';
import { internationalisePhoneNumber } from '../../../utils/phone';

const PHONE_LIB = PhoneNumberUtil.getInstance();
const DEFAULT_LOGIN_MODE = 'phone';
const DEFAULT_COUNTRY = 'ZA';

@Component({
	selector: 'fixify-search-linked-property-modal',
	standalone: true,
	imports: [NgClass, ReactiveFormsModule, TextInputComponent],
	templateUrl: './search-linked-property-modal.component.html',
	styleUrl: './search-linked-property-modal.component.css'
})
export class SearchLinkedPropertyModalComponent {
	isModalOpen = model(false);
	search = output<{
		mode: string;
		value: string;
	}>();
	searchForm: FormGroup;
	searchMode = model<'phone' | 'mail'>(DEFAULT_LOGIN_MODE);
	countries: Array<Country> = [];
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	selectedCountry = CountryList.findOneByCountryCode(DEFAULT_COUNTRY)!;
	private destroy$ = new Subject<void>();

	constructor() {
		this.searchForm = new FormGroup({
			country: new FormControl(this.selectedCountry.code, [
				Validators.required
			]),
			phone: new FormControl(
				{ value: '', disabled: this.searchMode() === 'mail' },
				[Validators.required]
			),
			email: new FormControl(
				{ value: '', disabled: this.searchMode() === 'phone' },
				[Validators.required, Validators.email]
			)
		});
	}

	ngOnInit() {
		this.countries = CountryList.getAll();

		this.searchForm.controls['country'].valueChanges
			.pipe(takeUntil(this.destroy$))
			.subscribe((countryCode: string) => {
				this.selectedCountry =
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					CountryList.findOneByCountryCode(countryCode)!;
			});

		this.searchForm.controls['phone'].valueChanges
			.pipe(takeUntil(this.destroy$))
			.subscribe((phone: string) => {
				let valid = true;
				try {
					valid = PHONE_LIB.isValidNumberForRegion(
						PHONE_LIB.parse(phone, this.selectedCountry.code),
						this.selectedCountry.code
					);
				} catch (error) {
					valid = false;
				}

				if (!valid) {
					this.searchForm.controls['phone'].setErrors({
						invalid: true
					});
				} else {
					this.searchForm.controls['phone'].setErrors(null);
				}
			});
	}

	hideModal() {
		this.isModalOpen.set(false);
	}

	swapSearchModes() {
		this.searchMode.set(this.searchMode() === 'phone' ? 'mail' : 'phone');

		if (this.searchMode() === 'phone') {
			this.searchForm.controls['email'].disable();
			this.searchForm.controls['phone'].enable();
		} else {
			this.searchForm.controls['phone'].disable();
			this.searchForm.controls['email'].enable();
		}
	}

	triggerSearch() {
		if (this.searchMode() === 'mail') {
			this.search.emit({
				mode: 'mail',
				value: this.searchForm.get('email')?.value
			});
		} else {
			const internationalPhoneNumber = internationalisePhoneNumber(
				this.searchForm.get('phone')?.value,
				this.selectedCountry.code
			);

			if (!internationalPhoneNumber) {
				// TODO: Show error message to user with a nice dialog
				alert('Invalid phone number');
				return;
			}

			this.search.emit({
				mode: 'phone',
				value: internationalPhoneNumber
			});
		}

		this.hideModal();
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
