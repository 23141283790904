import { Injectable } from '@angular/core';
import {
	BehaviorSubject,
	filter,
	from,
	map,
	Observable,
	of,
	switchMap
} from 'rxjs';
import { Auth, User } from '@angular/fire/auth';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	private authStateReady$ = new BehaviorSubject<boolean>(false);
	private currentUserSubject = new BehaviorSubject<User | null | undefined>(
		undefined
	);
	public currentUser$ = this.currentUserSubject.asObservable();

	constructor(private auth: Auth) {
		this.auth.onAuthStateChanged(user => {
			this.currentUserSubject.next(user);
			if (!this.authStateReady$.value) {
				this.authStateReady$.next(true);
			}
		});
	}

	getCurrentUser(): User | null | undefined {
		return this.currentUserSubject.value;
	}

	isLoggedIn(): boolean {
		return !!this.getCurrentUser();
	}

	async signOut(): Promise<void> {
		await this.auth.signOut();
	}

	getAuthState(): Observable<User | null> {
		return this.authStateReady$.pipe(
			filter(ready => ready),
			switchMap(() => this.currentUser$),
			filter((user): user is User | null => user !== undefined)
		);
	}

	checkClaim(claim: string): Observable<boolean> {
		return this.getAuthState().pipe(
			switchMap(user => {
				if (!user) {
					return of(false);
				}
				return from(user.getIdTokenResult()).pipe(
					map(idToken => {
						return (
							claim.split('.').reduce(
								// eslint-disable-next-line @typescript-eslint/no-explicit-any
								(obj: any, key) => obj && obj[key],
								idToken.claims
							) !== undefined
						);
					})
				);
			})
		);
	}
}
