import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { User } from '@angular/fire/auth';

@Injectable({
	providedIn: 'root'
})
export class AuthResolver implements Resolve<User | null> {
	constructor(private authService: AuthService) {}

	resolve(): Observable<User | null> {
		return this.authService.getAuthState();
	}
}
