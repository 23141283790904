import { Component, computed, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DropdownComponent } from '../../../shared/ui/dropdown/dropdown.component';
import { TextInputComponent } from '../../../shared/ui/text-input/text-input.component';
import { NgClass } from '@angular/common';
import {
	FormControl,
	FormGroup,
	ReactiveFormsModule,
	Validators
} from '@angular/forms';
import { IRoles, ROLES } from '../../../shared/models/agent';
import {
	nameToInitials,
	titleCase
} from '../../../shared/utils/string-manipulation';
import { getCountryCodeFromNumber } from '../../../shared/utils/phone';
import { AvatarComponent } from '../../../shared/ui/avatar/avatar.component';
import { IconComponent } from '../../../shared/ui/icon/icon.component';

interface ITestAgent {
	firstName: string;
	lastName: string;
	email: string;
	role: IRoles;
	thumbnail: string;
	mobileNumber: string;
}
@Component({
	selector: 'app-edit-agent',
	standalone: true,
	imports: [
		DropdownComponent,
		TextInputComponent,
		NgClass,
		ReactiveFormsModule,
		AvatarComponent,
		IconComponent
	],
	templateUrl: './edit-agent.component.html',
	styleUrl: './edit-agent.component.css'
})
export class EditAgentComponent {
	agentInfoForm: FormGroup;
	agentCountry = '';
	selectedRole = signal<{ name: string; value: string } | null>(null);
	roles = ROLES.map(role => ({ name: titleCase(role), value: role }));
	loading = false;

	agent = signal<ITestAgent | null>(null);
	initials = computed(() => {
		if (!this.agent()) {
			return '--';
		}

		return nameToInitials(
			this.agent()?.firstName + ' ' + this.agent()?.lastName
		);
	});

	constructor(
		private route: ActivatedRoute,
		private router: Router
	) {
		const { agentId } = this.route.snapshot.params;

		this.agentInfoForm = new FormGroup({
			firstName: new FormControl('', [Validators.required]),
			lastName: new FormControl('', [Validators.required]),
			email: new FormControl('', [Validators.required, Validators.email]),
			role: new FormControl<IRoles | ''>('', [])
		});
		if (agentId) {
			this.loadAgent(agentId);
		} else {
			this.router.navigate(['/agency/agents']);
		}
	}

	goBack() {
		this.router.navigate(['/agency/agents']);
	}

	loadAgent(agentId: string) {
		// simulate fetch agent details
		new Promise<void>(resolve => {
			setTimeout(() => {
				resolve();
			}, 1000);
		}).then(() => {
			const agent: ITestAgent = {
				firstName: 'John',
				lastName: 'Doe',
				email: 'john@doe.com',
				role: 'admin',
				thumbnail: '',
				mobileNumber: '+27831234567'
			};
			this.agent.set(agent);

			this.agentCountry =
				getCountryCodeFromNumber(agent.mobileNumber) ?? '';
			this.agentInfoForm.patchValue(agent);
		});
	}

	onSubmit() {
		if (this.agentInfoForm.valid) {
			console.log(this.agentInfoForm.value);
		}
	}
}
