import { Component } from '@angular/core';
import { ModalComponent } from '../../../../../shared/ui/modals/modal.service';
import { ModalCardComponent } from '../../../../../shared/ui/modal-card/modal-card.component';
import { IconComponent } from '../../../../../shared/ui/icon/icon.component';

@Component({
	selector: 'app-property-image-guideline',
	standalone: true,
	imports: [ModalCardComponent, IconComponent],
	templateUrl: './property-image-guideline.component.html',
	styleUrl: './property-image-guideline.component.css'
})
export class PropertyImageGuidelineComponent extends ModalComponent<
	null,
	void
> {
	onClose() {
		this.close();
	}
}
