import { Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { PaginationComponent } from '../../../../../shared/ui/table/pagination/pagination.component';
import { TableColumnDirective } from '../../../../../shared/ui/table/directives/table-column.directive';
import { TableComponent } from '../../../../../shared/ui/table/table.component';
import { TextInputComponent } from '../../../../../shared/ui/text-input/text-input.component';
import { IconComponent } from '../../../../../shared/ui/icon/icon.component';
import { NgClass } from '@angular/common';
import {
	ITabItem,
	TabsComponent
} from '../../../../../shared/ui/tabs/tabs.component';
import { ITestAgentInterface } from '../../../../agents/ui/tables/agents-table/agents-table.component';
import { PropertyAvatarComponent } from '../../../../../shared/ui/property-avatar/property-avatar.component';
import { ModalService } from '../../../../../shared/ui/modals/modal.service';
import { PropertyModalComponent } from '../../modals/property-modal/property-modal.component';

export interface ITestPropertyInterface {
	selected?: boolean;
	thumbnail: string;
	id: number;
	address: string;
	suburb: string;
	type: string;
	vacant: boolean;
	agent: ITestAgentInterface;
	totalJobs: number;
	estate: boolean;
}

@Component({
	selector: 'fixify-agency-property-table',
	standalone: true,
	imports: [
		PaginationComponent,
		TableColumnDirective,
		TableComponent,
		TextInputComponent,
		ReactiveFormsModule,
		IconComponent,
		NgClass,
		TabsComponent,
		PropertyAvatarComponent
	],
	templateUrl: './property-table.component.html',
	styleUrl: './property-table.component.css'
})
export class PropertyTableComponent {
	itemsPerPage = 10;
	currentPage = 1;

	barFilters: Array<ITabItem> = [
		{
			label: 'All',
			value: '432',
			active: true
		},
		{
			label: 'Houses',
			value: '255',
			active: false
		},
		{
			label: 'Apartments',
			value: '157',
			active: false
		},
		{
			label: 'Common Property',
			value: '20',
			active: false
		},
		{
			label: 'Archived',
			value: '10',
			active: false
		}
	];

	properties: Array<ITestPropertyInterface> = [
		{
			thumbnail:
				'https://firebasestorage.googleapis.com/v0/b/fixify-dev.appspot.com/o/testImages%2Fproperties%2F1.png?alt=media&token=13d61581-4f1b-411f-9c45-44548fe400ca',
			id: 1,
			address: '24 Main Road, Cloverfield Estate',
			suburb: 'Cape Town',
			type: 'House',
			vacant: false,
			agent: {
				id: 1,
				name: 'Elsabie de Villiers',
				email: '',
				role: 'Property Manager',
				phoneNumber: '+27123456789',
				dateJoined: new Date()
			},
			totalJobs: 0,
			estate: true
		},
		{
			thumbnail:
				'https://firebasestorage.googleapis.com/v0/b/fixify-dev.appspot.com/o/testImages%2Fproperties%2F2.png?alt=media&token=6b0e1af9-2c53-48e1-bde2-daf49e72977f',
			id: 2,
			address: '24 Plant Crescent, Cloverfield Village',
			suburb: 'Blue Downs',
			type: 'Apartment',
			vacant: true,
			agent: {
				id: 2,
				name: 'Annelize Smit',
				email: '',
				role: 'Property Manager',
				phoneNumber: '+27123456789',
				dateJoined: new Date()
			},
			totalJobs: 0,
			estate: false
		},
		{
			thumbnail: '',
			id: 3,
			address: '24 Sunnyridge Estate, Matteville',
			suburb: 'Durbanville',
			type: 'House',
			vacant: false,
			agent: {
				id: 3,
				name: 'Kayleen Summers',
				email: '',
				role: 'Property Manager',
				phoneNumber: '+27123456789',
				dateJoined: new Date()
			},
			totalJobs: 1,
			estate: false
		},
		{
			thumbnail:
				'https://firebasestorage.googleapis.com/v0/b/fixify-dev.appspot.com/o/testImages%2Fproperties%2F3.png?alt=media&token=2a9cfbd1-8de3-4c4d-afb7-d8e264331686',
			id: 4,
			address:
				'2 Borzoi Fernanale Crescent, Strandfontein Village, Cape Town',
			suburb: 'Parow',
			type: 'Apartment',
			vacant: true,
			agent: {
				id: 4,
				name: 'Petro Kriek',
				email: '',
				role: 'Property Manager',
				phoneNumber: '+27123456789',
				dateJoined: new Date()
			},
			totalJobs: 3,
			estate: true
		},
		{
			thumbnail:
				'https://firebasestorage.googleapis.com/v0/b/fixify-dev.appspot.com/o/testImages%2Fproperties%2F4.png?alt=media&token=4265cf09-adf9-486c-9980-a21b88bfabd9',
			id: 5,
			address: '101 General J M Olivier Street',
			suburb: 'Cape Town',
			type: 'Apartment',
			vacant: false,
			agent: {
				id: 5,
				name: 'Francis Johannes Jouber',
				email: '',
				role: 'Property Manager',
				phoneNumber: '+27123456789',
				dateJoined: new Date()
			},
			totalJobs: 2,
			estate: false
		},
		{
			thumbnail: '',
			id: 6,
			address: '30 Andiswa St, Fairdale',
			suburb: 'Durbanville',
			type: 'Common Property',
			vacant: false,
			agent: {
				id: 6,
				name: 'Miranda Pretorius',
				email: '',
				role: 'Property Manager',
				phoneNumber: '+27123456789',
				dateJoined: new Date()
			},
			totalJobs: 6,
			estate: false
		},
		{
			thumbnail: '',
			id: 7,
			address: '24 Plant Crescent, Cloverfield Estate',
			suburb: 'Test Suburb',
			type: 'Apartment',
			vacant: true,
			agent: {
				id: 7,
				name: 'Lilitha Matemba',
				email: '',
				role: 'Property Manager',
				phoneNumber: '+27123456789',
				dateJoined: new Date()
			},
			totalJobs: 0,
			estate: false
		}
	];
	filterForm: FormGroup;

	constructor(
		private router: Router,
		private modalService: ModalService
	) {
		this.filterForm = new FormGroup({
			search: new FormControl('')
		});
	}

	// This will be returned by the BE, but for testing this is calculated
	get totalPages(): number {
		return Math.ceil(this.properties.length / this.itemsPerPage);
	}

	onPageChange(page: number) {
		this.currentPage = page;
		// Trigger a BE call to fetch the data

		// BE to handle the pending ones, as they should be returned first and apply with pagination offset too

		// Always make a new array instance of the data. This is a workaround to trigger change detection for flowbite inits for
		// interactive UI elements like dropdowns, modals, etc.
		this.properties = [...this.properties];
	}

	onRowClicked(property: ITestPropertyInterface) {
		console.log(property);
		this.openInviteAgentsModal();
	}

	onTabItemChange(item: ITabItem) {
		console.log(item);
		// Apply BE filter
	}

	openInviteAgentsModal() {
		this.modalService.showModal<null, void>(PropertyModalComponent);
	}
}
