<div class="p-4 flex gap-2 items-start bg-stone">
	<fixify-icon
		class="w-5 h-5 text-gray-500"
		[name]="iconName()"
	></fixify-icon>
	<div class="flex flex-col gap-1">
		<p class="text-sm font-normal text-gray-500">{{ title() }}</p>
		<p class="text-sm font-normal text-gray-900">{{ subtitle() }}</p>
	</div>
</div>

