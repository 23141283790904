@if (loadingState()) {
	<img
		[src]="
			secondary()
				? 'assets/svg/spinner-light.svg'
				: 'assets/svg/spinner-dark.svg'
		"
		alt="Loader"
		class="animate-spin"
	/>
} @else {
	<ng-content></ng-content>
}
