import { Component } from '@angular/core';

@Component({
	selector: 'app-agency-jobs',
	standalone: true,
	imports: [],
	templateUrl: './agency-jobs.component.html',
	styleUrl: './agency-jobs.component.css'
})
export class AgencyJobsComponent {}
