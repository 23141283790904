import { NgClass } from '@angular/common';
import { Component, input } from '@angular/core';

@Component({
	selector: 'fixify-divider',
	standalone: true,
	imports: [NgClass],
	templateUrl: './divider.component.html',
	styleUrl: './divider.component.css'
})
export class DividerComponent {
	class = input('');
}
