import { Component, model, output } from '@angular/core';
import { BadgeComponent } from '../badge/badge.component';
import { NgClass } from '@angular/common';

export interface SimpleNavInput {
	name: string;
	selected: boolean;
	badgeValue?: number;
}

@Component({
	selector: 'fixify-simple-nav-bar',
	standalone: true,
	imports: [BadgeComponent, NgClass],
	templateUrl: './simple-nav-bar.component.html',
	styleUrl: './simple-nav-bar.component.css'
})
export class SimpleNavBarComponent {
	navItems = model.required<Array<SimpleNavInput>>();
	onItem = output<SimpleNavInput>();

	onItemClick(item: SimpleNavInput) {
		this.onItem.emit(item);
	}
}
