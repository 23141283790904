export const titleCase = (str: string) => {
	return str
		.toLowerCase()
		.split(' ')
		.map(word => word.charAt(0).toUpperCase() + word.slice(1))
		.join(' ');
};

export const nameToInitials = (name: string) => {
	const words = name.split(' ') ?? [];
	const capitalizedWords = words.map((word, index) => {
		if (index < 2) {
			return word.charAt(0).toUpperCase();
		}
		return;
	});
	return capitalizedWords.join('') || '--';
};
