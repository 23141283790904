@if (isModalOpen()) {
	<div
		class="fixed z-50 inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
	>
		<div
			id="verify-modal"
			(click)="hideModal()"
			class="fixed top-0 left-0 right-0 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full flex items-center justify-center"
		>
			<div
				(click)="$event.stopPropagation()"
				class="relative w-full max-w-md max-h-full"
			>
				<div class="relative bg-white rounded-lg shadow p-8">
					<div class="flex items-start justify-between rounded-t">
						<p class="text-xl font-semibold text-gray-900">
							Verify Mobile Number
						</p>
					</div>
					@if (!verificationId) {
						<div class="mb-6">
							<label
								for="phone"
								class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
								>Phone number</label
							>
							<input
								type="tel"
								id="phone"
								[disabled]="true"
								[(ngModel)]="phoneNumber"
								class="form-input rounded-l-none"
								placeholder="+1234567890"
								required
							/>
						</div>
						<div
							id="recaptcha-container"
							class="my-4 mx-auto"
						></div>
						@if (errorMessage) {
							<p>{{ errorMessage }}</p>
						}
						<button
							[disabled]="!recaptchaVerifier"
							(click)="sendOTP()"
							class="btn-primary"
						>
							Send OTP
						</button>
					} @else {
						<div class="mb-6">
							<label
								for="otp"
								class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
								>Enter OTP</label
							>
							<input
								type="text"
								id="otp"
								[(ngModel)]="verificationCode"
								class="form-input rounded-l-none"
								placeholder="Enter OTP"
								required
							/>
						</div>
						<div
							id="recaptcha-container"
							class="my-4 mx-auto"
						></div>
						@if (errorMessage) {
							<p class="mb-2 text-sm text-red-500">
								{{ errorMessage }}
							</p>
						}
						<button
							(click)="verifyOTP()"
							[disabled]="disableVerify()"
							class="btn-primary"
						>
							Verify OTP
						</button>
					}
				</div>
			</div>
		</div>
	</div>
}

