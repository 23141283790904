export interface MenuItem {
	name: string;
	route: string;
	active: boolean;
	icon: string;
	bottom?: boolean;
}

const spMenuItems: MenuItem[] = [
	{
		name: 'Jobs',
		route: '/service-provider/jobs',
		active: false,
		icon: 'briefcase'
	},
	{
		name: 'My locations',
		route: '/service-provider/my-locations',
		active: false,
		icon: 'marker-pin'
	},
	{
		name: 'My account',
		route: '/service-provider/account',
		active: false,
		icon: 'building'
	},
	{
		name: 'Support',
		route: '/service-provider/support',
		active: false,
		icon: 'help-circle'
	}
];

const agencyMenuItems: MenuItem[] = [
	{
		name: 'Jobs',
		route: '/agency/jobs',
		active: false,
		icon: 'briefcase'
	},
	{
		name: 'Properties',
		route: '/agency/properties',
		active: false,
		icon: 'house-gray'
	},
	{
		name: 'Complexes & Estates',
		route: '/agency/complexes-estates',
		active: false,
		icon: 'building'
	},
	{
		name: 'Service providers',
		route: '/agency/service-providers',
		active: false,
		icon: 'marker-pin'
	},
	{
		name: 'Agents',
		route: '/agency/agents',
		active: false,
		icon: 'users-gray'
	},
	{
		name: 'Account',
		route: '/agency/account',
		active: false,
		icon: 'user-square-gray'
	},
	{
		name: 'Support',
		route: '/agency/support',
		active: false,
		icon: 'help-circle',
		bottom: true
	}
];

export const menuItems = {
	'service-providers': spMenuItems,
	agency: agencyMenuItems
};
