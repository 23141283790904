<div class="relative w-full h-full">
	<div
		class="rounded-full w-full h-full aspect-1 text-gray-900 bg-gray-200"
	></div>
	@if (placeholder()) {
		<div
			class="flex items-center justify-center w-full h-full absolute top-0 left-0 leading-none text-xs font-medium text-gray-900"
		>
			<p class="text-center">{{ placeholder() }}</p>
		</div>
	} @else {
		<div
			class="flex items-center justify-center w-full h-full absolute top-0 left-0 leading-none text-xs font-medium text-gray-900"
		>
			<fixify-icon
				name="user-02"
				class="p-2 stroke-1 w-full h-full text-gray-400"
			></fixify-icon>
		</div>
	}

	@if (source()) {
		<img
			class="absolute top-0 rounded-full object-cover w-full h-full"
			[src]="source()"
		/>
	}
</div>

