<header class="bg-white border-b-2 border-gray-100 sticky top-0 flex z-40">
	<nav class="flex grow items-center h-14 content-center px-8 pt-4 pb-3">
		<div class="flex-grow flex"></div>
		<div
			class="h-10 cursor-pointer rounded-full bg-gray-200 aspect-square flex justify-center items-center"
		>
			<p class="text-gray-900">{{ shortenedName$ | async }}</p>
		</div>
	</nav>
</header>
