import { Component, signal } from '@angular/core';
import { TableComponent } from '../../../../../shared/ui/table/table.component';
import { TableColumnDirective } from '../../../../../shared/ui/table/directives/table-column.directive';
import { PaginationComponent } from '../../../../../shared/ui/table/pagination/pagination.component';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TextInputComponent } from '../../../../../shared/ui/text-input/text-input.component';
import { IconComponent } from '../../../../../shared/ui/icon/icon.component';
import { DatePipe } from '@angular/common';
import { AvatarComponent } from '../../../../../shared/ui/avatar/avatar.component';
import { ViewAgentModalComponent } from '../../modals/view-agent-modal/view-agent-modal.component';
import { Router } from '@angular/router';
import { DeleteModalComponent } from '../../../../../shared/ui/modals/delete/delete.component';
import { StatusPillComponent } from '../../../../../shared/ui/status-pill/status-pill.component';

export interface ITestAgentInterface {
	id: number;
	name: string | null;
	role: string;
	email: string;
	phoneNumber: string | null;
	dateJoined: Date | null;
}

@Component({
	selector: 'fixify-agents-table',
	standalone: true,
	imports: [
		TableComponent,
		TableColumnDirective,
		PaginationComponent,
		ReactiveFormsModule,
		TextInputComponent,
		IconComponent,
		DatePipe,
		AvatarComponent,
		ViewAgentModalComponent,
		DeleteModalComponent,
		StatusPillComponent
	],
	templateUrl: './agents-table.component.html',
	styleUrl: './agents-table.component.css'
})
export class AgentsTableComponent {
	itemsPerPage = 10;
	currentPage = 1;
	isViewAgentModalOpen = signal<boolean>(false);
	isDeleteModalOpen = signal<boolean>(false);
	selectedAgent = signal<ITestAgentInterface | null>(null);

	activeAgents: Array<ITestAgentInterface> = [
		{
			id: 14,
			name: null,
			role: 'Admin',
			email: 'jane@example.com',
			phoneNumber: null,
			dateJoined: null
		},
		{
			id: 15,
			name: null,
			role: 'Designer',
			email: 'jane@example.com',
			phoneNumber: null,
			dateJoined: null
		},
		{
			id: 16,
			name: null,
			role: 'Admin',
			email: 'jane@example.com',
			phoneNumber: null,
			dateJoined: null
		},
		{
			id: 1,
			name: 'A Doe',
			role: 'Developer',
			email: 'john@example.com',
			phoneNumber: '+27234567890',
			dateJoined: new Date(2022, 0, 15)
		},
		{
			id: 2,
			name: 'B Smith',
			role: 'Designer',
			email: 'jane@example.com',
			phoneNumber: '+27987654321',
			dateJoined: new Date(2023, 5, 1)
		},
		{
			id: 3,
			name: 'C Smith',
			role: 'Designer',
			email: 'jane@example.com',
			phoneNumber: '+27987654321',
			dateJoined: new Date(2023, 5, 1)
		},
		{
			id: 4,
			name: 'D Smith',
			role: 'Designer',
			email: 'jane@example.com',
			phoneNumber: '+27987654321',
			dateJoined: new Date(2023, 5, 1)
		},
		{
			id: 5,
			name: 'E Smith',
			role: 'Designer',
			email: 'jane@example.com',
			phoneNumber: '+27987654321',
			dateJoined: new Date(2023, 5, 1)
		},
		{
			id: 6,
			name: 'F Smith',
			role: 'Designer',
			email: 'jane@example.com',
			phoneNumber: '+27987654321',
			dateJoined: new Date(2023, 5, 1)
		},
		{
			id: 7,
			name: 'G Smith',
			role: 'Designer',
			email: 'jane@example.com',
			phoneNumber: '+27987654321',
			dateJoined: new Date(2023, 5, 1)
		},
		{
			id: 8,
			name: 'H Smith',
			role: 'Designer',
			email: 'jane@example.com',
			phoneNumber: '+27987654321',
			dateJoined: new Date(2023, 5, 1)
		},
		{
			id: 9,
			name: 'I Smith',
			role: 'Designer',
			email: 'jane@example.com',
			phoneNumber: '+27987654321',
			dateJoined: new Date(2023, 5, 1)
		},
		{
			id: 10,
			name: 'J Smith',
			role: 'Designer',
			email: 'jane@example.com',
			phoneNumber: '+27987654321',
			dateJoined: new Date(2023, 5, 1)
		},
		{
			id: 11,
			name: 'K Smith',
			role: 'Designer',
			email: 'jane@example.com',
			phoneNumber: '+27987654321',
			dateJoined: new Date(2023, 5, 1)
		},
		{
			id: 12,
			name: 'L Smith',
			role: 'Designer',
			email: 'jane@example.com',
			phoneNumber: '+27987654321',
			dateJoined: new Date(2023, 5, 1)
		},
		{
			id: 13,
			name: 'M Smith',
			role: 'Designer',
			email: 'jane@example.com',
			phoneNumber: '+27987654321',
			dateJoined: new Date(2023, 5, 1)
		}
	];

	filterForm: FormGroup;

	constructor(private router: Router) {
		this.filterForm = new FormGroup({
			search: new FormControl('')
		});
	}

	// This will be returned by the BE, but for testing this is calculated
	get totalPages(): number {
		return Math.ceil(this.activeAgents.length / this.itemsPerPage);
	}

	isPending(agent: ITestAgentInterface) {
		return agent?.dateJoined === null;
	}

	onPageChange(page: number) {
		this.currentPage = page;
		// Trigger a BE call to fetch the data

		// BE to handle the pending ones, as they should be returned first and apply with pagination offset too

		// Always make a new array instance of the data. This is a workaround to trigger change detection for flowbite inits for
		// interactive UI elements like dropdowns, modals, etc.
		this.activeAgents = [...this.activeAgents];
	}

	deleteSelectedAgent() {
		if (!this.selectedAgent()) {
			return;
		}

		// call BE to delete agent
		// Rememer to call the BE to fetch the data again after deletion, for the current page
		this.isDeleteModalOpen.set(false);
	}

	editAgent(agentId: string | number) {
		this.router.navigate(['/agency/agents/edit', agentId]);

		// NEW WINDOW EXAMPLE: open a new window to edit the agent at path /agency/agents/edit/:id

		// const url = this.router.serializeUrl(
		// 	this.router.createUrlTree(['/agency/agents/edit', agentId])
		// );

		// window.open(url, '_blank');
	}

	editSelectedAgent() {
		if (!this.selectedAgent()) {
			return;
		}

		this.editAgent(this.selectedAgent()?.id ?? '');
	}

	showDeleteAgencyModal(agent?: ITestAgentInterface) {
		if (!agent) {
			if (!this.selectedAgent()) {
				return;
			}

			this.selectedAgent.set(this.selectedAgent());
		} else {
			this.selectedAgent.set(agent);
		}

		this.isDeleteModalOpen.set(true);
	}

	viewAgent(agent: ITestAgentInterface) {
		this.selectedAgent.set(agent);
		this.isViewAgentModalOpen.set(true);
	}
}
