import { Component } from '@angular/core';
import { ModalComponent } from '../../../../../shared/ui/modals/modal.service';
import { ModalCardComponent } from '../../../../../shared/ui/modal-card/modal-card.component';
import { IconComponent } from '../../../../../shared/ui/icon/icon.component';

@Component({
	selector: 'app-sp-favouriting',
	standalone: true,
	imports: [ModalCardComponent, IconComponent],
	templateUrl: './sp-favouriting.component.html',
	styleUrl: './sp-favouriting.component.css'
})
export class SpFavouritingComponent extends ModalComponent<null, void> {
	onClose() {
		this.close();
	}
}
