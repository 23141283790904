<div class="rounded border-2 border-gray-100 p-6">
	<div class="flex items-center gap-4">
		<img [hidden]="!iconSrc()" [src]="iconSrc()" />
		<div class="flex-col gap-1">
			<div class="flex items-center gap-2">
				<div class="text-lg font-medium">
					{{ title() }}
				</div>
				<fixify-status-pill
					[hidden]="!status()"
					[color]="statusColor()"
					>{{ status() }}</fixify-status-pill
				>
			</div>
			<div
				[hidden]="!subtitle()"
				class="text-sm font-normal text-gray-700"
			>
				{{ subtitle() }}
			</div>
		</div>
		<div class="ml-auto class flex gap-2">
			<button
				(click)="onPrimaryClick()"
				[hidden]="!primaryAction()"
				class="btn btn-primary danger small"
			>
				{{ primaryAction() }}
			</button>
			<button
				(click)="onSecondaryClick()"
				[hidden]="!secondaryAction()"
				class="btn btn-primary small"
			>
				{{ secondaryAction() }}
			</button>
		</div>
	</div>
	@if (contentRef.children.length) {
		<fixify-divider class="my-4 !bg-gray-200"></fixify-divider>
	}
	<div #contentRef>
		<ng-content></ng-content>
	</div>
</div>

