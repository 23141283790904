import { NgClass } from '@angular/common';
import { Component, Input, input } from '@angular/core';
import {
	AbstractControl,
	FormControl,
	ReactiveFormsModule
} from '@angular/forms';

@Component({
	selector: 'fixify-dropdown',
	standalone: true,
	imports: [NgClass, ReactiveFormsModule],
	templateUrl: './dropdown.component.html',
	styleUrl: './dropdown.component.css'
})
export class DropdownComponent<T> {
	@Input() control!: AbstractControl;
	items = input.required<Array<T>>();
	nameFieldOfItem = input<keyof T | ''>('');
	valueFieldOfItem = input<keyof T | ''>('');
	currentlySelectedItem = input<T | null>(null);
	defaultText = input<string>('Select an item');
	defaultSelectable = input<boolean>(true);
	errorText = input<string>('Invalid input');
	showErrorMessage = input<boolean>(true);
	showRequiredStar = input<boolean>(false);
	label = input<string>('');
	disabled = input<boolean>(false);
	id = input<string>('dropdown');

	ngControl!: FormControl;

	ngOnInit() {
		this.ngControl = this.control as FormControl;
	}

	getItemName(item: T) {
		if (this.nameFieldOfItem()) {
			return item[this.nameFieldOfItem() as keyof T];
		}

		return item;
	}

	getItemValue(item: T) {
		if (this.valueFieldOfItem()) {
			return item[this.valueFieldOfItem() as keyof T];
		}

		return item;
	}
}
