<div class="flex items-center gap-2 px-4 pb-2">
	@for (navItem of navItems(); track $index) {
		<div
			(click)="onItemClick(navItem)"
			[ngClass]="{
				'bg-gray-200 font-semibold text-gray-900': navItem.selected
			}"
			class="flex rounded-md font-medium text-gray-500 px-2.5 py-1.5 gap-2 cursor-pointer"
		>
			<div class="flex items-center">
				<p class="text-xs">
					{{ navItem.name }}
				</p>
			</div>

			@if (navItem.badgeValue) {
				<fixify-badge>{{ navItem.badgeValue }}</fixify-badge>
			}
		</div>
	}
</div>

