@if (isModalOpen()) {
	<div
		class="fixed z-50 inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
	>
		<div
			id="delete-modal"
			(click)="hideModal()"
			class="fixed top-0 left-0 right-0 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full flex items-center justify-center"
		>
			<div
				(click)="$event.stopPropagation()"
				class="relative w-full max-w-md max-h-full"
			>
				<div class="relative bg-white rounded-lg shadow p-8">
					<div class="flex items-start justify-between rounded-t">
						<p class="text-xl font-semibold text-gray-900">
							{{ title() }}
						</p>
					</div>
					<div class="pt-1 pb-6">
						<p class="text-base leading-relaxed text-gray-500">
							{{ body() }}
						</p>
					</div>
					<div
						class="flex items-center justify-end space-x-2 border-gray-200 rounded-b"
					>
						@if (enableCancel()) {
							<button
								(click)="hideModal()"
								type="button"
								class="btn-outline border-gray-200"
							>
								Cancel
							</button>
						}
						<button
							(click)="deleteClicked()"
							type="button"
							class="btn-primary danger"
						>
							{{ deleteButtonText() }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
}

