<fixify-modal (onBackdropClick)="onClose()" size="xl">
	<div [formGroup]="inviteForm">
		<div class="w-8 h-8 p-1.5 rounded bg-primary-50 mb-5">
			<fixify-icon
				(click)="onClose()"
				class="w-5 h-5 text-primary-500 cursor-pointer stroke-1"
				name="users-plus"
			></fixify-icon>
		</div>
		<div class="mb-6">
			<p class="text-xl font-semibold text-gray-900 mb-1">
				Invite agents
			</p>
			<p class="text-base font-normal text-gray-500">
				Invite your agents by adding their email address and role below.
			</p>
		</div>
		<div formArrayName="invites" class="mb-6">
			@for (
				formGroup of allFormGroups();
				track formGroup;
				let count = $count
			) {
				<div [formGroup]="formGroup" class="flex gap-2 mb-4">
					<fixify-text-input
						class="w-2/3"
						[control]="formGroup.get('email')!"
						placeholder="Enter email address"
						inputType="email"
						[showErrorMessage]="false"
					></fixify-text-input>
					<div class="flex gap-2 items-center">
						<fixify-dropdown
							class="flex-grow w-1/3"
							[control]="formGroup.get('role')!"
							[items]="roles"
							[nameFieldOfItem]="'name'"
							[defaultSelectable]="false"
							[valueFieldOfItem]="'value'"
							defaultText="-- Agent role --"
						></fixify-dropdown>
						@if (count - 1 !== 0) {
							<div
								(click)="removeForm($index)"
								class="w-5 h-5 rounded bg-gray-100 p-1 cursor-pointer"
							>
								<fixify-icon
									class="text-primary-500 flex-none w-3 h-3 stroke-2"
									name="x-close"
								>
								</fixify-icon>
							</div>
						}
					</div>
				</div>
			}
			<button
				(click)="addAnother()"
				class="btn-outline border-0 text-primary-500 p-2"
			>
				<div class="flex gap-2 items-center">
					<fixify-icon
						class="w-5 h-5 text-primary-500 stroke-2"
						name="plus"
					></fixify-icon>
					<p class="text-sm font-medium">Add another</p>
				</div>
			</button>
		</div>
		<div
			class="flex justify-end items-center gap-2 border-gray-200 rounded-b mt-2"
		>
			<button (click)="onClose()" type="button" class="btn-outline">
				Cancel
			</button>

			<button
				[disabled]="inviteForm.invalid"
				type="button"
				class="btn-primary"
			>
				Invite
			</button>
		</div>
	</div>
</fixify-modal>

