import { NgClass } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
	FormControl,
	FormGroup,
	ReactiveFormsModule,
	Validators
} from '@angular/forms';
import { LocalStorageService } from '../../shared/data-access/local-storage.service';
import { LoaderComponent } from '../../shared/ui/loader/loader.component';
import { TextInputComponent } from '../../shared/ui/text-input/text-input.component';
import {
	Auth,
	isSignInWithEmailLink,
	signInWithEmailLink
} from '@angular/fire/auth';
import { AlertComponent } from '../../shared/ui/alert/alert.component';
import { Router } from '@angular/router';

@Component({
	selector: 'app-verify-email',
	standalone: true,
	imports: [
		NgClass,
		ReactiveFormsModule,
		LoaderComponent,
		TextInputComponent,
		AlertComponent
	],
	templateUrl: './verify-email.component.html',
	styleUrl: './verify-email.component.css'
})
export class VerifyEmailComponent implements OnInit {
	emailForm: FormGroup;
	loading = false;
	error = '';

	constructor(
		private localStorage: LocalStorageService,
		private auth: Auth,
		private router: Router
	) {
		this.emailForm = new FormGroup({
			email: new FormControl('', [Validators.required, Validators.email])
		});
	}

	ngOnInit() {
		const localMail = this.localStorage.getItem('otpMail');

		if (localMail) {
			this.emailForm.get('email')?.setValue(localMail);
		}
	}

	async continue() {
		this.error = '';
		this.loading = true;

		const valid = isSignInWithEmailLink(this.auth, window.location.href);

		if (!valid) {
			this.error = 'Invalid email link';
			this.loading = false;
			return;
		}

		signInWithEmailLink(
			this.auth,
			this.emailForm.get('email')?.value,
			window.location.href
		)
			.then(() => {
				const urlParams = new URLSearchParams(window.location.search);
				const customUrl = urlParams.get('customUrl');
				if (customUrl) {
					this.router.navigateByUrl(customUrl);
				} else {
					this.error = 'Invalid email link';
					console.error(
						'No custom URL found in the query parameters'
					);
					// Handle the case when no custom URL is present
				}
			})
			.catch(error => {
				console.error(error);
				this.error = 'Invalid email link';
			})
			.finally(() => {
				this.loading = false;
			});

		await new Promise<void>(res => {
			setTimeout(() => {
				this.loading = false;
				res();
			}, 2000);
		});
	}
}
