@if (isModalOpen()) {
	<div
		class="fixed z-50 inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
	>
		<div
			id="delete-modal"
			(click)="hideModal()"
			class="fixed top-0 left-0 right-0 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full flex items-center justify-center"
		>
			<div
				(click)="$event.stopPropagation()"
				class="relative w-full max-w-md max-h-full"
			>
				<div class="relative bg-white rounded-lg shadow p-8">
					<div class="flex gap-2 justify-between items-start mb-5">
						<div class="w-20 h-20">
							<fixify-avatar></fixify-avatar>
						</div>
						<fixify-icon
							(click)="hideModal()"
							class="w-8 h-8 text-gray-400 cursor-pointer"
							name="x-close"
						></fixify-icon>
					</div>
					<div class="flex flex-col gap-1 mb-6">
						<p class="text-xl font-semibold">{{ agent()?.name }}</p>
						<p class="text-sm text-gray-500">
							Joined
							{{ agent()?.dateJoined | date: 'dd MMM YYYY' }}
						</p>
					</div>
					<div class="flex gap-2 mb-8">
						<div
							class="flex-1 rounded-lg border-gray-100 border-2 p-4 flex flex-col gap-0.5 justify-start"
						>
							<fixify-icon
								class="w-6 h-6 mb-2"
								name="home-05-plus"
							></fixify-icon>
							<p class="text-sm text-gray-500">
								Propery portfolio
							</p>
							<p class="text-sm text-gray-900">
								172 linked properties
							</p>
						</div>
						<div
							class="flex-1 rounded-lg border-gray-100 border-2 p-4 flex flex-col gap-0.5 justify-start"
						>
							<fixify-icon
								class="w-6 h-6 mb-2"
								name="luggage-plus"
							></fixify-icon>
							<p class="text-sm text-gray-500">Managed jobs</p>
							<p class="text-sm text-gray-900">72 linked jobs</p>
						</div>
					</div>
					<div class="mb-8">
						<ul>
							<li class="py-3">
								<div class="flex">
									<fixify-icon
										class="w-5 h-5 mr-2 text-gray-700"
										name="user-01"
									></fixify-icon>
									<div class="flex flex-col justify-start">
										<p
											class="text-sm font-normal text-gray-500 mb-1"
										>
											Role
										</p>
										<p
											class="text-sm font-normal text-gray-900"
										>
											{{ agent()?.role }}
										</p>
									</div>
								</div>
							</li>
							<li class="py-3">
								<div class="flex">
									<fixify-icon
										class="w-5 h-5 mr-2 text-gray-700"
										name="mail-01"
									></fixify-icon>
									<div class="flex flex-col justify-start">
										<p
											class="text-sm font-normal text-gray-500 mb-1"
										>
											Email address
										</p>
										<p
											class="text-sm font-normal text-gray-900"
										>
											{{ agent()?.email }}
										</p>
									</div>
								</div>
							</li>
							<li class="py-3">
								<div class="flex">
									<fixify-icon
										class="w-5 h-5 mr-2 text-gray-700"
										name="phone"
									></fixify-icon>
									<div class="flex flex-col justify-start">
										<p
											class="text-sm font-normal text-gray-500 mb-1"
										>
											Contact number
										</p>
										<p
											class="text-sm font-normal text-gray-900"
										>
											{{ agent()?.phoneNumber }}
										</p>
									</div>
								</div>
							</li>
						</ul>
					</div>
					<div
						class="flex items-center justify-between space-x-2 border-gray-200 rounded-b mt-2"
					>
						<button
							(click)="deleteClicked()"
							type="button"
							class="btn-outline danger border-none"
						>
							Delete Agent
						</button>

						<button
							(click)="editClicked()"
							type="button"
							class="btn-primary"
						>
							Edit Details
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
}

