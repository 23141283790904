import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BreadcrumbItem } from '../breadcrumb/breadcrumb.interface';

@Injectable({
	providedIn: 'root'
})
export class BreadcrumbService {
	breadcrumbsSubject = new BehaviorSubject<BreadcrumbItem[]>([]);
	public breadcrumbs$ = this.breadcrumbsSubject.asObservable();

	setBreadcrumbs(items: BreadcrumbItem[]) {
		this.breadcrumbsSubject.next(items);
	}
}
