import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class LocalStorageService {
	setItem(key: string, value: unknown): void {
		localStorage.setItem(key, JSON.stringify(value));
	}

	getItem<T>(key: string): T | null {
		const value = JSON.parse(localStorage.getItem(key) || '{}');

		if (typeof value === 'object' && !Object.keys(value).length) {
			return null;
		}

		return value as T;
	}

	removeItem(key: string): void {
		localStorage.removeItem(key);
	}

	clear(): void {
		localStorage.clear();
	}
}
