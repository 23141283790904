import { Component, input } from '@angular/core';

@Component({
	selector: 'fixify-property-info-card',
	standalone: true,
	imports: [],
	templateUrl: './property-info-card.component.html',
	styleUrl: './property-info-card.component.css'
})
export class PropertyInfoCardComponent {
	iconSrc = input.required<string>();
	title = input.required<string>();
	text = input.required<string>();
}
