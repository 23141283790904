import { NgClass } from '@angular/common';
import { Component, input, output, signal } from '@angular/core';
import { Property } from '../../service/creator-portal.service';

@Component({
	selector: 'fixify-linked-addresses',
	standalone: true,
	imports: [NgClass],
	templateUrl: './linked-addresses.component.html',
	styleUrl: './linked-addresses.component.css'
})
export class LinkedAddressesComponent {
	linkedProperties = input<Property[]>();
	setLinkedProperty = output<Property | null>();
	selectedProperty = signal<Property | null>(null);
	goBack = output<boolean>();

	isSelected(id: string) {
		return this.selectedProperty()?.id === id;
	}

	back() {
		this.goBack.emit(true);
	}

	continue() {
		this.setLinkedProperty.emit(this.selectedProperty());
	}

	selectAddress(property: Property) {
		this.selectedProperty.set(property);
	}
}
