import { Component, input } from '@angular/core';

export interface LinkedAgency {
	id: string;
	name: string;
	url: string;
	formattedAddress: string;
	managingAgent: string;
	email: string;
	contactNumber: string;
}

@Component({
	selector: 'fixify-agency-tile',
	standalone: true,
	imports: [],
	templateUrl: './agency-tile.component.html',
	styleUrl: './agency-tile.component.css'
})
export class AgencyTileComponent {
	linkedAgency = input.required<LinkedAgency>();
}
