<div class="">
	@if (label()) {
		<label for="mobileNumber" class="input-label mb-4"
			>{{ label() }} <span class="text-red-500"></span
		></label>
	}

	<input
		accept="image/*"
		id="file-input"
		class="hidden"
		type="file"
		(change)="onFileSelected($event)"
	/>

	@if (!fileToUploadSrc() && !url()) {
		<div
			[ngClass]="{ 'bg-stone': dragOver() }"
			class="flex flex-col justify-center items-center p-8 rounded-lg border-2 border-dashed border-gray-200"
			(dragover)="onDragOver($event)"
			(dragleave)="onDragLeave($event)"
			(drop)="onDrop($event)"
		>
			<img class="mb-4" src="assets/svg/upload-cloud.svg" />
			<div class="flex flex-col items-center pb-4">
				<p class="text-sm font-medium text-gray-900 mb-1">Upload</p>
				<p class="text-sm font-normal text-gray-500">
					Click to browse or drag & drop a file here
				</p>
			</div>
			<button (click)="triggerFileSelect()" class="btn btn-secondary">
				Upload
			</button>
		</div>
	} @else {
		<div>
			<div
				[ngClass]="{ 'w-48 h-48': largerLogo() }"
				class="w-20 h-20 mb-4"
			>
				<div class="relative">
					<img
						[ngClass]="{ 'w-48 h-48': largerLogo() }"
						class="w-20 h-20 rounded-full object-contain"
						[src]="source()"
						alt="Selected File"
					/>
					@if (uploading()) {
						<div
							class="absolute top-0 left-0 w-full h-full overflow-hidden rounded-full"
						>
							<div
								id="loading-background"
								class="absolute top-0 right-0 h-full bg-primary-500 transition-all duration-100 ease-out opacity-50"
								[style]="'width: ' + (100 - percentage()) + '%'"
							></div>
						</div>
						@if (percentage() !== 100) {
							<div
								class="absolute top-0 left-0 w-full h-full flex items-center justify-center"
							>
								<span
									class="text-white font-bold text-sm [text-shadow:_0_1px_2px_rgb(0_0_0_/_90%)]"
									>{{ percentage() }}%</span
								>
							</div>
						}
					}
				</div>
			</div>
			<div class="flex gap-3">
				<button
					[disabled]="uploading() || removing()"
					(click)="edit()"
					class="btn btn-secondary small"
				>
					Edit photo
				</button>
				<button
					[disabled]="uploading() || removing()"
					(click)="remove()"
					class="btn btn-outline small border-none danger"
				>
					Remove
				</button>
			</div>
		</div>
	}
</div>

