@if (isModalOpen()) {
	<div
		class="fixed z-50 inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
	>
		<div
			id="delete-modal"
			(click)="hideModal()"
			class="fixed top-0 left-0 right-0 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full flex items-center justify-center"
		>
			<div
				(click)="$event.stopPropagation()"
				class="relative w-full max-w-md max-h-full"
			>
				<div class="relative bg-white rounded-lg shadow p-8">
					<div class="flex justify-between mb-5">
						<img src="assets/svg/search-icon-modal.svg" />
						<img
							(click)="hideModal()"
							class="cursor-pointer"
							src="assets/svg/close.svg"
						/>
					</div>
					<div class="flex items-start justify-between rounded-t">
						<p class="text-xl font-semibold text-gray-900">
							Search for a linked property
						</p>
					</div>
					<div class="pt-1 pb-6">
						<p class="text-base leading-relaxed text-gray-500">
							Enter your phone number or email address to search
							for a property you are linked to.
						</p>
					</div>
					<form [formGroup]="searchForm" class="max-w">
						<div
							[ngClass]="{
								hidden: searchMode() === 'mail'
							}"
						>
							<div class="mb-4">
								<select
									formControlName="country"
									id="country"
									class="form-input"
								>
									@for (country of countries; track $index) {
										<option
											[selected]="
												country.name ===
												selectedCountry.name
											"
											[value]="country.code"
										>
											{{ country.name }}
										</option>
									}
								</select>
							</div>
							<div class="mb-8">
								<fixify-text-input
									[control]="searchForm.get('phone')!"
									id="phone"
									inputType="tel"
									placeholder="{{
										selectedCountry.dial_code
									}}"
									errorText="Invalid phone number"
								></fixify-text-input>
							</div>
						</div>
						<div
							[ngClass]="{
								hidden: searchMode() === 'phone'
							}"
							class="mb-8"
						>
							<fixify-text-input
								[control]="searchForm.get('email')!"
								id="email"
								inputType="email"
								placeholder="Enter your email address"
								errorText="Invalid email address"
							></fixify-text-input>
						</div>

						<div class="grid grid-rows-2 gap-2">
							<button
								(click)="triggerSearch()"
								[disabled]="!searchForm.valid"
								class="btn-primary w-full"
							>
								Search
							</button>
							<button
								(click)="swapSearchModes()"
								class="btn-outline"
							>
								<img
									[src]="
										searchMode() === 'phone'
											? 'assets/svg/mail.svg'
											: 'assets/svg/phone.svg'
									"
									alt="img"
									class="h-6 w-6 mr-2"
								/>
								Continue with
								{{
									searchMode() === 'phone' ? 'Email' : 'Phone'
								}}
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
}

