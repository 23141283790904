import { Component } from '@angular/core';

@Component({
	selector: 'app-jobs',
	standalone: true,
	imports: [],
	templateUrl: './jobs.component.html',
	styleUrl: './jobs.component.css'
})
export class JobsComponent {}
