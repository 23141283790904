import { Component, inject, input, model } from '@angular/core';
import { NgClass, TitleCasePipe } from '@angular/common';
import { AlertComponent } from '../../../../../shared/ui/alert/alert.component';
import {
	FormArray,
	FormControl,
	FormGroup,
	ReactiveFormsModule,
	Validators
} from '@angular/forms';

import { atLeastOneActiveTask } from '../../pipes/one-task-active.pipe';
import {
	CategoryForm,
	ITaskCheckbox,
	EditCategoryComponent
} from '../edit-category/edit-category.component';
import { Storage } from '@angular/fire/storage';
import {
	ServiceProviderCategory,
	Category,
	UploadedDocuments
} from '../../../../../shared/data-access/service-provider/service-provider.types';

@Component({
	selector: 'fixify-current-categories',
	standalone: true,
	imports: [
		NgClass,
		TitleCasePipe,
		AlertComponent,
		ReactiveFormsModule,
		EditCategoryComponent
	],
	templateUrl: './current-categories.component.html',
	styleUrl: './current-categories.component.css'
})
export class CurrentCategoriesComponent {
	storage = inject(Storage);
	spCategories = input<Array<ServiceProviderCategory>>([]);
	categories = input<Array<Category>>([]);
	unavailableCategoryIds = input<Array<string>>([]);
	editingId = model<string | null>(null);
	categoryForm: FormGroup<CategoryForm>;

	constructor() {
		this.categoryForm = new FormGroup({
			id: new FormControl<string | null>(null),
			name: new FormControl<string | null>('', Validators.required),
			tasks: new FormArray<FormGroup<ITaskCheckbox>>(
				[],
				[atLeastOneActiveTask()]
			),
			supportingDocuments: new FormControl<Array<UploadedDocuments>>([])
		});
	}

	download(url: string) {
		window.open(url, '_blank');
	}

	stopEdit() {
		this.editingId.set(null);
	}

	startEditing(category: ServiceProviderCategory) {
		if (category.status === 'pending') {
			return;
		}
		const categoryDetails = this.categories()?.find(
			c => c.id === category.id
		);

		if (!categoryDetails) {
			alert('Category not found');
			return;
		}

		const tasks: Array<{ name: string; selected: boolean }> = [];

		categoryDetails.tasks.forEach(task => {
			tasks.push({
				name: task,
				selected: category.tasks.includes(task)
			});
		});

		this.categoryForm.patchValue({
			name: category.name,
			tasks: tasks,
			supportingDocuments: category.supportingDocuments
		});

		this.editingId.set(category.id);
	}
}
