<!-- MODALS -->
@if (creatorPortalService.isSearchModalOpen()) {
	<fixify-search-linked-property-modal
		[(isModalOpen)]="creatorPortalService.isSearchModalOpen"
		(search)="search($event)"
	></fixify-search-linked-property-modal>
}

<!-- TODO - SHOW JOB SUMBITTED MODAL -->
<fixify-job-submitted-modal
	[isModalOpen]="creatorPortalService.jobCreated()"
	(onCreate)="createAnotherJob($event)"
	(onView)="viewCreatedJob($event)"
></fixify-job-submitted-modal>

<!-- TODO - SHOW DELETE WITH REASON MODAL -->
<!-- <fixify-delete-with-reason-modal
	[isModalOpen]="true"
	title="Cancel this maintenance job?"
	body="This action is irreversible. Are you sure you want to cancel this job?"
	deleteButtonText="Ja nee"
></fixify-delete-with-reason-modal> -->

<!--  END MODALS -->

@if (creatorPortalService.searching()) {
	<fixify-searching-linked-property></fixify-searching-linked-property>
} @else {
	<div
		[ngClass]="{
			'mb-16': !creatorPortalService.createJob(),
			'mb-4': creatorPortalService.createJob()
		}"
		class="flex flex-start"
	>
		<img
			[src]="(agency | async)?.logoUrl ?? 'assets/svg/logo.svg'"
			alt="Agency image"
			class="max-w-[108px] max-h-[64px] w-auto h-auto object-contain"
		/>
	</div>
	@if (creatorPortalService.noAgency()) {
		<fixify-no-linked-agency></fixify-no-linked-agency>
	} @else if (creatorPortalService.landing()) {
		<fixify-creator-start
			(openSearchModal)="openSearchModal()"
			[selection]="creatorPortalService.selection()"
		></fixify-creator-start>
	} @else if (creatorPortalService.showLinkedProperties()) {
		@if (creatorPortalService.linkedProperties().length > 0) {
			<fixify-linked-addresses
				[linkedProperties]="creatorPortalService.linkedProperties()"
				(setLinkedProperty)="setLinkedProperty($event)"
				(goBack)="creatorPortalService.reset()"
			></fixify-linked-addresses>
		} @else {
			<fixify-no-linked-addresses
				[estates]="creatorPortalService.availableEstates()"
				(createProperty)="createAProperty($event)"
				(goBack)="creatorPortalService.reset()"
			>
			</fixify-no-linked-addresses>
		}
	} @else if (
		creatorPortalService.createJob() &&
		creatorPortalService.linkedProperty()
	) {
		<fixify-new-job
			(onEditAddress)="changeAddresses()"
			(createJob)="createJob($event)"
			[agencyId]="agencyId.value"
			[propertyId]="propertyId.value"
			[linkedProperty]="creatorPortalService.linkedProperty()!"
			[canInterchangeProperty]="
				creatorPortalService.canInterchangeProperty()
			"
		></fixify-new-job>
	} @else if (creatorPortalService.showJobDetails()) {
		<fixify-creator-job-details></fixify-creator-job-details>
	}
}

