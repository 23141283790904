import { Component, input, model, output } from '@angular/core';
import { LinkedAgency } from '../../../../service-provider/locations/ui/agency-tile/agency-tile.component';
import { IconComponent } from '../../icon/icon.component';

@Component({
	selector: 'fixify-view-linked-agency-modal',
	standalone: true,
	imports: [IconComponent],
	templateUrl: './view-linked-agency-modal.component.html',
	styleUrl: './view-linked-agency-modal.component.css'
})
export class ViewLinkedAgencyModalComponent {
	linkedAgency = input.required<LinkedAgency>();
	isModalOpen = model<boolean>(false);
	onDelink = output<void>();

	hideModal() {
		this.isModalOpen.set(false);
	}

	delink() {
		this.onDelink.emit();
		this.hideModal();
	}
}
