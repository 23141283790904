<div class="w-full">
	<div (click)="back()" class="inline-block p-2 mb-10 cursor-pointer">
		<p class="flex items-center text-gray-800 text-sm font-medium">
			<span class="inline-flex justify-center items-center">
				<img
					src="assets/svg/arrow-left.svg"
					alt="back"
					class="w-4 h-4 mr-2"
				/>
			</span>
			Back
		</p>
	</div>
	<p class="">
		@if (loginMode() === 'mail') {
			<p class="mb-2 leading-tight text-3xl font-medium pb-1">
				Check your inbox to confirm your email address
			</p>
			<p class="leading-tight text-base font-normal text-gray-600 mb-8">
				We sent a link to <strong>{{ emailOrPhoneNumber() }}</strong
				>. If you don’t see it, check your spam folder. After confirming
				your email, you can explore the platform.
			</p>
		} @else {
			<p class="leading-tight text-3xl font-medium pb-1 mb-8">
				@if (!showRecaptcha) {
					Enter the 6-digit code sent to you at
					{{ redactedPhoneNumber() }}.
				} @else {
					Are you a robot?
				}
			</p>
		}
	</p>

	@if (loginMode() === 'phone') {
		@if (!showRecaptcha) {
			<form [formGroup]="codeForm" class="max-w">
				<div class="mb-8">
					<fixify-text-input
						[control]="codeForm.get('otp')!"
						id="otp"
						inputType="text"
						placeholder="Enter code"
						errorText="Invalid OTP"
						[showRequiredStar]="true"
					></fixify-text-input>
				</div>
			</form>

			<div class="grid grid-cols-2 gap-2 items-center">
				<div (click)="resendOtp()">
					<p
						[ngClass]="{ disabled: secondsRemaining() > 0 }"
						class="text-action"
					>
						Resend code
						{{
							secondsRemaining() > 0
								? '(' + secondsRemaining() + 's)'
								: ''
						}}
					</p>
				</div>

				<button
					[ngClass]="{
						disabled: !codeForm.valid
					}"
					[disabled]="!codeForm.valid"
					class="btn-primary w-full"
					(click)="verifyOTP()"
				>
					Continue
				</button>
			</div>
		} @else {
			<div id="recaptcha-container" class="my-4 mx-auto"></div>
		}
	} @else {
		<app-alert [showAlert]="emailResent" type="success">
			<p class="text-sm">Confirmation email sent</p>
		</app-alert>
		<button
			(click)="resendEmail()"
			[disabled]="secondsRemaining() > 0 || loading"
			[ngClass]="{
				disabled: secondsRemaining() > 0 || loading
			}"
			class="btn-outline w-full mt-4"
		>
			<app-loader
				[secondary]="true"
				[loadingState]="loading"
				class="flex justify-center"
			>
				Resend email
				{{
					secondsRemaining() > 0
						? '(' + secondsRemaining() + 's)'
						: ''
				}}
			</app-loader>
		</button>
	}
</div>
