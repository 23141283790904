import { Component, signal } from '@angular/core';
import { ModalComponent } from '../../../../../shared/ui/modals/modal.service';
import { IconComponent } from '../../../../../shared/ui/icon/icon.component';
import {
	FormArray,
	FormControl,
	FormGroup,
	ReactiveFormsModule,
	Validators
} from '@angular/forms';
import { TextInputComponent } from '../../../../../shared/ui/text-input/text-input.component';
import { DropdownComponent } from '../../../../../shared/ui/dropdown/dropdown.component';
import { IRoles, ROLES } from '../../../../../shared/models/agent';
import { titleCase } from '../../../../../shared/utils/string-manipulation';
import { ModalCardComponent } from '../../../../../shared/ui/modal-card/modal-card.component';
import { noDuplicateEmails } from '../../../../../shared/validators';

@Component({
	selector: 'app-invite-agents-modal',
	standalone: true,
	imports: [
		IconComponent,
		ReactiveFormsModule,
		TextInputComponent,
		DropdownComponent,
		ModalCardComponent
	],
	templateUrl: './invite-agents-modal.component.html',
	styleUrl: './invite-agents-modal.component.css'
})
export class InviteAgentsModalComponent extends ModalComponent<null, void> {
	inviteForm: FormGroup;
	isModalOpen = signal<boolean>(false);
	roles = ROLES.map(role => ({ name: titleCase(role), value: role }));

	constructor() {
		super();
		this.inviteForm = new FormGroup({
			invites: new FormArray(
				[
					new FormGroup({
						email: new FormControl('', [
							Validators.required,
							Validators.email
						]),
						role: new FormControl<IRoles | ''>('', [
							Validators.required
						])
					})
				],
				[noDuplicateEmails]
			)
		});
	}

	addAnother() {
		(this.inviteForm.controls['invites'] as FormArray).push(
			new FormGroup({
				email: new FormControl('', [
					Validators.required,
					Validators.email
				]),
				role: new FormControl<IRoles | ''>('', [Validators.required])
			})
		);

		this.inviteForm.updateValueAndValidity();
	}

	allFormGroups(): FormGroup[] {
		return (this.inviteForm.controls['invites'] as FormArray)
			.controls as FormGroup[];
	}

	onClose() {
		this.close();
	}

	removeForm(index: number) {
		(this.inviteForm.controls['invites'] as FormArray).removeAt(index);
		this.inviteForm.updateValueAndValidity();
	}
}
