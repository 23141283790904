<div class="flex flex-col justify-center items-center">
	<div class="mt-8 mt-4">
		<img src="assets/svg/empty-box.svg" />
	</div>
	<p class="text-base font-semibold text-gray-700 mt-4 mb-1">
		You have no jobs
	</p>
	<p class="text-sm font-normal text-gray-500 mb-4">
		Finish setting up your account to start creating jobs.
	</p>
	<button class="btn btn-primary small">Complete account</button>
</div>

