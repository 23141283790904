<nav class="flex mb-1.5" aria-label="Breadcrumb">
	<ol class="inline-flex items-center space-x-1 md:space-x-3">
		@for (item of items ?? []; track item.label; let last = $last) {
			<li class="inline-flex items-center">
				@if (!last) {
					<a
						[routerLink]="item.url"
						class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-primary-600"
					>
						{{ item.label }}
					</a>
					<svg
						class="w-3 h-3 text-gray-400 mx-1"
						aria-hidden="true"
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 6 10"
					>
						<path
							stroke="currentColor"
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="2"
							d="m1 9 4-4-4-4"
						/>
					</svg>
				} @else {
					<span class="text-sm font-medium text-gray-500">{{
						item.label
					}}</span>
				}
			</li>
		}
	</ol>
</nav>
