@if (isModalOpen()) {
	<div
		class="fixed z-50 inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
	>
		<div
			id="delete-modal"
			(click)="hideModal()"
			class="fixed top-0 left-0 right-0 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full flex items-center justify-center"
		>
			<div
				(click)="$event.stopPropagation()"
				class="relative w-full max-w-md max-h-full"
			>
				<div class="relative bg-white rounded-lg shadow p-8">
					<img
						class="w-20 h-12 bg-primary-50 mr-4 rounded"
						src="assets/svg/no-locations.svg"
					/>
					<p class="py-5 text-xl font-semibold">
						{{ linkedAgency().name }}
					</p>
					<ul>
						<li class="py-3">
							<div class="flex">
								<img
									class="w-5 h-5 mr-2"
									src="assets/svg/marker-pin-gray.svg"
								/>
								<div class="flex flex-col justify-start">
									<p
										class="text-sm font-normal text-gray-500 mb-1"
									>
										Location
									</p>
									<p
										class="text-sm font-normal text-gray-900"
									>
										{{ linkedAgency().formattedAddress }}
									</p>
								</div>
							</div>
						</li>
						<li class="py-3">
							<div class="flex">
								<fixify-icon
									class="w-5 h-5 mr-2 text-gray-700"
									name="user-01"
								></fixify-icon>
								<div class="flex flex-col justify-start">
									<p
										class="text-sm font-normal text-gray-500 mb-1"
									>
										Managing Agent
									</p>
									<p
										class="text-sm font-normal text-gray-900"
									>
										{{ linkedAgency().managingAgent }}
									</p>
								</div>
							</div>
						</li>
						<li class="py-3">
							<div class="flex">
								<fixify-icon
									class="w-5 h-5 mr-2 text-gray-700"
									name="mail-01"
								></fixify-icon>
								<div class="flex flex-col justify-start">
									<p
										class="text-sm font-normal text-gray-500 mb-1"
									>
										Email address
									</p>
									<p
										class="text-sm font-normal text-gray-900"
									>
										{{ linkedAgency().email }}
									</p>
								</div>
							</div>
						</li>
						<li class="py-3">
							<div class="flex">
								<fixify-icon
									class="w-5 h-5 mr-2 text-gray-700"
									name="phone"
								></fixify-icon>
								<div class="flex flex-col justify-start">
									<p
										class="text-sm font-normal text-gray-500 mb-1"
									>
										Contact number
									</p>
									<p
										class="text-sm font-normal text-gray-900"
									>
										{{ linkedAgency().contactNumber }}
									</p>
								</div>
							</div>
						</li>
					</ul>
					<div
						class="flex items-center justify-between space-x-2 border-gray-200 rounded-b mt-6"
					>
						<p
							(click)="delink()"
							class="cursor-pointer text-sm font-medium px-2 py-3 text-red-700"
						>
							Delink from this agency
						</p>
						<button
							(click)="hideModal()"
							type="button"
							class="btn-outline border-gray-200"
						>
							Cancel
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
}

