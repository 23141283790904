import { Component } from '@angular/core';
import { ModalCardComponent } from '../../modal-card/modal-card.component';
import { ModalComponent } from '../modal.service';

@Component({
	selector: 'fixify-phone-not-found',
	standalone: true,
	imports: [ModalCardComponent],
	templateUrl: './phone-not-found.component.html',
	styleUrl: './phone-not-found.component.css'
})
export class PhoneNotFoundComponent extends ModalComponent<null, boolean> {
	onClose() {
		this.close(false);
	}

	continueWithEmail() {
		this.close(true);
	}
}
