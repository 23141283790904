import { Injectable, InjectionToken, Injector } from '@angular/core';
import { AgencyService } from '../data-access/agency/agency.service';
import { ServiceProviderService } from '../data-access/service-provider/service-provider.service';
import { AuthService } from '../auth/auth.service';
import { firstValueFrom } from 'rxjs';

export const CURRENT_AUTH_USER_SERVICE = new InjectionToken<
	Promise<ServiceProviderService | AgencyService>
>('CURRENT_AUTH_USER_SERVICE');

@Injectable({ providedIn: 'root' })
export class UserServiceFactory {
	constructor(
		private injector: Injector,
		private authService: AuthService
	) {}

	async createUserService(): Promise<ServiceProviderService | AgencyService> {
		const isAgent = await firstValueFrom(
			this.authService.checkClaim('agent.agencyId')
		);
		const isServiceProvider = await firstValueFrom(
			this.authService.checkClaim('serviceProvider')
		);

		if (isServiceProvider) {
			return this.injector.get(ServiceProviderService);
		} else if (isAgent) {
			return this.injector.get(AgencyService);
		} else {
			throw new Error('User role not recognized');
		}
	}
}

export const currentAuthServiceProvider = {
	provide: CURRENT_AUTH_USER_SERVICE,
	useFactory: (userServiceFactory: UserServiceFactory) =>
		userServiceFactory.createUserService(),
	deps: [UserServiceFactory]
};
