@for (spCategory of spCategories(); track $index) {
	@if (editingId() == spCategory.id && editingId()) {
		<fixify-edit-category
			[unavailableCategoryIds]="unavailableCategoryIds()"
			[categories]="categories()"
			[spCategory]="spCategory"
			(stopEdit)="stopEdit()"
		></fixify-edit-category>
	} @else {
		<div
			[ngClass]="{
				'pointer-events-none opacity-50':
					editingId() != spCategory.id && editingId()
			}"
			class="p-6 rounded border-2 border-gray-100 mb-6"
		>
			<div class="flex justify-between items-center mb-4">
				<div>
					<p
						[ngClass]="{
							'text-green-500': spCategory.status == 'active',
							'text-red-500': spCategory.status == 'deactivated',
							'text-primary-500': spCategory.status == 'pending'
						}"
						class="text-sm font-medium text-green-500"
					>
						{{ spCategory.status | titlecase }}
					</p>
					<p class="text-xl font-medium text-gray-900">
						{{ spCategory.name }}
					</p>
				</div>
				<div
					(click)="startEditing(spCategory)"
					[ngClass]="{
						'!cursor-default !text-primary-200':
							spCategory.status == 'pending'
					}"
					class="px-3 py-2 text-xs font-medium text-primary-500 cursor-pointer"
				>
					Edit
				</div>
			</div>
			@if (spCategory.status == 'deactivated') {
				<app-alert class="my-4" [type]="'grey'" [showAlert]="true">
					The documents submitted are incorrect. Please update and
					resubmit the correct documents.
				</app-alert>
			}

			<div class="py-2">
				<p class="text-sm text-gray-500 font-normal pb-2">Tasks</p>
				@for (task of spCategory.tasks; track $index) {
					<p class="text-sm text-gray-900 font-normal py-1">
						{{ task }}
					</p>
				}
			</div>
			@if (spCategory.supportingDocuments.length > 0) {
				<div class="py-2"></div>
				<div class="py-2">
					<p class="text-sm text-gray-500 font-normal pb-2">
						Supporting Documents
					</p>
					@for (doc of spCategory.supportingDocuments; track $index) {
						<div class="truncate flex justify-between py-1">
							<p
								class="underline text-ellipsis overflow-hidden text-sm text-gray-900 font-normal py-1"
							>
								{{ doc.fileName }}
							</p>
							<button
								(click)="download(doc.url)"
								class="btn-outline small border-gray-200 ml-1"
							>
								Download
							</button>
						</div>
					}
				</div>
			}
		</div>
	}
}

