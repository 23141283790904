import { Component, input } from '@angular/core';
import { IconComponent } from '../../../../shared/ui/icon/icon.component';

@Component({
	selector: 'fixify-property-info-cell',
	standalone: true,
	imports: [IconComponent],
	templateUrl: './property-info-cell.component.html',
	styleUrl: './property-info-cell.component.css'
})
export class PropertyInfoCellComponent {
	title = input.required<string>();
	subtitle = input.required<string>();
	iconName = input.required<string>();
}
