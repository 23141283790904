@if (label().length > 0) {
	<label class="input-label" [for]="id()"
		>{{ label() }}
		@if (showRequiredStar()) {
			<span class="text-red-700">*</span>
		}
	</label>
}
<div [formGroup]="countryFormGroup" class="flex">
	<select
		[disabled]="disabled()"
		formControlName="country"
		id="country-dropdown"
		class="form-input max-h-10 max-w-28 focus:outline-none focus:ring-0 focus:border-transparent rounded-r-none"
	>
		@for (country of countries; track $index) {
			<option
				[selected]="country.name === selectedCountry?.name"
				[value]="country.code"
			>
				{{ country.countryCode }}
			</option>
		}
	</select>
	<div class="bg-gray-100 w-[1px] flex justify-center items-center">
		<div class="h-4 w-full bg-gray-300"></div>
	</div>
	<input
		[id]="id()"
		[disabled]="disabled()"
		[readOnly]="disabled()"
		[formControl]="ngControl"
		[ngClass]="{
			'cursor-not-allowed focus:ring-0 text-gray-400': disabled(),
			'input-error': control.invalid && control.dirty
		}"
		type="tel"
		class="form-input max-h-10 focus:outline-none focus:ring-1 focus:ring-primary-100 focus:border-transparent rounded-l-none"
		[placeholder]="placeholder()"
	/>
</div>
@if (hasError() && showErrorMessage()) {
	<p class="input-error-text">Invalid phone number</p>
}

