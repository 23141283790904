import { Component, signal } from '@angular/core';
import { BreadcrumbComponent } from '../../../shared/breadcrumb/breadcrumb.component';
import {
	FormControl,
	FormGroup,
	ReactiveFormsModule,
	Validators
} from '@angular/forms';
import CountryList, { Country } from 'country-list-with-dial-code-and-flag';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { Subject, takeUntil, takeWhile } from 'rxjs';
import { TextInputComponent } from '../../../shared/ui/text-input/text-input.component';
import { AsyncPipe, NgClass } from '@angular/common';
import { BreadcrumbService } from '../../../shared/data-access/breadcrumb.service';
import { DropdownComponent } from '../../../shared/ui/dropdown/dropdown.component';
import { titleCase } from '../../../shared/utils/string-manipulation';
import { UploadComponent } from '../../../shared/ui/upload/upload.component';
import { IRoles, ROLES } from '../../../shared/models/agent';
import { AgencyService } from '../../../shared/data-access/agency/agency.service';
import { Agent } from '../../../shared/data-access/agency/agency.types';
import { VerifyMobileNumberComponent } from '../../../shared/ui/modals/verify-mobile-number/verify-mobile-number.component';
import {
	deleteObject,
	getDownloadURL,
	ref,
	Storage,
	uploadBytesResumable
} from '@angular/fire/storage';
const PHONE_LIB = PhoneNumberUtil.getInstance();
const DEFAULT_COUNTRY = 'ZA';

@Component({
	selector: 'app-personal-info',
	standalone: true,
	imports: [
		BreadcrumbComponent,
		AsyncPipe,
		ReactiveFormsModule,
		NgClass,
		TextInputComponent,
		DropdownComponent,
		UploadComponent,
		VerifyMobileNumberComponent
	],
	templateUrl: './personal-info.component.html',
	styleUrl: './personal-info.component.css'
})
export class AgencyPersonalInfoComponent {
	personalInfoForm: FormGroup;
	countries: Array<Country> = [];
	selectedCountry = CountryList.findOneByCountryCode(DEFAULT_COUNTRY);
	selectedRole = signal({ name: 'Admin', value: 'admin' });
	roles = ROLES.map(role => ({ name: titleCase(role), value: role }));
	private destroy$ = new Subject<void>();
	isSubmitting = false;
	uploadState: {
		percentage: number;
		downloadUrl?: string;
		uploading: boolean;
	} = {
		percentage: 0,
		uploading: false
	};

	isMobileNumberVerified = false;
	verifingMobileNumber = false;
	verifiedMobileNumber: string | null = null;

	userId: string | null = null;
	profileLogoUrl = signal<string | null>(null);

	accountCompletion = signal<boolean>(false);

	constructor(
		private breadcrumbService: BreadcrumbService,
		private agencyService: AgencyService,
		private storage: Storage
	) {
		this.personalInfoForm = new FormGroup({
			firstName: new FormControl('', [Validators.required]),
			lastName: new FormControl('', [Validators.required]),
			email: new FormControl('', [Validators.required, Validators.email]),
			role: new FormControl<IRoles>('admin', []),
			country: new FormControl(this.selectedCountry?.code ?? 'ZA', [
				Validators.required
			]),
			mobileNumber: new FormControl('', [Validators.required])
		});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
	}

	breadcrumbs() {
		return this.breadcrumbService.breadcrumbs$;
	}

	hasError(control: string) {
		return (
			this.personalInfoForm.get(control)?.errors &&
			this.personalInfoForm.get(control)?.dirty
		);
	}

	get formTouched(): boolean {
		return this.personalInfoForm.dirty;
	}

	get formValid(): boolean {
		return this.personalInfoForm.valid;
	}

	ngOnInit() {
		this.breadcrumbService.setBreadcrumbs([
			{ label: 'Account', url: '/agency/account' },
			{ label: 'Personal Info' }
		]);

		this.countries = CountryList.getAll();

		this.personalInfoForm.controls['country'].valueChanges
			.pipe(takeUntil(this.destroy$))
			.subscribe((countryCode: string) => {
				this.selectedCountry =
					CountryList.findOneByCountryCode(countryCode);

				const mobileNumber =
					this.personalInfoForm.controls['mobileNumber'].value ?? '';
				this.validateMobileNumber(mobileNumber);
			});

		this.personalInfoForm.controls['mobileNumber'].valueChanges
			.pipe(takeUntil(this.destroy$))
			.subscribe((mobileNumber: string) => {
				this.validateMobileNumber(mobileNumber);
			});

		this.agencyService
			.isSubmitting()
			.pipe(takeUntil(this.destroy$))
			.subscribe(isSubmitting => {
				this.isSubmitting = isSubmitting;
			});

		this.agencyService
			.accountCompletion()
			.pipe(takeUntil(this.destroy$))
			.subscribe(acc => {
				this.accountCompletion.set(acc.profile ?? false);
			});

		this.agencyService
			.agent()
			.pipe(takeUntil(this.destroy$))
			.subscribe(agent => {
				let mobileNumber: number | undefined;
				let regionCode: string | undefined;
				if (agent?.mobileNumber) {
					const countryCode = PHONE_LIB.parse(
						agent.mobileNumber
					).getCountryCode();
					regionCode = PHONE_LIB.getRegionCodeForCountryCode(
						countryCode ?? 27
					);
					if (regionCode) {
						this.selectedCountry = CountryList.findOneByCountryCode(
							regionCode ?? 'ZA'
						);
						mobileNumber = PHONE_LIB.parse(
							agent.mobileNumber
						).getNationalNumber();
					}
				}
				this.userId = agent?.userId ?? null;
				if (agent?.profilePicture)
					this.profileLogoUrl.set(agent.profilePicture);

				this.personalInfoForm.patchValue({
					firstName: agent?.firstName ?? '',
					lastName: agent?.lastName ?? '',
					email: agent?.email ?? '',
					mobileNumber: mobileNumber ?? '',
					role: agent?.role ?? 'admin',
					country: regionCode ?? 'ZA'
				});

				this.personalInfoForm.markAsPristine();
				this.personalInfoForm.markAsUntouched();
			});
	}

	onSubmit() {
		if (this.personalInfoForm.valid && this.personalInfoForm.dirty) {
			const updateServiceProvider: Partial<Agent> = {};
			Object.keys(this.personalInfoForm.controls).forEach(key => {
				if (this.personalInfoForm.controls[key].dirty) {
					if (key === 'country') return;
					if (key === 'mobileNumber') {
						updateServiceProvider[key] =
							`${this.selectedCountry?.dialCode}${this.personalInfoForm.controls[key].value}`;
						this.verifiedMobileNumber =
							updateServiceProvider[key] ?? null;
					} else {
						updateServiceProvider[key as keyof Agent] =
							this.personalInfoForm.controls[key].value;
					}
				}
			});

			if (
				this.verifiedMobileNumber !== null &&
				!this.isMobileNumberVerified
			) {
				this.verifingMobileNumber = true;
				return;
			}

			this.agencyService.updateProfile(updateServiceProvider);
		}
	}

	mobileNumberVerfied(verified: boolean) {
		this.isMobileNumberVerified = verified;
		this.verifingMobileNumber = false;
		this.onSubmit();
	}

	validateMobileNumber(mobileNumber: string) {
		let valid = true;
		try {
			const region = this.selectedCountry?.code ?? 'ZA';
			const number = PHONE_LIB.parse(`${mobileNumber}`, region);
			if (!PHONE_LIB.isValidNumber(number)) {
				throw new Error('Invalid number');
			}

			valid = PHONE_LIB.isValidNumberForRegion(number, region);
		} catch (error) {
			valid = false;
		}
		if (!valid) {
			this.personalInfoForm.controls['mobileNumber'].setErrors({
				invalid: true
			});
		} else {
			this.personalInfoForm.controls['mobileNumber'].setErrors(null);
		}
	}

	async uploadMedia(file: File): Promise<void> {
		if (!this.userId) {
			throw new Error('Agent User ID not set');
		}
		const filePath = `users/${this.userId}/profilePicture/${file.name}`;
		const storageRef = ref(this.storage, filePath);
		const uploadTask = uploadBytesResumable(storageRef, file);

		console.log(file.name);

		if (this.profileLogoUrl()) {
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			await this.deleteMedia().catch(_ => _);
			this.profileLogoUrl.set(null);
		}

		uploadTask.on(
			'state_changed',
			snapshot => {
				const progress =
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				this.uploadState = {
					percentage: Math.round(progress),
					uploading: Math.round(progress) < 100
				};
			},
			error => console.error('Upload failed:', error),
			() => {
				getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
					this.agencyService.updateProfilePicture(downloadURL);
					this.agencyService
						.isSubmittingMedia()
						.pipe(
							takeWhile(value => !value, true),
							takeUntil(this.destroy$)
						)
						.subscribe(value => {
							if (value) {
								this.profileLogoUrl.set(downloadURL);
								this.uploadState = {
									percentage: 100,
									downloadUrl: downloadURL,
									uploading: false
								};
							}
						});
				});
			}
		);
	}

	async deleteMedia() {
		if (!this.profileLogoUrl()) return;
		const storageRef = ref(this.storage, this.profileLogoUrl() as string);
		await deleteObject(storageRef);
		this.agencyService.updateProfilePicture(null);
		this.profileLogoUrl.set(null);
	}
}
