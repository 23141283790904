<div>
	@for (
		categoryForm of allFormGroups();
		track $index;
		let outerIndex = $index
	) {
		<div [ngClass]="{ 'mt-6': outerIndex != 0 }">
			<div class="flex flex-row justify-between">
				<p class="text-sm font-normal text-gray-700">
					Category {{ $index + 1 }}
					@if ($index == 0) {
						<span class="text-red-500">*</span>
					}
				</p>

				<img
					(click)="removeCategory($index)"
					src="assets/svg/plus-grey.svg"
					class="rotate-45 mx-1 cursor-pointer"
				/>
			</div>

			<div class="my-3">
				<fixify-divider></fixify-divider>
			</div>

			<fixify-category-form
				[categoryForm]="categoryForm"
				[categories]="categories()"
				[unavailableCategoryIds]="categoryAlreadyUsedIds()"
			></fixify-category-form>
		</div>
	}
	@if (!allCategoriesInUse()) {
		<button
			type="button"
			(click)="addCategory()"
			class="btn-secondary small"
		>
			Add another category
		</button>
	}
</div>

