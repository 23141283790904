import { NgClass } from '@angular/common';
import { Component, input, model, output } from '@angular/core';

@Component({
	selector: 'fixify-delete-modal',
	standalone: true,
	imports: [NgClass],
	templateUrl: './delete.component.html',
	styleUrl: './delete.component.css'
})
export class DeleteModalComponent {
	title = input<string>('Delete?');
	body = input<string>('Are you sure you want to delete this item?');
	deleteButtonText = input<string>('Delete');
	enableCancel = input<boolean>(true);
	isModalOpen = model<boolean>(false);
	onDelete = output<boolean>();

	deleteClicked() {
		this.onDelete.emit(true);
		this.hideModal();
	}

	hideModal() {
		this.isModalOpen.set(false);
	}
}
