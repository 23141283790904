<fixify-modal (onBackdropClick)="onClose()" size="md">
	<div class="flex justify-end">
		<fixify-icon
			(click)="close()"
			name="x-close"
			class="text-gray-400 w-8 h-8 cursor-pointer"
		></fixify-icon>
	</div>
	<div class="flex flex-col items-center justify-center">
		<img
			class="h-36 w-36 mb-6"
			src="assets/png/property-image-guideline.png"
		/>
		<div class="mx-6 mb-2 text-center">
			<p class="text-base font-semibold text-gray-900 mb-1">
				Property image guideline
			</p>
			<p class="text-sm font-light text-gray-500">
				Adding a property image is optional but helps identify
				properties more easily.
			</p>
			<p class="text-sm font-light text-gray-500">Ensure the image is:</p>
			<ul class="text-sm font-light text-gray-500">
				<li>• Clear</li>
				<li>• Captures an important facade</li>
				<li>• Use a recent photo</li>
			</ul>
		</div>
	</div>
</fixify-modal>

