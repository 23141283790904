import { Injectable } from '@angular/core';
import { BehaviorSubject, take } from 'rxjs';
import { LocalStorageService } from '../local-storage.service';
import { ApiService } from '../api-service.service';
import {
	AccountCompletion,
	Category,
	CompanyDetails,
	PersonalInfo,
	ServiceProvider,
	TransformedCategories,
	UploadedCategory,
	Location
} from './service-provider.types';
import { Auth, signOut } from '@angular/fire/auth';
import { AcceptTermsComponent } from '../../ui/modals/accept-terms/accept-terms.component';
import { ModalService } from '../../ui/modals/modal.service';
import { Router } from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class ServiceProviderService {
	private serviceProvider$ = new BehaviorSubject<ServiceProvider | null>(
		null
	);

	private serviceProviderId$ = new BehaviorSubject<string | null>(null);

	private categories$ = new BehaviorSubject<Array<Category>>([]);
	private locations$ = new BehaviorSubject<Array<Location>>([]);

	private isSubmitting$ = new BehaviorSubject<boolean>(false);
	private isSubmittingMedia$ = new BehaviorSubject<boolean>(false);
	private userName$ = new BehaviorSubject<string | null>(null);

	private accountCompletion$ = new BehaviorSubject<AccountCompletion>({
		companyDetails: false,
		categories: false,
		profile: false
	});

	constructor(
		private localStorage: LocalStorageService,
		private apiService: ApiService,
		private auth: Auth,
		private modalService: ModalService,
		private router: Router
	) {
		// set test account completion
		this.localStorage.setItem('accountCompletion', {
			companyDetails: false,
			categories: false,
			personalInfo: true
		});

		const localAccountCompletion =
			this.localStorage.getItem('accountCompletion');

		if (localAccountCompletion) {
			this.accountCompletion$.next(
				localAccountCompletion as AccountCompletion
			);
		}
		this.fetchAccountOverview();
		this.fetchProfile();
		this.fetchCategories();
		this.fetchLocations();
	}

	acceptTerms() {
		this.apiService
			.postData('service-providers/accept-terms', {})
			.subscribe({
				next: data => {
					const acceptanceTime = data.body as number | null;
					this.serviceProvider()
						.pipe(take(1))
						.subscribe({
							next: serviceProvider => {
								const updatedServiceProvider = {
									...serviceProvider,
									termsAccepted: acceptanceTime
								} as ServiceProvider;
								this.setServiceProvider(updatedServiceProvider);
							}
						});
				},
				error: error => {
					console.error(error);
				}
			});
	}

	signOut() {
		this.localStorage.removeItem('serviceProvider');
		this.clearServiceProvider();
		signOut(this.auth);
		this.router.navigate(['/']);
	}

	fetchAccountOverview() {
		this.apiService.getData('service-providers/account').subscribe({
			next: data => {
				this.accountCompletion$.next(data.body as AccountCompletion);
			},
			error: error => {
				console.error(error);
			}
		});
	}

	fetchProfile() {
		this.apiService.getData('service-providers/profile').subscribe({
			next: async data => {
				const serviceProvider = data.body as ServiceProvider;
				if (!serviceProvider.termsAccepted) {
					// if terms not accepted
					const accepted =
						await this.modalService.showModal(AcceptTermsComponent);
					if (accepted) {
						this.acceptTerms();
					} else {
						this.signOut();
					}
				}
				this.setServiceProvider({
					...serviceProvider,
					name: `${serviceProvider.firstName ?? 'Name'} ${serviceProvider.lastName ?? 'Surname'}`
				});
				this.userName$.next(
					`${serviceProvider.firstName ?? ''} ${serviceProvider.lastName ?? ''}`
				);
				this.serviceProviderId$.next(serviceProvider.id ?? null);
			},
			error: error => {
				console.error(error);
			}
		});
	}

	extractFilenameFromFirebaseUrl(url: string): string {
		const parsedUrl = new URL(url);
		const path = decodeURIComponent(parsedUrl.pathname);

		const segments = path.split('/');
		const filename = segments[segments.length - 1].split('?')[0];
		return filename;
	}

	fetchCategories() {
		this.apiService.getData('service-providers/categories').subscribe({
			next: data => {
				const serviceProvider = data.body as Array<Category>;
				this.setCategories(serviceProvider);
			},
			error: error => {
				console.error(error);
			}
		});
	}

	addCategories(data: TransformedCategories) {
		this.apiService
			.postData('service-providers/categories', data)
			.subscribe({
				next: data => {
					const serviceProvider = data.body as ServiceProvider;
					this.setServiceProvider({
						...serviceProvider,
						name: `${serviceProvider.firstName ?? 'Name'} ${serviceProvider.lastName ?? 'Surname'}`
					});
					this.userName$.next(
						`${serviceProvider.firstName ?? ''} ${serviceProvider.lastName ?? ''}`
					);
					this.fetchAccountOverview();
				},
				error: error => {
					console.error(error);
				}
			});
	}

	editCategory(data: UploadedCategory) {
		this.apiService
			.putData(`service-providers/categories/${data.id}`, {
				tasks: data.tasks,
				supportingDocuments: data.supportingDocuments
			})
			.subscribe({
				next: data => {
					const serviceProvider = data.body as ServiceProvider;
					this.setServiceProvider({
						...serviceProvider,
						name: `${serviceProvider.firstName ?? 'Name'} ${serviceProvider.lastName ?? 'Surname'}`
					});
					this.userName$.next(
						`${serviceProvider.firstName ?? ''} ${serviceProvider.lastName ?? ''}`
					);
					this.fetchAccountOverview();
				},
				error: error => {
					console.error(error);
				}
			});
	}

	deleteCategory(id: string) {
		this.apiService
			.deleteData(`service-providers/categories/${id}`)
			.subscribe({
				next: data => {
					const serviceProvider = data.body as ServiceProvider;
					this.setServiceProvider({
						...serviceProvider,
						name: `${serviceProvider.firstName ?? 'Name'} ${serviceProvider.lastName ?? 'Surname'}`
					});
					this.userName$.next(
						`${serviceProvider.firstName ?? ''} ${serviceProvider.lastName ?? ''}`
					);
					this.fetchAccountOverview();
				},
				error: error => {
					console.error(error);
				}
			});
	}

	updateProfile(data: Partial<PersonalInfo>) {
		this.isSubmitting$.next(true);
		this.apiService.putData('service-providers/profile', data).subscribe({
			next: data => {
				const personalData = data.body as PersonalInfo;
				this.serviceProvider()
					.pipe(take(1))
					.subscribe({
						next: serviceProvider => {
							const updatedServiceProvider = {
								...serviceProvider,
								...personalData
							} as ServiceProvider;
							this.setServiceProvider(updatedServiceProvider);
							this.fetchAccountOverview();
						}
					});
			},
			error: error => {
				console.error(error);
			},
			complete: () => {
				this.isSubmitting$.next(false);
			}
		});
	}

	updateCompany(data: Partial<CompanyDetails>) {
		this.isSubmitting$.next(true);
		this.apiService
			.putData('service-providers/company-details', data)
			.subscribe({
				next: data => {
					const companyDetails = (data.body as ServiceProvider)
						.companyDetails;
					this.serviceProvider()
						.pipe(take(1))
						.subscribe({
							next: serviceProvider => {
								const updatedServiceProvider = {
									...serviceProvider,
									companyDetails: companyDetails
								} as ServiceProvider;
								this.setServiceProvider(updatedServiceProvider);
								this.fetchAccountOverview();
							}
						});
				},
				error: error => {
					console.error(error);
				},
				complete: () => {
					this.isSubmitting$.next(false);
				}
			});
	}

	updateCompanyLogo(companyLogo: string | null) {
		this.isSubmittingMedia$.next(true);
		this.apiService
			.putData('service-providers/company-details', {
				companyLogo
			})
			.subscribe({
				next: () => {
					this.fetchAccountOverview();
				},
				error: error => {
					console.error(error);
				},
				complete: () => {
					this.isSubmittingMedia$.next(false);
				}
			});
	}

	fetchLocations() {
		return this.apiService
			.getData('service-providers/locations')
			.subscribe({
				next: data => {
					const locations = (data.body as Array<Location>).map(
						location => {
							return {
								...location,
								latlng: {
									lat: location.coordinates[0],
									lng: location.coordinates[1]
								}
							};
						}
					);
					this.setLocations(locations);
				},
				error: error => {
					console.error(error);
				}
			});
	}

	addLocation(coordinates: [number, number], name: string) {
		this.apiService
			.postData('service-providers/locations', {
				coordinates,
				name
			})
			.subscribe({
				next: data => {
					const location = {
						...(data.body as Location),
						latlng: { lat: coordinates[0], lng: coordinates[1] }
					};
					this.locations()
						.pipe(take(1))
						.subscribe({
							next: locations => {
								this.setLocations([...locations, location]);
							}
						});
				},
				error: error => {
					console.error(error);
				}
			});
	}

	editLocation(id: string, coordinates: [number, number], name: string) {
		this.apiService
			.putData(`service-providers/locations/${id}`, {
				coordinates,
				name
			})
			.subscribe({
				next: data => {
					const location = {
						...(data.body as Location),
						latlng: { lat: coordinates[0], lng: coordinates[1] }
					};
					this.locations()
						.pipe(take(1))
						.subscribe({
							next: locations => {
								const index = locations.findIndex(
									loc => loc.id === id
								);
								if (index === -1) {
									return;
								}
								locations[index] = location;
								this.setLocations(locations);
							}
						});
				},
				error: error => {
					console.error(error);
				}
			});
	}

	removeLocation(id: string) {
		this.apiService
			.deleteData(`service-providers/locations/${id}`)
			.subscribe({
				next: () => {
					this.locations()
						.pipe(take(1))
						.subscribe({
							next: locations => {
								const index = locations.findIndex(
									loc => loc.id === id
								);
								if (index === -1) {
									return;
								}
								locations.splice(index, 1);
								this.setLocations(locations);
							}
						});
				},
				error: error => {
					console.error(error);
				}
			});
	}

	requestSupport(message: string) {
		return this.apiService.postData('service-providers/request-support', {
			message
		});
	}

	isSubmitting() {
		return this.isSubmitting$.asObservable();
	}

	isSubmittingMedia() {
		return this.isSubmittingMedia$.asObservable();
	}

	accountCompletion() {
		return this.accountCompletion$.asObservable();
	}

	getShortenedName() {
		return this.userName$.asObservable();
	}

	serviceProvider() {
		return this.serviceProvider$.asObservable();
	}

	serviceProviderId() {
		return this.serviceProviderId$.asObservable();
	}

	categories() {
		return this.categories$.asObservable();
	}

	locations() {
		return this.locations$.asObservable();
	}

	setServiceProvider(serviceProvider: ServiceProvider) {
		this.serviceProvider$.next(serviceProvider);
	}

	setCategories(categories: Array<Category>) {
		this.categories$.next(categories);
	}

	setLocations(locations: Array<Location>) {
		this.locations$.next(locations);
	}

	clearServiceProvider() {
		this.serviceProvider$.next(null);
	}
}
