<fixify-modal (onBackdropClick)="onClose()" size="4xl">
	<div class="flex mb-6">
		<fixify-property-avatar
			class="w-32 h-20"
			url="https://firebasestorage.googleapis.com/v0/b/fixify-dev.appspot.com/o/testImages%2Fproperties%2Flarge-1.png?alt=media&token=ab8303c8-8ac7-4401-a475-5cebdc13a805"
		></fixify-property-avatar>
	</div>

	<div class="flex flex-col mb-10">
		<p class="text-3xl font-medium text-gray-900">
			2 Borzoi Fernanale Crescent · Unit 87
		</p>
		<p class="text-xl font-normal text-gray-900">Cape Town, 7441</p>
	</div>

	<div class="mb-6">
		<div class="flex grid grid-cols-3 gap-2">
			<fixify-property-info-cell
				title="Type"
				subtitle="Apartment"
				iconName="building-03"
			></fixify-property-info-cell>
			<fixify-property-info-cell
				title="Status"
				subtitle="Occupied"
				iconName="user-circle"
			></fixify-property-info-cell>
			<fixify-property-info-cell
				title="Total jobs"
				subtitle="2"
				iconName="tool-02"
			></fixify-property-info-cell>
		</div>
	</div>

	<div class="flex flex-col mb-6">
		<div
			class="py-4 gap-4 flex justify-between items-center bg-white min-h-20 border-b-2 border-gray-100"
		>
			<p class="leading-tight text-base font-semibold text-gray-900">
				Occupants
			</p>
			<div
				class="flex text-right flex-col gap-1 leading-tight text-base font-normal text-gray-900"
			>
				<p>Stacey Kruger</p>
				<p>Kaylin Marie Rivers</p>
			</div>
		</div>
		<div
			class="py-4 gap-4 flex justify-between items-center bg-white min-h-20 border-b-2 border-gray-100"
		>
			<p class="leading-tight text-base font-semibold text-gray-900">
				Landlords/Trustees
			</p>
			<div
				class="flex flex-col gap-1 leading-tight text-base font-normal text-gray-900"
			>
				<p>Riaan Louw</p>
			</div>
		</div>
		<div
			class="py-4 gap-4 flex justify-between items-center bg-white min-h-20 border-b-2 border-gray-100"
		>
			<p class="leading-tight text-base font-semibold text-gray-900">
				Managing Agent
			</p>
			<div
				class="flex flex-col gap-1 leading-tight text-base font-normal text-gray-900"
			>
				<div class="flex gap-3 items-center">
					<fixify-avatar class="h-8 w-8"></fixify-avatar>
					<p>Elsabe de Villiers</p>
				</div>
			</div>
		</div>
		<div
			class="py-4 flex flex-col justify-center bg-white min-h-20 border-b-2 border-gray-100"
		>
			<div class="w-full flex items-center gap-2 justify-between">
				<div class="flex">
					<p
						class="leading-tight text-base font-semibold text-gray-900"
					>
						Linked Complex/Estate
					</p>
					@if (isLinkedToEstate()) {
						<div class="ml-2 w-5 h-5 p-0.5 bg-primary-100 rounded">
							<fixify-icon
								class="text-primary-900 w-4 h-4"
								name="home-05"
								symbol="filled"
							></fixify-icon>
						</div>
					}
				</div>
				<button
					[ngClass]="{
						'btn-secondary': !isLinkedToEstate(),
						'btn-outline border-gray-200': isLinkedToEstate()
					}"
					class="small"
				>
					{{
						!isLinkedToEstate() ? 'Link to complex/estate' : 'Edit'
					}}
				</button>
			</div>
			@if (isLinkedToEstate()) {
				<p class="text-sm text-gray-900 font-normal">
					Oceanridge Estate
				</p>
			}
		</div>
		<div
			class="py-4 flex flex-col justify-center bg-white min-h-20 border-b-2 border-gray-100"
		>
			<div class="w-full flex items-center gap-2 justify-between">
				<p class="leading-tight text-base font-semibold text-gray-900">
					Favourite service providers
				</p>
				<button
					[ngClass]="{
						'btn-secondary': !hasFavouriteSps(),
						'btn-outline border-gray-200': hasFavouriteSps()
					}"
					class="small"
				>
					{{ !hasFavouriteSps() ? 'Add service providers' : 'Edit' }}
				</button>
			</div>
			@if (hasFavouriteSps()) {
				<div class="flex flex-wrap gap-2 mt-4">
					<fixify-sp-info-card
						companyName="Apex Plumbing Company hmm ok this is w"
						imageUrl=""
					></fixify-sp-info-card>
					<fixify-sp-info-card
						companyName="Apex Plumbing Co."
						imageUrl=""
					></fixify-sp-info-card>
					<fixify-sp-info-card
						companyName="Apex Plumbing Co."
						imageUrl=""
					></fixify-sp-info-card>
				</div>
			}
		</div>
	</div>

	<div class="flex justify-between gap-2">
		<button class="btn btn-outline danger border-0">
			Archive Property
		</button>
		<div class="flex gap-2">
			<button class="btn-outline">Create job</button>
			<button class="btn-primary">Edit details</button>
		</div>
	</div>
</fixify-modal>

