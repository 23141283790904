import { NgClass } from '@angular/common';
import { Component, input, output } from '@angular/core';
import { IconComponent } from '../../icon/icon.component';

@Component({
	selector: 'fixify-pagination',
	standalone: true,
	imports: [NgClass, IconComponent],
	templateUrl: './pagination.component.html',
	styleUrl: './pagination.component.css'
})
export class PaginationComponent {
	currentPage = input<number>(1);
	totalPages = input<number>(1);
	pageSize = input<number>(10);
	maxItems = input<number>(2);
	pageChange = output<number>();

	get showingFrom(): number {
		return Math.min(
			(this.currentPage() - 1) * this.pageSize() + 1,
			this.maxItems()
		);
	}

	get showingTo(): number {
		return Math.min(this.currentPage() * this.pageSize(), this.maxItems());
	}

	onPageChange(page: number) {
		if (page < 1 || page > this.totalPages()) {
			return;
		}
		this.pageChange.emit(page);
	}
}
