import { AsyncPipe, NgClass } from '@angular/common';
import { Component, inject, OnDestroy } from '@angular/core';
import { SearchLinkedPropertyModalComponent } from '../shared/ui/modals/search-linked-property-modal/search-linked-property-modal.component';
import { RequestCardComponent } from './ui/request-card/request-card.component';
import { SearchingComponent } from './ui/searching/searching.component';
import { CreatorStartComponent } from './ui/creator-start/creator-start.component';
import { LinkedAddressesComponent } from './ui/linked-addresses/linked-addresses.component';
import { NewJobComponent } from './ui/new-job/new-job.component';
import { NoLinkedAddressesComponent } from './ui/no-linked-addresses/no-linked-addresses.component';
import { JobDetailsComponent } from './ui/job-details/job-details.component';
import { Subject } from 'rxjs';
import {
	createAJobRequest,
	createAPropertyRequest,
	residentialSearchUserRequest
} from '../shared/data-access/residential/residential.types';
import {
	CreatorPortalService,
	Selection,
	Property
} from './service/creator-portal.service';
import { NoLinkedAgencyComponent } from './ui/no-linked-agency/no-linked-agency.component';
import { JobSubmittedComponent } from '../shared/ui/modals/job-submitted/job-submitted.component';
import { DeleteWithReasonComponent } from '../shared/ui/modals/delete-with-reason/delete-with-reason.component';

@Component({
	selector: 'fixify-creator-portal',
	standalone: true,
	providers: [CreatorPortalService],
	imports: [
		NgClass,
		SearchLinkedPropertyModalComponent,
		RequestCardComponent,
		SearchingComponent,
		CreatorStartComponent,
		LinkedAddressesComponent,
		NewJobComponent,
		NoLinkedAddressesComponent,
		JobDetailsComponent,
		AsyncPipe,
		NoLinkedAgencyComponent,
		JobSubmittedComponent,
		DeleteWithReasonComponent
	],
	templateUrl: './creator-portal.component.html',
	styleUrl: './creator-portal.component.css'
})
export class CreatorPortalComponent implements OnDestroy {
	creatorPortalService = inject(CreatorPortalService);
	private destroy$ = new Subject<void>();
	agencyId = this.creatorPortalService.agencyId;
	propertyId = this.creatorPortalService.propertyId;

	agency = this.creatorPortalService.agency;

	ngOnDestroy(): void {
		this.destroy$.next();
	}

	changeAddresses() {
		this.creatorPortalService.showLinkedProperties.set(true);
		this.creatorPortalService.createJob.set(false);
	}

	changeCardSelection(value: Selection) {
		this.creatorPortalService.selection.set(value);
	}

	openSearchModal() {
		this.creatorPortalService.isSearchModalOpen.set(true);
	}

	viewCreatedJob(view: boolean) {
		if (view) this.creatorPortalService.reset();
	}

	createAnotherJob(create: boolean) {
		if (create) this.creatorPortalService.reset();
	}

	createJob(createJob: createAJobRequest) {
		if (this.agencyId === null) {
			console.error('Agency ID is not set');
			return;
		}

		this.creatorPortalService.createAJob(createJob);
	}

	createAProperty(createProperty: createAPropertyRequest) {
		if (this.agencyId === null) {
			console.error('Agency ID is not set');
			return;
		}

		this.creatorPortalService.createAProperty(createProperty);
	}

	setLinkedProperty(property: Property | null) {
		if (property === null) {
			return;
		}
		this.creatorPortalService.selectProperty(property);
		this.creatorPortalService.showLinkedProperties.set(false);
		this.creatorPortalService.createJob.set(true);
	}

	async search(searchSettings: { mode: string; value: string }) {
		if (this.agencyId === null) {
			console.error('Agency ID is not set');
			return;
		}

		const searchCriteria: residentialSearchUserRequest = {};
		if (searchSettings.mode === 'phone') {
			searchCriteria.mobileNumber = searchSettings.value;
		} else {
			searchCriteria.email = searchSettings.value;
		}

		this.creatorPortalService.fetchProperties(searchCriteria);
	}
}
