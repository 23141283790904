import { Component, output } from '@angular/core';

@Component({
	selector: 'fixify-no-locations',
	standalone: true,
	imports: [],
	templateUrl: './no-locations.component.html',
	styleUrl: './no-locations.component.css'
})
export class NoLocationsComponent {
	addLocation = output<boolean>();

	addLocationClicked() {
		this.addLocation.emit(true);
	}
}
