import { NgClass } from '@angular/common';
import { Component, Input, input } from '@angular/core';
import {
	AbstractControl,
	FormControl,
	FormGroup,
	ReactiveFormsModule,
	Validators
} from '@angular/forms';
import CountryList, { Country } from 'country-list-with-dial-code-and-flag';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { Subject, takeUntil } from 'rxjs';
import { getCountryCodeFromNumber } from '../../utils/phone';
const PHONE_LIB = PhoneNumberUtil.getInstance();
const DEFAULT_COUNTRY = 'ZA';

@Component({
	selector: 'fixify-phone-input',
	standalone: true,
	imports: [ReactiveFormsModule, NgClass],
	templateUrl: './phone-input.component.html',
	styleUrl: './phone-input.component.css'
})
export class PhoneInputComponent {
	@Input() control!: AbstractControl;
	showErrorMessage = input<boolean>(true);
	showRequiredStar = input<boolean>(false);
	disabled = input<boolean>(false);
	placeholder = input<string>('Enter phone number');
	label = input<string>('');
	id = input<string>('phone-input');
	countries: Array<Country> = [];
	selectedCountry = CountryList.findOneByCountryCode(DEFAULT_COUNTRY);
	ngControl!: FormControl;
	destroy$ = new Subject<void>();
	countryFormGroup: FormGroup;

	constructor() {
		this.countryFormGroup = new FormGroup({
			country: new FormControl('', [Validators.required])
		});
	}

	ngOnInit() {
		this.ngControl = this.control as FormControl;

		const countryCode = getCountryCodeFromNumber(this.ngControl.value);

		this.selectedCountry = CountryList.findOneByCountryCode(
			countryCode ?? DEFAULT_COUNTRY
		);

		this.countryFormGroup.controls['country'].setValue(
			this.selectedCountry?.code ?? ''
		);

		this.countries = CountryList.getAll();

		this.countryFormGroup.controls['country'].valueChanges
			.pipe(takeUntil(this.destroy$))
			.subscribe((countryCode: string) => {
				this.selectedCountry =
					CountryList.findOneByCountryCode(countryCode);

				const mobileNumber = this.ngControl.value ?? '';
				this.validateMobileNumber(mobileNumber);
			});

		this.ngControl.valueChanges
			.pipe(takeUntil(this.destroy$))
			.subscribe((mobileNumber: string) => {
				this.validateMobileNumber(mobileNumber);
			});
	}

	hasError() {
		return this.ngControl?.errors && this.ngControl?.dirty;
	}

	validateMobileNumber(mobileNumber: string) {
		let valid = true;
		try {
			const region = this.selectedCountry?.code ?? 'ZA';
			const number = PHONE_LIB.parse(`${mobileNumber}`, region);
			if (!PHONE_LIB.isValidNumber(number)) {
				throw new Error('Invalid number');
			}

			valid = PHONE_LIB.isValidNumberForRegion(number, region);
		} catch (error) {
			valid = false;
		}
		if (!valid) {
			this.ngControl.setErrors({
				invalid: true
			});
		} else {
			this.ngControl.setErrors(null);
		}
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
