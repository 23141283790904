@if (isModalOpen()) {
	<div
		class="fixed z-50 inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
	>
		<div
			id="delete-modal"
			(click)="hideModal()"
			class="fixed top-0 left-0 right-0 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full flex items-center justify-center"
		>
			<div
				(click)="$event.stopPropagation()"
				class="relative w-full max-w-md max-h-full"
			>
				<div class="relative bg-white rounded-lg shadow px-8 pt-5 pb-8">
					<div class="flex justify-center mb-6">
						<img src="assets/svg/social.svg" />
					</div>

					<div class="flex items-start justify-between rounded-t">
						<p class="text-xl font-semibold text-gray-900">
							Job sumbitted!
						</p>
					</div>
					<div class="pt-1 pb-6">
						<p class="text-base leading-relaxed text-gray-500">
							Please note that all common property jobs may take
							up to 5 business days to process while we contact
							the body corporate. Your patience is appreciated.
						</p>
					</div>
					<div
						class="flex items-center justify-end space-x-2 border-gray-200 rounded-b"
					>
						<button
							(click)="createClicked()"
							type="button"
							class="btn-outline border-gray-200"
						>
							Create another job
						</button>

						<button
							(click)="viewClicked()"
							type="button"
							class="btn-primary"
						>
							View my job
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
}

