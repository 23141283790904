<router-outlet modalHost />

@if (toasts | async) {
	<div class="z-50 top-0 right-0 fixed mt-8 mr-8 w-72">
		@for (toast of toasts | async; track $index) {
			<aside>
				<app-toast
					[uniqueId]="toast.id"
					[message]="toast.message"
					[type]="toast.type"
				></app-toast>
			</aside>
		}
	</div>
}
