<div class="flex w-full mt-4 pb-8 justify-center">
	<div class="max-w-[800px] w-full">
		<div id="top-nav-bar">
			<div class="flex items-center gap-2 justify-between">
				<div class="flex items-center gap-3 cursor-pointer">
					<img
						class="h-6 w-6"
						src="assets/svg/arrow-left-sharp.svg"
					/>
					<p class="text-gray-900 font-medium text-3xl">Job #12345</p>
					<fixify-status-pill color="purple"
						>Ongoing</fixify-status-pill
					>
				</div>
				<div class="flex">
					<button class="btn btn-outline danger small mr-3">
						Cancel job
					</button>
					<div
						[ngClass]="{
							'opacity-50 !cursor-default': !canGoPrevious()
						}"
						class="rounded-lg border-gray-200 border-2 p-1 mr-2 cursor-pointer"
					>
						<img
							class="rotate-180"
							src="assets/svg/chevron-right.svg"
						/>
					</div>
					<div
						[ngClass]="{
							'opacity-50 !cursor-default': !canGoNext()
						}"
						class="rounded-lg border-gray-200 border-2 p-1 cursor-pointer"
					>
						<img class="" src="assets/svg/chevron-right.svg" />
					</div>
				</div>
			</div>
			<div>
				<p class="ml-9 mt-0.5 text-sm font-normal text-gray-500">
					Created on 12 May, 2024 at 10:01 pm
				</p>
			</div>
		</div>
		<div class="mt-6">
			<fixify-status-banner
				title="Site visit date scheduled: Tuesday, 21 May - 14:00"
				subtitle="Your site visit is scheduled for Tuesday, 21 May at 2:00 pm.
				Please ensure you are available."
				primaryAction="Reject"
				secondaryAction="Accept"
				status="To do"
				statusColor="bright-orange"
				iconSrc="assets/svg/calendar-primary.svg"
				(primaryClicked)="primaryStatusClicked()"
				(secondaryClicked)="secondaryStatusClicked()"
			>
				<p>Show quotes or some other shit</p>
			</fixify-status-banner>
		</div>
		<div class="mt-4 flex gap-4">
			<fixify-property-info-card
				class="w-full"
				iconSrc="assets/svg/house.svg"
				title="Property address"
				text="30 Andiswa St, Fairdale, Cape Town, 7100"
			></fixify-property-info-card>
			<fixify-property-info-card
				class="w-full"
				iconSrc="assets/svg/common-property.svg"
				title="Common property"
				text="The front, west-facing garden-area"
			></fixify-property-info-card>
		</div>
		<div class="mt-4 flex gap-4">
			<div class="w-2/3 h-min">
				<div class="rounded border-2 border-gray-100 pb-4">
					<div class="p-4">
						<p class="text-base text-gray-900 font-semibold">
							Job details
						</p>
					</div>
					<fixify-divider class="!bg-gray-200 mb-4"></fixify-divider>
					<fixify-job-detail
						title="Category"
						iconSrc="assets/svg/tools-gray.svg"
					>
						<p class="text-sm font-normal text-gray-900">
							Plumbing
						</p>
					</fixify-job-detail>
					<fixify-job-detail
						title="Site cisit date & time"
						iconSrc="assets/svg/calendar-plus-gray.svg"
					>
						<p class="text-sm font-normal text-gray-900">
							21 May · 14:00
						</p>
					</fixify-job-detail>
					<fixify-job-detail
						title="Job description"
						iconSrc="assets/svg/pencil-line-gray.svg"
					>
						<p class="text-sm font-normal text-gray-900">
							I would like to move the bath in the bathroom and
							need a plumber to inspect the pipes as this is an
							old house.
						</p>
					</fixify-job-detail>
					<fixify-job-detail
						title="Extra notes"
						iconSrc="assets/svg/sticker-square-gray.svg"
					>
						<p class="text-sm font-normal text-gray-900">
							Call 12345678 and use the gate on the right.
						</p>
					</fixify-job-detail>
					<fixify-job-detail
						title="Your photos"
						iconSrc="assets/svg/camera-gray.svg"
					>
						<div class="flex flex-wrap gap-2">
							@for (imgUrl of imgUrls(); track $index) {
								<div class="w-20 h-20">
									<img
										class="w-full h-full object-cover rounded-lg"
										[src]="imgUrl"
									/>
								</div>
							}
						</div>
					</fixify-job-detail>
				</div>
				<div class="rounded border-2 border-gray-100 mt-4">
					<div class="p-4">
						<p class="text-base text-gray-900 font-semibold">
							Documents
						</p>
					</div>
					<fixify-divider class="!bg-gray-200 mb-4"></fixify-divider>
					<fixify-simple-nav-bar
						[(navItems)]="simpleNavItems"
						(onItem)="navItemChanged($event)"
					></fixify-simple-nav-bar>
					<div class="mt-2">
						@for (quote of quoteItems(); track $index) {
							<fixify-quote-list-item
								[quoteItem]="quote"
							></fixify-quote-list-item>
							@if (quoteItems().length - 1 !== $index) {
								<fixify-divider
									class="!bg-gray-100"
								></fixify-divider>
							}
						}
					</div>
				</div>
			</div>
			<div class="w-1/3 flex flex-col gap-6">
				<div class="rounded border-2 border-gray-100">
					<p class="p-4 text-base text-gray-900 font-semibold">
						Service provider
					</p>
					<fixify-divider class="!bg-gray-200"></fixify-divider>
					<div class="p-4 flex justify-between gap-2">
						<p class="text-sm font-normal text-gray-500">Name</p>
						<p
							class="leading-tight text-sm font-normal text-gray-900"
						>
							John Doe
						</p>
					</div>
					<fixify-divider class="!bg-gray-200"></fixify-divider>
					<div class="p-4 flex justify-between gap-2">
						<p class="text-sm font-normal text-gray-500">Mobile</p>
						<p
							class="leading-tight text-sm font-normal text-gray-900"
						>
							0123456789
						</p>
					</div>
					<fixify-divider class="!bg-gray-200"></fixify-divider>
					<div class="p-4 flex items-center justify-between gap-2">
						<p class="text-sm font-normal text-gray-500">Email</p>
						<p
							class="break-all text-right leading-tight text-sm font-normal text-gray-900"
						>
							someemailaddress.com
						</p>
					</div>
				</div>
				<div class="rounded border-2 border-gray-100">
					<div class="p-4 flex justify-between items-center gap-4">
						<p class="text-base text-gray-900 font-semibold">
							Tenant
						</p>
						<div class="px-3 py-2 cursor-pointer">
							<p class="text-primary-500">Edit</p>
						</div>
					</div>
					<fixify-divider class="!bg-gray-200"></fixify-divider>
					<div class="p-4 flex justify-between gap-2">
						<p class="text-sm font-normal text-gray-500">Name</p>
						<p
							class="leading-tight text-sm font-normal text-gray-900"
						>
							John Doe
						</p>
					</div>
					<fixify-divider class="!bg-gray-200"></fixify-divider>
					<div class="p-4 flex justify-between gap-2">
						<p class="text-sm font-normal text-gray-500">Mobile</p>
						<p
							class="leading-tight text-sm font-normal text-gray-900"
						>
							0123456789
						</p>
					</div>
					<fixify-divider class="!bg-gray-200"></fixify-divider>
					<div class="p-4 flex items-center justify-between gap-4">
						<p class="text-sm font-normal text-gray-500">Email</p>
						<p
							class="break-all text-right leading-tight text-sm font-normal text-gray-900"
						>
							someemailaddress.com
						</p>
					</div>
				</div>
				<div class="rounded border-2 border-gray-100">
					<div class="p-4">
						<p class="text-base text-gray-900 font-semibold">
							Landlord (You)
						</p>
					</div>
					<fixify-divider class="!bg-gray-200"></fixify-divider>
					<div class="p-4 flex justify-between gap-2">
						<p class="text-sm font-normal text-gray-500">Name</p>
						<p
							class="leading-tight text-sm font-normal text-gray-900"
						>
							John Doe
						</p>
					</div>
					<fixify-divider class="!bg-gray-200"></fixify-divider>
					<div class="p-4 flex justify-between gap-2">
						<p class="text-sm font-normal text-gray-500">Mobile</p>
						<p
							class="leading-tight text-sm font-normal text-gray-900"
						>
							0123456789
						</p>
					</div>
					<fixify-divider class="!bg-gray-200"></fixify-divider>
					<div class="p-4 flex items-center justify-between gap-4">
						<p class="text-sm font-normal text-gray-500">Email</p>
						<p
							class="break-all text-right leading-tight text-sm font-normal text-gray-900"
						>
							someemailaddress.com
						</p>
					</div>
				</div>
				<div class="rounded border-2 border-gray-100">
					<div class="p-4">
						<p class="text-base text-gray-900 font-semibold">
							Agency
						</p>
					</div>
					<fixify-divider class="!bg-gray-200"></fixify-divider>
					<div class="p-4 flex justify-between gap-2">
						<p class="text-sm font-normal text-gray-500">Agency</p>
						<p
							class="leading-tight text-sm font-normal text-gray-900"
						>
							WatchProp
						</p>
					</div>
					<fixify-divider class="!bg-gray-200"></fixify-divider>
					<div class="p-4 flex justify-between gap-2">
						<p class="text-sm font-normal text-gray-500">Agent</p>
						<p
							class="leading-tight text-sm font-normal text-gray-900"
						>
							Jonathan de Beer
						</p>
					</div>
					<fixify-divider class="!bg-gray-200"></fixify-divider>
					<div class="p-4 flex items-center justify-between gap-4">
						<p class="text-sm font-normal text-gray-500">Mobile</p>
						<p
							class="break-all text-right leading-tight text-sm font-normal text-gray-900"
						>
							0123456789
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

