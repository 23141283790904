<div
	[ngClass]="{
		'border-primary-500': selected(),
		'!border-red-700': error()
	}"
	class="flex p-4 rounded-lg border-2 border-gray-100 cursor-pointer"
>
	<ng-content></ng-content>
</div>

