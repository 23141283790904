<div class="flex w-full mt-16 justify-center">
	<div class="max-w-[600px] w-full px-8">
		<div (click)="back()" class="flex my-2 cursor-pointer">
			<img class="mr-2" src="assets/svg/arrow-left.svg" />
			<p class="text-sm font-medium text-gray-800">Back</p>
		</div>
		<p class="my-6 text-3xl font-medium text-gray-900">Linked addresses</p>
		<p class="mb-8 text-xl font-medium text-gray-900">
			Select your address to continue
		</p>
		<div class="flex flex-col gap-4 mb-8 max-h-[300px] overflow-y-scroll">
			@for (linkedProperty of linkedProperties(); track $index) {
				<div
					[ngClass]="{
						'border-primary-500': isSelected(linkedProperty.id)
					}"
					(click)="selectAddress(linkedProperty)"
					class="cursor-pointer p-6 flex items-center justify-between gap-3 border-2 border-gray-100 rounded-lg"
				>
					<p class="text-base font-normal text-gray-900">
						{{ linkedProperty.address.formatted }}
					</p>
					<input
						[checked]="isSelected(linkedProperty.id)"
						[value]="linkedProperty.id"
						type="radio"
						class="w-4 h-4 text-primary-500 bg-gray-100 border-gray-300 focus:ring-transparent"
					/>
				</div>
			}
		</div>
		<div class="mt-4 flex gap-3">
			<button (click)="back()" class="btn-outline w-full">
				Cancel request
			</button>
			<button
				(click)="continue()"
				[disabled]="!selectedProperty()"
				class="btn-primary w-full"
			>
				Continue
			</button>
		</div>
	</div>
</div>

