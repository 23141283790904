<div class="flex w-full h-screen bg-white">
	<div class="flex-row flex w-full md:w-2/3 h-screen">
		<div class="flex-col flex w-0 sm:w-1/4 h-screen p-0 sm:p-6">
			<img
				src="assets/svg/logo.svg"
				alt="logo"
				class="object-contain h-12 w-32"
			/>
		</div>
		<div
			class="flex-col flex w-full sm:w-2/4 h-screen justify-center px-16 sm:p-0"
		>
			<router-outlet></router-outlet>
		</div>
		<div class="flex-col flex w-0 sm:w-1/4 h-screen"></div>
	</div>
	<div class="flex-col flex w-0 md:w-1/3 h-screen">
		<img
			src="assets/svg/authGraphic.svg"
			alt="logo"
			class="object-cover h-full"
		/>
	</div>
</div>
