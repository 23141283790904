import {
	Component,
	effect,
	inject,
	input,
	model,
	OnDestroy,
	Signal
} from '@angular/core';
import { AsyncPipe, NgClass } from '@angular/common';
import { DividerComponent } from '../../../../../shared/ui/divider/divider.component';
import { TextInputComponent } from '../../../../../shared/ui/text-input/text-input.component';
import { AlertComponent } from '../../../../../shared/ui/alert/alert.component';
import {
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	ReactiveFormsModule,
	Validators
} from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { atLeastOneActiveTask } from '../../pipes/one-task-active.pipe';
import {
	CategoryForm,
	ITaskCheckbox
} from '../edit-category/edit-category.component';
import { ServiceProviderService } from '../../../../../shared/data-access/service-provider/service-provider.service';
import { CategoryFormComponent } from '../category-form/category-form.component';
import {
	Category,
	UploadedDocuments,
	TransformedCategories
} from '../../../../../shared/data-access/service-provider/service-provider.types';

@Component({
	selector: 'fixify-new-categories',
	standalone: true,
	imports: [
		NgClass,
		AsyncPipe,
		DividerComponent,
		TextInputComponent,
		AlertComponent,
		ReactiveFormsModule,
		CategoryFormComponent
	],
	templateUrl: './new-categories.component.html',
	styleUrl: './new-categories.component.css'
})
export class NewCategoriesComponent implements OnDestroy {
	serviceProviderService = inject(ServiceProviderService);
	unavailableCategoryIds = input.required<Array<string>>();
	categories = input.required<Array<Category>>();
	save = input.required<Signal<boolean>>();
	formValid = model(false);
	noCategories = model(false);
	destroy$ = new Subject<void>();

	categoriesForm: FormArray<FormGroup<CategoryForm>>;
	constructor(private formBuilder: FormBuilder) {
		this.categoriesForm = this.formBuilder.array<FormGroup<CategoryForm>>(
			[]
		);

		this.categoriesForm.statusChanges
			.pipe(takeUntil(this.destroy$))
			.subscribe(status => {
				this.formValid.set(status === 'VALID');
			});
		this.addCategory();

		effect(() => {
			if (this.save()()) {
				this.saveNewCategories();
			}
		});
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}

	addCategory() {
		this.categoriesForm.push(this.createCategoryFormGroup());
	}

	allFormGroups() {
		return this.categoriesForm.controls as Array<FormGroup<CategoryForm>>;
	}

	allTasksForFormGroup(formGroup: FormGroup<CategoryForm>) {
		return formGroup.controls.tasks as FormArray<FormGroup<ITaskCheckbox>>;
	}

	createCategoryFormGroup(): FormGroup {
		const formGroup: FormGroup<CategoryForm> =
			this.formBuilder.group<CategoryForm>({
				id: new FormControl<string | null>(null),
				name: new FormControl<string | null>('', Validators.required),
				tasks: new FormArray<FormGroup<ITaskCheckbox>>(
					[],
					[atLeastOneActiveTask()]
				),
				supportingDocuments: new FormControl<Array<UploadedDocuments>>(
					[]
				)
			});

		formGroup.controls.name.valueChanges
			.pipe(takeUntil(this.destroy$))
			.subscribe(newValue => {
				formGroup.controls.id.setValue(
					this.categories().find(
						category => category.name === newValue
					)?.id ?? null
				);
				const tasks = this.retrieveTasks(newValue ?? '');
				formGroup.controls.tasks.clear();
				tasks.forEach(task => {
					const taskFormGroup = this.formBuilder.group<ITaskCheckbox>(
						{
							selected: new FormControl<boolean>(false),
							name: new FormControl<string>(task)
						}
					);

					formGroup.controls.tasks.push(taskFormGroup);
				});
			});

		return formGroup;
	}

	saveNewCategories() {
		if (this.formValid()) {
			const categories = this.categoriesForm.controls
				.map(formGroup => {
					const tasks = formGroup.controls.tasks.controls
						.map(taskGroup => {
							return taskGroup.controls.selected.value
								? taskGroup.controls.name.value
								: null;
						})
						.filter(task => task !== null) as Array<string>;

					return {
						id: formGroup.controls.id.value,
						name: formGroup.controls.name.value ?? '',
						tasks: tasks,
						supportingDocuments:
							formGroup.controls.supportingDocuments.value?.map(
								(doc: UploadedDocuments) => doc.url
							) ?? []
					};
				})
				.filter(category => category.id !== null)
				.reduce<TransformedCategories>((acc, category) => {
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					const { id, name, ...otherFields } = category;
					if (id !== null) {
						acc[id] = otherFields;
					}
					return acc;
				}, {});

			this.serviceProviderService.addCategories(categories);
		}
		this.categoriesForm.clear();
		this.noCategories.set(true);
	}

	removeCategory(index: number) {
		this.categoriesForm.removeAt(index);

		if (this.categoriesForm.length === 0) {
			this.noCategories.set(true);
		}
	}

	retrieveTasks(cagtegoryName: string) {
		return (
			this.categories()?.find(category => category.name === cagtegoryName)
				?.tasks ?? []
		);
	}

	categoryAlreadyUsedIds() {
		return [
			...this.unavailableCategoryIds(),
			...this.categoriesForm.controls
				.map(formGroup => formGroup.controls.id.value)
				.filter(id => id !== null)
		] as Array<string>;
	}

	categoryAlreadyUsed(categoryId: string) {
		const categoryName = this.categories()?.find(
			category => category.id === categoryId
		)?.name;

		return (
			this.categoriesForm.controls.some(
				formGroup => formGroup.controls.name.value === categoryName
			) || this.unavailableCategoryIds().includes(categoryId)
		);
	}

	allCategoriesInUse() {
		return (
			this.categoriesForm.controls.length ===
				(this.categories()?.length ?? 0) ||
			(this.unavailableCategoryIds().length > 0 &&
				this.unavailableCategoryIds().every(id => {
					return this.categories().some(
						category => category.id === id
					);
				}))
		);
	}
}
