import { AbstractControl, FormArray, ValidatorFn } from '@angular/forms';

export function atLeastOneActiveTask(): ValidatorFn {
	return (formArray: AbstractControl): { [key: string]: unknown } | null => {
		if (!(formArray instanceof FormArray)) {
			throw new Error(
				'atLeastOneActiveTask validator can only be applied to a FormArray'
			);
		}

		const hasActiveTask = formArray.controls.some(
			control => control.get('selected')?.value === true
		);

		return hasActiveTask ? null : { noActiveTask: true };
	};
}
