import { Component } from '@angular/core';

@Component({
	selector: 'fixify-no-linked-agency',
	standalone: true,
	imports: [],
	templateUrl: './no-linked-agency.component.html',
	styleUrl: './no-linked-agency.component.css'
})
export class NoLinkedAgencyComponent {}
