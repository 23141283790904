import { Component, input } from '@angular/core';

@Component({
	selector: 'fixify-icon',
	standalone: true,
	imports: [],
	templateUrl: './icon.component.html',
	styleUrl: './icon.component.css'
})
export class IconComponent {
	name = input.required();
	symbol = input('');

	symbolName() {
		return this.symbol() ? this.symbol() : this.name();
	}
}
