import { Component, model, output } from '@angular/core';
import { RequestCardComponent } from '../request-card/request-card.component';
import { Selection } from '../../service/creator-portal.service';

@Component({
	selector: 'fixify-creator-start',
	standalone: true,
	imports: [RequestCardComponent],
	templateUrl: './creator-start.component.html',
	styleUrl: './creator-start.component.css'
})
export class CreatorStartComponent {
	selection = model<Selection>(null);
	openSearchModal = output<boolean>();

	changeCardSelection(value: Selection) {
		this.selection.set(value);
	}

	continue() {
		this.openSearchModal.emit(true);
	}

	isSelected(value: Selection) {
		return this.selection() === value;
	}
}
