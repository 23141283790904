import { Component } from '@angular/core';
import { IconComponent } from '../../shared/ui/icon/icon.component';
import { DashboardCardComponent } from '../../shared/ui/dashboard-card/dashboard-card.component';
import { PropertyTableComponent } from './ui/tables/property-table/property-table.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	selector: 'app-properties',
	standalone: true,
	imports: [IconComponent, DashboardCardComponent, PropertyTableComponent],
	templateUrl: './properties.component.html',
	styleUrl: './properties.component.css'
})
export class PropertiesComponent {
	constructor(
		private router: Router,
		private route: ActivatedRoute
	) {}

	addProperty() {
		this.router.navigate(['add'], { relativeTo: this.route });
	}
}
