<aside
	[ngClass]="{ 'sm:w-72': expanded() | async }"
	class="sticky top-0 bg-stone h-screen flex flex-col w-20 sm:transition-all duration-100 pb-4"
>
	<div class="w-full rounded flex justify-center sm:justify-start">
		<!-- Image for small screens (sm) and below -->
		<img
			src="assets/svg/logo.svg"
			class="sm:block hidden h-16 ml-4 mt-4 mb-6"
			alt="Logo Small"
		/>

		<!-- Image for screens larger than sm -->
		<img
			src="assets/svg/logo-small.svg"
			class="sm:hidden h-10 m-4"
			alt="Logo"
		/>
	</div>
	<ul>
		@for (item of topItems(); track $index) {
			<li>
				<a
					[routerLink]="item.route"
					[ngClass]="{
						'text-primary-500 border-l-2 border-primary-500':
							item.active
					}"
					routerLinkActive="bg-white"
					ariaCurrentWhenActive="page"
					class="text-base justify-center sm:justify-start font-medium flex py-4 px-6 text-gray-500 hover:bg-white cursor-pointer"
				>
					<img
						[src]="
							item.active
								? 'assets/svg/' + item.icon + '-active.svg'
								: 'assets/svg/' + item.icon + '.svg'
						"
						alt="img"
						class="h-6 w-6 sm:mr-3 mr-0"
					/>
					@if (expanded() | async) {
						<p class="hidden sm:block">{{ item.name }}</p>
					}
				</a>
			</li>
		}
	</ul>
	<div class="flex-col flex-grow m-4"></div>
	<ul>
		@for (item of bottomItems(); track $index) {
			<li>
				<a
					[routerLink]="item.route"
					[ngClass]="{
						'text-primary-500 border-l-2 border-primary-500':
							item.active
					}"
					routerLinkActive="bg-white"
					ariaCurrentWhenActive="page"
					class="text-base justify-center sm:justify-start font-medium flex py-4 px-6 text-gray-500 hover:bg-white cursor-pointer"
				>
					<img
						[src]="
							item.active
								? 'assets/svg/' + item.icon + '-active.svg'
								: 'assets/svg/' + item.icon + '.svg'
						"
						alt="img"
						class="h-6 w-6 sm:mr-3 mr-0"
					/>
					@if (expanded() | async) {
						<p class="hidden sm:block">{{ item.name }}</p>
					}
				</a>
			</li>
		}
	</ul>
</aside>
