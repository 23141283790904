<div class="flex mt-16 w-full justify-center">
	<div class="w-[772px]">
		<div class="mx-32 text-center mb-16">
			<p
				class="px-9 leading-tight text-5xl font-medium text-gray-900 mb-2"
			>
				Streamline your home maintenance
			</p>
			<p class="text-lg font-normal text-gray-700">
				Manage all your home maintenance needs in one place.
			</p>
		</div>
		<div class="gap-4 flex px-8 w-full mb-16">
			<fixify-request-card
				(changeCardSelect)="changeCardSelection('new')"
				imgUrl="assets/svg/new-job-request.svg"
				[selected]="isSelected('new')"
				value="new"
				text="I’m looking to request a new maintenance job"
			></fixify-request-card>
			<fixify-request-card
				(changeCardSelect)="changeCardSelection('existing')"
				imgUrl="assets/svg/existing-job-request.svg"
				[selected]="isSelected('existing')"
				value="existing"
				text="I’m looking to track an existing maintenance job"
			></fixify-request-card>
		</div>
		<div class="w-full flex justify-center">
			<button
				(click)="continue()"
				[disabled]="!selection()"
				class="btn-primary"
			>
				Continue
			</button>
		</div>
	</div>
</div>

