@if (otpScreen) {
	<app-otp
		[loginMode]="loginMode"
		[userType]="userType()"
		[emailOrPhoneNumber]="emailOrPhoneNumber"
		[secondsRemaining]="secondsRemaining()"
		(goBackChange)="backToLogin()"
		(resendOTPChange)="startCooldown()"
	></app-otp>
}

<!-- Hide only, so that no re-render is necessary and state is kept -->
<app-login
	[ngClass]="{ hidden: otpScreen }"
	[(loginMode)]="loginMode"
	[userType]="userType()"
	[(continueToOtp)]="otpScreen"
	[(emailOrPhoneNumber)]="emailOrPhoneNumber"
	[secondsRemaining]="secondsRemaining()"
></app-login>
