import { Component, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { BreadcrumbService } from '../../../shared/data-access/breadcrumb.service';
import { BreadcrumbComponent } from '../../../shared/breadcrumb/breadcrumb.component';
import { AsyncPipe, NgClass } from '@angular/common';
import { TextInputComponent } from '../../../shared/ui/text-input/text-input.component';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Subject, takeUntil, takeWhile } from 'rxjs';
import CountryList, { Country } from 'country-list-with-dial-code-and-flag';
import { ServiceProviderService } from '../../../shared/data-access/service-provider/service-provider.service';
import {
	deleteObject,
	getDownloadURL,
	ref,
	Storage,
	uploadBytesResumable
} from '@angular/fire/storage';
import { CompanyDetails } from '../../../shared/data-access/service-provider/service-provider.types';

interface UploadState {
	percentage: number;
	downloadUrl?: string;
}

@Component({
	selector: 'app-company-info',
	standalone: true,
	imports: [
		BreadcrumbComponent,
		AsyncPipe,
		TextInputComponent,
		ReactiveFormsModule,
		NgClass
	],
	templateUrl: './company-info.component.html',
	styleUrl: './company-info.component.css'
})
export class CompanyInfoComponent implements OnInit, OnDestroy {
	companyDetailsForm: FormGroup;
	destroy$ = new Subject<void>();
	formTouched = signal<boolean>(false);
	formValid = signal<boolean>(false);
	countries = CountryList.getAll().map((country: Country) => country.name);

	serviceProviderService = inject(ServiceProviderService);
	storage = inject(Storage);
	serviceProviderId: string | null = null;

	selectedFile: File | null = null;
	uploadState: UploadState = { percentage: 0 };
	companyLogoUrl = signal<string | null>(null);
	isSubmitting = signal<boolean>(false);

	accountCompletion = signal<boolean>(false);

	constructor(public breadcrumbService: BreadcrumbService) {
		this.companyDetailsForm = new FormGroup({
			companyName: new FormControl(''),
			registrationNumber: new FormControl(''),
			vatNumber: new FormControl(''),
			entityType: new FormControl(''),
			province: new FormControl(''),
			country: new FormControl(''),
			registeredName: new FormControl(''),
			tradingName: new FormControl('')
		});
	}

	ngOnInit() {
		this.breadcrumbService.setBreadcrumbs([
			{ label: 'Account', url: '/service-provider/account' },
			{ label: 'Company Details' }
		]);

		this.companyDetailsForm.statusChanges
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.formTouched.set(this.companyDetailsForm.dirty);
				this.formValid.set(this.companyDetailsForm.valid);
			});

		this.serviceProviderService
			.serviceProvider()
			.pipe(takeUntil(this.destroy$))
			.subscribe(sp => {
				if (sp?.companyDetails) {
					const companyDetails: CompanyDetails = {
						...sp.companyDetails
					};

					Object.entries(sp.companyDetails).forEach(
						([key, value]) => {
							companyDetails[key as keyof CompanyDetails] =
								value ?? '';
						}
					);
					this.serviceProviderId = sp.id;
					if (sp.companyDetails.companyLogo)
						this.companyLogoUrl.set(sp.companyDetails.companyLogo);

					this.companyDetailsForm.patchValue(companyDetails);

					this.companyDetailsForm.markAsPristine();
					this.companyDetailsForm.markAsUntouched();
				}
			});

		this.serviceProviderService
			.accountCompletion()
			.pipe(takeUntil(this.destroy$))
			.subscribe(acc => {
				this.accountCompletion.set(acc.companyDetails ?? false);
			});

		this.serviceProviderService
			.isSubmitting()
			.pipe(takeUntil(this.destroy$))
			.subscribe(isSubmitting => {
				this.isSubmitting.set(isSubmitting);
			});
	}

	onSubmit() {
		if (this.companyDetailsForm.valid && this.companyDetailsForm.dirty) {
			const companyDetails: CompanyDetails =
				this.companyDetailsForm.value;
			this.serviceProviderService.updateCompany(companyDetails);
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onFileSelected(event: any): void {
		this.selectedFile = event.target.files[0] as File;
		this.uploadMedia(this.selectedFile);
	}

	async uploadMedia(file: File): Promise<void> {
		if (!this.serviceProviderId) {
			throw new Error('Service Provider ID not set');
		}
		const filePath = `serviceProviders/${this.serviceProviderId}/companyDetails/${file.name}`;
		const storageRef = ref(this.storage, filePath);
		const uploadTask = uploadBytesResumable(storageRef, file);

		if (this.companyLogoUrl()) {
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			await this.deleteMedia(this.companyLogoUrl()!).catch(_ => _);
			this.companyLogoUrl.set(null);
		}

		uploadTask.on(
			'state_changed',
			snapshot => {
				const progress =
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				this.uploadState = { percentage: Math.round(progress) };
			},
			error => console.error('Upload failed:', error),
			() => {
				getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
					this.serviceProviderService.updateCompanyLogo(downloadURL);
					this.serviceProviderService
						.isSubmittingMedia()
						.pipe(
							takeWhile(value => !value, true),
							takeUntil(this.destroy$)
						)
						.subscribe(value => {
							if (value) {
								this.companyLogoUrl.set(downloadURL);
								this.uploadState = {
									percentage: 100,
									downloadUrl: downloadURL
								};
							}
						});
				});
			}
		);
	}

	async deleteMedia(downloadURL: string) {
		const storageRef = ref(this.storage, downloadURL);
		await deleteObject(storageRef);
	}

	ngOnDestroy(): void {
		this.destroy$.next();
	}
}
