import { Component } from '@angular/core';
import { ModalComponent } from '../modal.service';
import { environment } from '../../../../../environments/environment';
import { ModalCardComponent } from '../../modal-card/modal-card.component';

@Component({
	selector: 'app-accept-terms',
	standalone: true,
	imports: [ModalCardComponent],
	templateUrl: './accept-terms.component.html',
	styleUrl: './accept-terms.component.css'
})
export class AcceptTermsComponent extends ModalComponent<void, boolean> {
	termsLink = environment.termsAndConditions;

	override setData(): void {
		throw new Error('Method not implemented.');
	}

	signOutClicked() {
		this.close(false);
	}

	acceptClicked() {
		this.close(true);
	}
}
