<div class="max-w px-12 md:px-22 xl:px-44">
	<app-breadcrumb
		[items]="this.breadcrumbService.breadcrumbs$ | async"
		public
	></app-breadcrumb>

	<div class="flex justify-start items-center">
		<div class="grow flex items-center">
			<h1 class="font-medium text-2xl leading-tight pr-2 py-2.5">
				Company details
			</h1>
			@if (!accountCompletion()) {
				<span
					class="inline-block bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded"
					>Incomplete</span
				>
			}
		</div>
		<div>
			<button
				(click)="onSubmit()"
				[disabled]="!formValid() || !formTouched() || isSubmitting()"
				[ngClass]="{
					'opacity-50 cursor-not-allowed':
						!formValid() || !formTouched() || isSubmitting()
				}"
				class="grow-0 btn btn-primary"
			>
				{{ isSubmitting() ? 'Saving...' : 'Save' }}
			</button>
		</div>
	</div>

	<div class="h-8"></div>

	<form [formGroup]="companyDetailsForm">
		<div
			class="grid gap-x-6 lg:gap-x-12 gap-y-6 grid-cols-1 md:grid-cols-2 pt-6"
		>
			<div>
				<fixify-text-input
					[control]="companyDetailsForm.get('companyName')!"
					label="Company name"
					id="companyName"
					placeholder="Enter your company name"
				></fixify-text-input>
			</div>
			<div class="md:row-span-8">
				<label class="input-label" for="company_logo"
					>Company logo</label
				>
				@if (companyLogoUrl() === null) {
					<div class="flex items-center justify-center w-full">
						<label
							for="dropzone-file"
							class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100"
						>
							<div
								class="flex flex-col items-center justify-center pt-5 pb-6"
							>
								<svg
									class="w-8 h-8 mb-4 text-gray-500"
									aria-hidden="true"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 20 16"
								>
									<path
										stroke="currentColor"
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
									/>
								</svg>
								<p class="mb-2 text-sm text-gray-500">
									<span class="font-semibold"
										>Click to upload</span
									>
									or drag and drop
								</p>
								<p class="text-xs text-gray-500">
									SVG, PNG, JPG or GIF (MAX. 800x400px)
								</p>
							</div>
							<input
								id="dropzone-file"
								type="file"
								class="hidden"
								(change)="onFileSelected($event)"
								accept="image/*"
							/>
						</label>
					</div>
				}

				@if (companyLogoUrl() !== null) {
					<div class="flex flex-col items-start">
						<div
							class="w-48 h-48 overflow-hidden bg-white border border-gray-200 rounded-full flex items-center justify-center"
						>
							<img
								[src]="companyLogoUrl()"
								alt="Company logo"
								class="w-full h-full object-contain"
							/>
						</div>

						<label
							for="company-logo-input"
							class="mt-4 text-gray-900 bg-gray-200 border border-gray-200 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 cursor-pointer"
						>
							Edit photo
						</label>
						<input
							id="company-logo-input"
							type="file"
							class="hidden"
							(change)="onFileSelected($event)"
							accept="image/*"
						/>
					</div>
				}

				@if (
					this.uploadState.percentage > 0 &&
					uploadState.percentage < 100
				) {
					<div class="mt-4">
						<div class="flex justify-between mb-1">
							<span
								class="text-base font-medium text-primary-700 dark:text-white"
								>Uploading</span
							>
							<span
								class="text-sm font-medium text-primary-700 dark:text-white"
								>{{ uploadState.percentage }}%</span
							>
						</div>
						<div
							class="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700"
						>
							<div
								class="bg-primary-600 h-2.5 rounded-full"
								[style.width.%]="uploadState.percentage"
							></div>
						</div>
					</div>
				}
			</div>
			<div>
				<fixify-text-input
					[control]="companyDetailsForm.get('tradingName')!"
					label="Trading name"
					id="tradingName"
					placeholder="Enter your trading name"
				></fixify-text-input>
			</div>
			<div>
				<fixify-text-input
					[control]="companyDetailsForm.get('registeredName')!"
					label="Registered name"
					id="registeredName"
					placeholder="Enter your registered name"
				></fixify-text-input>
			</div>
			<div>
				<label for="country" class="input-label"
					>Country of Registration</label
				>
				<select
					formControlName="country"
					id="country"
					class="form-input"
				>
					<option selected value="">
						Select country of registration
					</option>
					@for (item of countries; track $index) {
						<option
							[value]="item"
							[selected]="item === 'South Africa'"
						>
							{{ item }}
						</option>
						;
					}
				</select>
			</div>
			<div>
				<fixify-text-input
					[control]="companyDetailsForm.get('province')!"
					label="Province/Region"
					id="province"
					placeholder="Enter your province/region"
				></fixify-text-input>
			</div>
			<div>
				<fixify-text-input
					[control]="companyDetailsForm.get('vatNumber')!"
					label="VAT Number"
					id="vatNumber"
					placeholder="Enter your VAT number (if applicable)"
				></fixify-text-input>
			</div>
			<div>
				<label for="entity_type" class="input-label">Entity type</label>
				<select
					formControlName="entityType"
					id="entity_type"
					class="form-input"
				>
					<option selected value="">Select entity type</option>
					<option value="LLC">LLC</option>
					<option value="Corporation">Corporation</option>
					<option value="Partnership">Partnership</option>
					<option value="Sole Proprietorship">
						Sole Proprietorship
					</option>
				</select>
			</div>
			<div>
				<fixify-text-input
					[control]="companyDetailsForm.get('registrationNumber')!"
					label="Registration Number"
					id="registrationNumber"
					placeholder="Enter your registration number"
				></fixify-text-input>
			</div>
		</div>
	</form>
</div>
