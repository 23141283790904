import { Address } from '../../../creator-portal/service/creator-portal.service';
import { environment } from '../../../../environments/environment';

export const provinces = [
	'Eastern Cape',
	'Free State',
	'Gauteng',
	'KwaZulu-Natal',
	'Limpopo',
	'Mpumalanga',
	'North West',
	'Northern Cape',
	'Western Cape'
] as const;

export type Provinces = (typeof provinces)[number];

export function mapAddress(location: google.maps.GeocoderResult): Address {
	return {
		city:
			location.address_components.find(component =>
				component.types.includes('locality')
			)?.long_name || null,
		coordinates: [
			location.geometry.location.lat(),
			location.geometry.location.lng()
		],
		formatted: location.formatted_address,
		geohash: null, // Google Maps object doesn't provide geohash
		postalCode:
			location.address_components.find(component =>
				component.types.includes('postal_code')
			)?.long_name || null,
		province:
			location.address_components.find(component =>
				component.types.includes('administrative_area_level_1')
			)?.long_name || null,
		street: {
			name:
				location.address_components.find(component =>
					component.types.includes('route')
				)?.long_name || '',
			number:
				location.address_components.find(component =>
					component.types.includes('street_number')
				)?.long_name || null
		},
		suburb:
			location.address_components.find(component =>
				component.types.includes('sublocality_level_1')
			)?.long_name ||
			location.address_components.find(component =>
				component.types.includes('sublocality_level_2')
			)?.long_name ||
			null,
		unitNumber: null // Google Maps object doesn't provide unit number
	};
}

export function staticMapImageUrl(
	coordinates: google.maps.LatLngLiteral,
	zoom = 16,
	width = 400,
	height = 300
): string {
	const center = `&markers=anchor:center%7Cicon:http://bit.ly/3TPjUzs%7C${coordinates.lat},${coordinates.lng}`;

	return `https://maps.googleapis.com/maps/api/staticmap?zoom=${zoom}&size=${width}x${height}&maptype=roadmap${center}&key=${environment.mapApiKey}`;
}
