import { Component, model, output } from '@angular/core';

@Component({
	selector: 'fixify-job-submitted-modal',
	standalone: true,
	imports: [],
	templateUrl: './job-submitted.component.html',
	styleUrl: './job-submitted.component.css'
})
export class JobSubmittedComponent {
	isModalOpen = model<boolean>(false);
	onView = output<boolean>();
	onCreate = output<boolean>();

	viewClicked() {
		this.onView.emit(true);
		this.hideModal();
	}

	createClicked() {
		this.onCreate.emit(false);
		this.hideModal();
	}

	hideModal() {
		this.isModalOpen.set(false);
	}
}
