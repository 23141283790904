import { Component, input } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
	selector: 'fixify-avatar',
	standalone: true,
	imports: [IconComponent],
	templateUrl: './avatar.component.html',
	styleUrl: './avatar.component.css'
})
export class AvatarComponent {
	source = input<string>('');
	placeholder = input<string>('');
}
