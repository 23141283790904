import { Component, signal } from '@angular/core';
import { BreadcrumbComponent } from '../../../shared/breadcrumb/breadcrumb.component';
import { AsyncPipe, NgClass } from '@angular/common';
import { TextInputComponent } from '../../../shared/ui/text-input/text-input.component';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { BreadcrumbService } from '../../../shared/data-access/breadcrumb.service';
import { UploadComponent } from '../../../shared/ui/upload/upload.component';
import { AgencyService } from '../../../shared/data-access/agency/agency.service';
import { Subject, takeUntil, takeWhile } from 'rxjs';
import {
	deleteObject,
	getDownloadURL,
	ref,
	uploadBytesResumable,
	Storage
} from '@angular/fire/storage';
import { Agency } from '../../../shared/data-access/agency/agency.types';

@Component({
	selector: 'app-agency-details',
	standalone: true,
	imports: [
		BreadcrumbComponent,
		AsyncPipe,
		TextInputComponent,
		ReactiveFormsModule,
		NgClass,
		UploadComponent
	],
	templateUrl: './agency-details.component.html',
	styleUrl: './agency-details.component.css'
})
export class AgencyDetailsComponent {
	agencyDetailsForm: FormGroup;
	private destroy$ = new Subject<void>();

	accountCompletion = signal<boolean>(false);
	uploadState: {
		percentage: number;
		downloadUrl?: string;
		uploading: boolean;
	} = {
		percentage: 0,
		uploading: false
	};
	agencyLogoUrl = signal<string | null>(null);
	agencyId: string | null = null;
	isSubmitting = false;

	constructor(
		public breadcrumbService: BreadcrumbService,
		private agencyService: AgencyService,
		private storage: Storage
	) {
		this.agencyDetailsForm = new FormGroup({
			name: new FormControl(''),
			contactNumber: new FormControl('')
		});
	}

	ngOnInit() {
		this.breadcrumbService.setBreadcrumbs([
			{ label: 'Account', url: '/agency/account' },
			{ label: 'Company Details' }
		]);

		this.agencyService
			.accountCompletion()
			.pipe(takeUntil(this.destroy$))
			.subscribe(acc => {
				this.accountCompletion.set(acc.agencyDetails ?? false);
			});

		this.agencyService
			.isSubmitting()
			.pipe(takeUntil(this.destroy$))
			.subscribe(isSubmitting => {
				this.isSubmitting = isSubmitting;
			});

		this.agencyService
			.agency()
			.pipe(takeUntil(this.destroy$))
			.subscribe(agency => {
				if (agency?.logoUrl) this.agencyLogoUrl.set(agency.logoUrl);

				this.agencyDetailsForm.patchValue({
					name: agency?.name,
					contactNumber: agency?.contactNumber
				});

				this.agencyDetailsForm.markAsPristine();
				this.agencyDetailsForm.markAsUntouched();
			});

		this.agencyService
			.agent()
			.pipe(takeUntil(this.destroy$))
			.subscribe(agent => {
				this.agencyId = agent?.agencyId ?? null;
			});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
	}

	get formTouched(): boolean {
		return this.agencyDetailsForm.dirty;
	}

	get formValid(): boolean {
		return this.agencyDetailsForm.valid;
	}

	onSubmit() {
		if (this.agencyDetailsForm.valid && this.agencyDetailsForm.dirty) {
			const updateAgency: Partial<Agency> = {};
			Object.keys(this.agencyDetailsForm.controls).forEach(key => {
				if (this.agencyDetailsForm.controls[key].dirty) {
					updateAgency[key as keyof Agency] =
						this.agencyDetailsForm.controls[key].value;
				}
			});

			this.agencyService.updateAgency(updateAgency);
		}
	}

	async uploadMedia(file: File): Promise<void> {
		if (!this.agencyId) {
			throw new Error('Agency ID not set');
		}
		const filePath = `agency/${this.agencyId}/logo/${file.name}`;
		const storageRef = ref(this.storage, filePath);
		const uploadTask = uploadBytesResumable(storageRef, file);

		console.log(file.name);

		if (this.agencyLogoUrl()) {
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			await this.deleteMedia().catch(_ => _);
			this.agencyLogoUrl.set(null);
		}

		uploadTask.on(
			'state_changed',
			snapshot => {
				const progress =
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				this.uploadState = {
					percentage: Math.round(progress),
					uploading: Math.round(progress) < 100
				};
			},
			error => console.error('Upload failed:', error),
			() => {
				getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
					this.agencyService.updateAgencyLogo(downloadURL);
					this.agencyService
						.isSubmittingMedia()
						.pipe(
							takeWhile(value => !value, true),
							takeUntil(this.destroy$)
						)
						.subscribe(value => {
							if (value) {
								this.agencyLogoUrl.set(downloadURL);
								this.uploadState = {
									percentage: 100,
									downloadUrl: downloadURL,
									uploading: false
								};
							}
						});
				});
			}
		);
	}

	async deleteMedia() {
		if (!this.agencyLogoUrl()) return;
		const storageRef = ref(this.storage, this.agencyLogoUrl() as string);
		await deleteObject(storageRef);
		this.agencyService.updateAgencyLogo(null);
		this.agencyLogoUrl.set(null);
	}
}
