import { NgClass } from '@angular/common';
import { Component, input } from '@angular/core';

@Component({
	selector: 'fixify-selectable-grid-item',
	standalone: true,
	imports: [NgClass],
	templateUrl: './selectable-grid-item.component.html',
	styleUrl: './selectable-grid-item.component.css'
})
export class SelectableGridItemComponent {
	selected = input<boolean>(false);
	error = input<boolean>(false);
}
