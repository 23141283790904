<div
	class="p-4 gap-4 rounded-md border-2 border-gray-100 flex flex-row items-center max-w-80"
>
	<fixify-property-avatar [url]="imageUrl()"></fixify-property-avatar>
	<div class="flex flex-col gap-1">
		<p
			class="leading-tight text-base font-normal text-gray-900 line-clamp-1"
		>
			{{ companyName() }}
		</p>
		<p
			class="py-2 px-0.5 underline text-xs font-normal text-gray-800 cursor-pointer"
		>
			More information
		</p>
	</div>
</div>

